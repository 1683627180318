import {createSlice} from '@reduxjs/toolkit';
import {sortVehicleNamesHelper} from '../../app/utils';

const cabviewSlice = createSlice({
  name: 'cabview',
  initialState: {
    vehicles: [],
    hazards: [],
    tasks: [],
    implements: [],
    zonesData: [],
    geoFences: [],
    geoFenceIntelliBlockDict: {},
    taskActive: false,
    selectedVehicle: '',
    selectedTask: '',
    displayedPlotLines: [],
    displayClusters: true,
    machineType: 0,
    reiActiveDict: {},
    refreshCabviewDataStream: false,
  },
  reducers: {
    updateDisplayedPlotLines(state, action) {
      state.displayedPlotLines = action.payload;
    },
    updateDisplayClusters(state, action) {
      state.displayClusters = action.payload;
    },
    updateCabviewVehicles(state, action) {
      const vehDataPack = action.payload;
      const vehDataFiltered = [];

      for (let i = 0; i < vehDataPack.length; i++) {
        // Create deep copy of the payload
        const vehicle = JSON.parse(JSON.stringify(vehDataPack[i]));
        vehDataFiltered.push(vehicle);
      }

      const sortedVehicles = vehDataFiltered.sort((a, b) => {
        return sortVehicleNamesHelper(a.name, b.name);
      });

      state.vehicles = sortedVehicles;
    },
    updateHazards(state, action) {
      const hazardDataPack = action.payload;
      if (hazardDataPack != null || hazardDataPack != undefined) {
        state.hazards = hazardDataPack;
      }
    },
    prepareCabviewVehiclesForGpsData(state, action) {
      state.vehicles.forEach((vehicle) => {
        vehicle.gpsCoords = [];
      });
    },
    updateTasks(state, action) {
      const taskDataPack = action.payload;
      const tasks = [];
      const sortedTaskDocs = taskDataPack.sort((a, b) => {
        if (a.name == 'No Task') {
          // `a` is No Task, sort it to front
          return -1;
        } else if (b.name == 'No Task') {
          // `b` is No Task, sort it to back
          return 1;
        } else {
          // both not No Task return alphabetic sort
          return a.name.localeCompare(b.name);
        }
      });
      for (let i = 0; i < sortedTaskDocs.length; i++) {
        // Filter out archived tasks
        if (!sortedTaskDocs[i].archived) {
          tasks.push(sortedTaskDocs[i]);
        }
      }
      state.tasks = tasks;
    },
    updateImplements(state, action) {
      state.implements = action.payload;
    },
    updateGeoFences(state, action) {
      // Collect a dictionary of geoFences and geoFenceAttributes based on intelliblock_num
      const geoFences = action.payload;
      const geoFenceIntelliBlockDict = {};
      for (let i = 0; i < geoFences.length; i++) {
        if (typeof geoFences[i].properties.block_id === 'undefined') {
          continue;
        }

        if (!Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, geoFences[i].properties.intelliblock_num)) {
          geoFenceIntelliBlockDict[geoFences[i].properties.intelliblock_num] = geoFences[i];
        }
      }
      state.geoFenceIntelliBlockDict = geoFenceIntelliBlockDict;
    },
    updateZonesData(state, action) {
      state.zonesData = action.payload;
    },
    updateReiActive(state, action) {
      const reiList = action.payload;
      const reiActiveDict = {};

      reiList.map((rei) => {
        reiActiveDict[rei.zoneId] = rei;
      });

      state.reiActiveDict = reiActiveDict;
    },
    setTaskActive(state, action) {
      state.taskActive = action.payload;
    },
    setSelectedVehicle(state, action) {
      state.selectedVehicle = action.payload;
    },
    setSelectedTask(state, action) {
      state.selectedTask = action.payload;
    },

    // Refreshing the cabview data stream
    setRefreshCabviewDataStream(state, action) {
      state.refreshCabviewDataStream = action.payload;
    },
  },
});

export const {
  updateCabviewVehicles,
  updateHazards,
  updateTasks,
  updateImplements,
  updateGeoFences,
  updateZonesData,
  updateReiActive,
  setTaskActive,
  setSelectedVehicle,
  setSelectedTask,
  prepareCabviewVehiclesForGpsData,
  updateDisplayedPlotLines,
  updateDisplayClusters,
  setRefreshCabviewDataStream,
} = cabviewSlice.actions;
export default cabviewSlice.reducer;

import {Bar} from 'react-chartjs-2';
import {ArcElement} from 'chart.js';
import ChartJS from 'chart.js/auto';

ChartJS.register(ArcElement);

function BarChart(props) {
  const options = props.options;
  const data = props.data;
  const plugins = props.plugins;
  options.plugins.targetLine = false;
  options.plugins.datalabels = false;
  options.layout = {
    padding: 20,
  };
  return <Bar className='d-inline-block' options={options} data={data} plugins={plugins} />;
}

export default BarChart;

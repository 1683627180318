import React, {useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {DateTime} from 'luxon';
import {
  sendGAEvent,
  unitsSubmitConversion,
  unitsDisplayConversion,
  fetchPostAuthSafe,
  dollarRegex,
  handleAllowOnlyNumbers,
} from '../../../app/utils';
import {Alert} from '@mui/material';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalColumnSection,
  ModalWideColumnSection,
  ModalInputSection,
  ModalBody,
  ModalWarning,
  ModalNote,
  ModalNoteInspectionLinks,
} from '../../../components/Modal';
import {getServiceData} from '../shopviewSlice';

const defaultInputs = {
  useCurrent: false,
  engineHours: '',
  date: '',
  odometer: '',
  workOrder: '',
  laborCost: '',
  laborTime: '',
  invoice: '',
  partsCost: '',
  supplier: '',
  reviewedBy: '',
  notes: '',
};
let currValues = defaultInputs;

function CompleteTaskModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = useSelector((state) => {
    return state.upcomingModal;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });
  const shopviewUseCurrentEnabled = useSelector((state) => {
    return state.app.customerSettings.shopview.useCurrentEnabled;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [inputs, setInputs] = useState(defaultInputs);
  const [warnings, setWarnings] = useState({});
  const [forceTriggered, setForceTriggered] = useState(false);
  const [units, setUnits] = useState('km');

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setUnits('mi');
    } else {
      setUnits('km');
    }
  }, [unitsSystem]);

  useEffect(() => {
    function modalOpen() {
      sendGAEvent('modal_open', 'CompleteTask', 'shopview');
      setDefault();
    }
    // Set default values when modal opened
    $('#shopview-complete-task-modal').on('show.bs.modal', modalOpen);
    return () => {
      // Remove event listener when component is unmounted
      $('#shopview-complete-task-modal').off('show.bs.modal', modalOpen);
    };
  }, []);

  useEffect(() => {
    currValues = initialValues;
    setDefault();
  }, [initialValues]);

  useEffect(() => {
    // Clear warnings if values change
    setWarnings({});
    setForceTriggered(false);
  }, [inputs.engineHours, inputs.odometer, inputs.date]);

  // Handle changes in form values
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (
      ['laborCost', 'laborTime', 'partsCost', 'engineHours', 'odometer'].includes(name) &&
      value &&
      (!dollarRegex.test(value) || value.includes('-'))
    ) {
      return;
    }
    setInputs((values) => {
      return {...values, [name]: value};
    });
  };

  const handleUseCurrent = (event) => {
    const value = event.target.checked;
    if (value) {
      setInputs((values) => {
        return {
          ...values,
          useCurrent: true,
          odometer: Math.floor(unitsDisplayConversion(initialValues.currentOdometer, units)),
          engineHours: Math.floor(initialValues.currentEngineHours),
        };
      });
    } else {
      setInputs((values) => {
        return {
          ...values,
          useCurrent: false,
          odometer: '',
          engineHours: '',
        };
      });
    }
  };
  function setDefault() {
    setWarnings({});
    const tempDefualtInputs = {...defaultInputs};
    if (currValues.serviceTask) {
      const currServiceTask = currValues.serviceTask;
      if (currServiceTask.hasOwnProperty('average_labor_cost') && currServiceTask['average_labor_cost']) {
        tempDefualtInputs.laborCost = currServiceTask['average_labor_cost'];
      }
      if (currServiceTask.hasOwnProperty('average_labor_hours') && currServiceTask['average_labor_hours']) {
        tempDefualtInputs.laborTime = currServiceTask['average_labor_hours'];
      }
      if (currServiceTask.hasOwnProperty('average_parts_cost') && currServiceTask['average_parts_cost']) {
        tempDefualtInputs.partsCost = currServiceTask['average_parts_cost'];
      }
    }
    setInputs(tempDefualtInputs);
    setInputs((values) => {
      return {
        ...values,
        date: DateTime.local().toISODate(),
      };
    });
  }

  async function submitModal() {
    /*
    Check the criteria for accepting the modal entry
    Since warningDataObj has the serviceTask schema,
    we check against it to determine what the serviceTask's required intervals are
    */
    const engineHoursEmpty = inputs.engineHours === '' && initialValues.serviceTask.engine_hours_interval;
    const odometerEmpty = inputs.odometer === '' && initialValues.serviceTask.odometer_interval;
    // Always require date
    const dateEmpty = inputs.date == '';

    const localTimeZone = DateTime.local().zoneName;
    // Get the current dateTime at the system local timezone midnight
    const currentDate = DateTime.local({zone: localTimeZone}).set({hour: 0, minute: 0, second: 0, millisecond: 0});
    // Get the entryDate dateTime at the system local timezone midnight
    const entryDate = DateTime.fromFormat(inputs.date, 'yyyy-LL-dd', {zone: localTimeZone});

    // Determine if the entered date is in the future
    const dateFuture = entryDate > currentDate;

    // Set any warnings
    setWarnings((values) => {
      return {
        ...values,
        warningEngineHours: engineHoursEmpty,
        warningOdometer: odometerEmpty,
        warningDate: dateEmpty,
        warningDateGreaterFault: dateFuture,
      };
    });

    // If all submit criterias are met POST the data to the server
    if (!engineHoursEmpty && !odometerEmpty && !dateEmpty && !dateFuture && !submitting) {
      setSubmitting(true);
      // Differentiate between '' vs 0 values
      let engineHours = parseFloat(inputs.engineHours);
      if (isNaN(engineHours)) {
        engineHours = '';
      }
      let odometer = parseFloat(unitsSubmitConversion(inputs.odometer, units));
      if (isNaN(odometer)) {
        odometer = '';
      }

      // Interpret cost
      let laborCostVal = parseFloat(inputs.laborCost);
      if (isNaN(laborCostVal)) {
        const laborCostString = `${inputs.laborCost}`;
        laborCostVal = parseFloat(laborCostString.replace(/[^0-9.\-]/g, '')) || 0;
      }

      let partsCostVal = parseFloat(inputs.partsCost);
      if (isNaN(partsCostVal)) {
        const partsCostString = `${inputs.partsCost}`;
        partsCostVal = parseFloat(partsCostString.replace(/[^0-9.\-]/g, '')) || 0;
      }

      const postData = {
        serviceTask: initialValues.serviceTask,
        engineHours: engineHours,
        odometer: odometer,
        date: inputs.date,
        workOrder: inputs.workOrder.trim(),
        invoice: inputs.invoice.trim(),
        laborCost: laborCostVal,
        partsCost: partsCostVal,
        laborTime: parseFloat(inputs.laborTime) || '',
        supplier: inputs.supplier.trim(),
        reviewedBy: inputs.reviewedBy.trim(),
        notes: inputs.notes.trim(),
      };

      // If warnings showing a submission still happens, use the force method to update
      if (forceTriggered) {
        postData.force = true;
      }

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      };

      const completeTaskReq = await fetchPostAuthSafe(
        '/shopview/completeTask',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const completeTaskReqData = await completeTaskReq.json();
      if (completeTaskReqData.errorMsg) {
        navigate('/error', {state: {errorMsg: completeTaskReqData.errorMsg}});
      }

      if (
        completeTaskReqData.odometerFault ||
        completeTaskReqData.engineHourFault ||
        completeTaskReqData.odometerGreaterThanCurrent ||
        completeTaskReqData.engineHourGreaterThanCurrent ||
        completeTaskReqData.engineHoursExpectedValue ||
        completeTaskReqData.engineHoursMaximumValue
      ) {
        const warningEngineHoursExpectedValue = completeTaskReqData.engineHoursExpectedValue;
        const warningEngineHoursMaximumValue = completeTaskReqData.engineHoursMaximumValue;
        const warningOdometerMoreThanCurrent = completeTaskReqData.odometerGreaterThanCurrent == 1;
        const warningEngineHoursMoreThanCurrent = completeTaskReqData.engineHourGreaterThanCurrent == 1;

        setWarnings((values) => {
          return {
            ...values,
            warningEngineHoursExpectedValue: warningEngineHoursExpectedValue,
            warningEngineHoursMaximumValue: warningEngineHoursMaximumValue,
            warningOdometerMoreThanCurrent: warningOdometerMoreThanCurrent,
            warningEngineHoursMoreThanCurrent: warningEngineHoursMoreThanCurrent,
            warningOdometerGreaterFault: completeTaskReqData.odometerFault == 1,
            warningOdometerLesserFault: completeTaskReqData.odometerFault == -1,
            warningEngineHoursGreaterFault: completeTaskReqData.engineHourFault == 1,
            warningEngineHoursLesserFault: completeTaskReqData.engineHourFault == -1,
          };
        });

        // Set forceTriggered for resubmitting
        if (
          warningOdometerMoreThanCurrent ||
          warningEngineHoursMoreThanCurrent ||
          warningEngineHoursExpectedValue ||
          warningEngineHoursMaximumValue
        ) {
          setForceTriggered(true);
        }
      } else {
        $('#shopview-complete-task-modal').modal('hide');
        setTimeout(function () {
          dispatch(getServiceData());
        }, 500);
      }

      sendGAEvent('modal_submit', 'CompleteTask', 'shopview');
      setSubmitting(false);
    }
  }

  return createPortal(
    <ModalFramework id='shopview-complete-task-modal'>
      <ModalHeader title='Complete Service' />
      <ModalBody>
        <ModalRowSection underline={true}>
          <ModalColumnSection>
            <p>
              <span className='font-weight-bold'>Equipment:</span> {initialValues.vehicle}
            </p>
          </ModalColumnSection>
          <ModalColumnSection>
            <p>
              <span className='font-weight-bold'>Service:</span> {initialValues.task}
            </p>
          </ModalColumnSection>
          {initialValues.serviceTask.notes && <ModalNote text={initialValues.serviceTask.notes} />}
          {initialValues.serviceTask.inspectionReportIds &&
            initialValues.serviceTask.inspectionReportIds.length > 0 && (
              <ModalNoteInspectionLinks
                modalId='shopview-complete-task-modal'
                setLinkedInspectionReportId={props.setLinkedInspectionReportId}
                inspectionReportIdsList={initialValues.serviceTask.inspectionReportIds}
              />
            )}
        </ModalRowSection>
        {shopviewUseCurrentEnabled &&
          initialValues.machineType != 3 && ( // Trailer type
            <ModalRowSection underline={true}>
              <div>
                <label>
                  <input
                    type='checkbox'
                    name='useCurrent'
                    className='ml-2'
                    checked={inputs.useCurrent}
                    onChange={handleUseCurrent}
                  />{' '}
                  Use Current Odometer and Engine Hours
                </label>
              </div>
            </ModalRowSection>
          )}
        {warnings.warningEngineHours && <ModalWarning text='Enter Engine Hours' />}
        {warnings.warningOdometer && <ModalWarning text='Enter Odometer Value' />}
        {warnings.warningDate && <ModalWarning text='Enter Date Completed' />}
        {warnings.warningDateGreaterFault && <ModalWarning text='Entry of future dates is not enabled' />}
        {warnings.warningOdometerGreaterFault && (
          <ModalWarning text='The input odometer value is greater than a following entry' />
        )}
        {warnings.warningOdometerLesserFault && (
          <ModalWarning text='The input odometer value is less than a previous entry' />
        )}
        {warnings.warningEngineHoursGreaterFault && (
          <ModalWarning text='The input engine hours value is greater than a following entry' />
        )}
        {warnings.warningEngineHoursLesserFault && (
          <ModalWarning text='The input engine hours value is less than a previous entry' />
        )}
        {warnings.warningOdometerMoreThanCurrent && (
          <ModalWarning
            text={`The input odometer value is more than the measured on the vehicle. 
              Please click submit again to confirm.`}
          />
        )}
        {warnings.warningEngineHoursMoreThanCurrent && (
          <ModalWarning
            text={`The input engine hours value is more than the measured on the vehicle. 
              Please click submit again to confirm.`}
          />
        )}
        {!!warnings.warningEngineHoursExpectedValue && (
          <ModalWarning
            text={`Your input (${inputs.engineHours} hrs) is much different than IntelliCulture's reading
              (${Math.floor(warnings.warningEngineHoursExpectedValue)} hrs). 
              Clicking submit will override IntelliCulture's hours.`}
          />
        )}
        {!!warnings.warningEngineHoursMaximumValue && (
          <ModalWarning
            text={`Your input (${inputs.engineHours} hrs) is very large. Please verify your input. 
              Clicking submit will override IntelliCulture's hours.`}
          />
        )}
        <ModalRowSection underline={true}>
          <ModalColumnSection>
            <ModalInputSection label='Engine Hours'>
              <input
                type='text'
                name='engineHours'
                className='form-control flex-fill'
                value={inputs.engineHours}
                step={0.01}
                min={0}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
              {initialValues.machineType != 3 && ( // Trailer type
                <span className='font-italic pl-1'>
                  Last Est. Hours: {Math.floor(initialValues.currentEngineHours)}
                </span>
              )}
            </ModalInputSection>
            <ModalInputSection label='Date Completed'>
              <input
                type='date'
                name='date'
                className='form-control flex-fill'
                value={inputs.date}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label={`Odometer (${units})`}>
              <input
                type='text'
                name='odometer'
                className='form-control flex-fill'
                value={inputs.odometer}
                step={0.01}
                min={0}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
              {initialValues.machineType != 3 && ( // Trailer type
                <span className='font-italic pl-1'>
                  Last Est. Odo: {Math.floor(unitsDisplayConversion(initialValues.currentOdometer, units))}
                </span>
              )}
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={true}>
          <ModalColumnSection>
            <ModalInputSection label='Work Order'>
              <input
                type='text'
                name='workOrder'
                className='form-control flex-fill'
                value={inputs.workOrder}
                onChange={handleChange}
              />
            </ModalInputSection>
            <ModalInputSection label='Labor Cost ($)'>
              <input
                type='text'
                name='laborCost'
                className='form-control flex-fill'
                value={inputs.laborCost}
                step={0.5}
                min={0}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
            <ModalInputSection label='Labor Time (Hrs)'>
              <input
                type='text'
                name='laborTime'
                className='form-control flex-fill'
                value={inputs.laborTime}
                step={0.5}
                min={0}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label='Invoice Number'>
              <input
                type='text'
                name='invoice'
                className='form-control flex-fill'
                value={inputs.invoice}
                onChange={handleChange}
              />
            </ModalInputSection>
            <ModalInputSection label='Parts Cost ($)'>
              <input
                type='text'
                name='partsCost'
                className='form-control flex-fill'
                value={inputs.partsCost}
                step={0.5}
                min={0}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
            <ModalInputSection label='Supplier Name'>
              <input
                type='text'
                name='supplier'
                className='form-control flex-fill'
                value={inputs.supplier}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalWideColumnSection>
            <ModalInputSection label='Reviewed By'>
              <input
                type='text'
                name='reviewedBy'
                className='form-control flex-fill'
                value={inputs.reviewedBy}
                onChange={handleChange}
              />
            </ModalInputSection>
            <ModalInputSection label='Notes'>
              <input
                type='textarea'
                name='notes'
                className='form-control flex-fill'
                value={inputs.notes}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalWideColumnSection>
        </ModalRowSection>
      </ModalBody>
      <ModalFooter onSubmit={submitModal} submitting={submitting} />
    </ModalFramework>,
    document.getElementById('app')
  );
}

export {CompleteTaskModal};

import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {CoverPageCard} from '../components/CoverPageCard';
import {sendGAPageview} from '../app/utils';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [formVariables, setFormVariables] = useState({
    header: 'Forgot Your Password?',
    headerClass: 'h4 text-gray-900 mb-2',
    text:
      "We get it, stuff happens. Just enter your email address below and we'll " +
      'send you a link to reset your password!',
  });

  useEffect(() => {
    document.title = 'IntelliCulture | Forgot Password';
    sendGAPageview(document, true);
  }, []);

  return (
    <CoverPageCard secondary={true}>
      <div className='text-center'>
        <h1 className={formVariables.headerClass}>{formVariables.header}</h1>
        <p className='mb-4'>{formVariables.text}</p>
      </div>
      {!submitting && (
        <form className='user' onSubmit={handleSubmit}>
          <div className='form-group'>
            <input
              type='email'
              name='email'
              className='form-control form-control-user'
              aria-describedby='emailHelp'
              placeholder='Enter Email Address...'
              value={email}
              onChange={(e) => {
                return setEmail(e.target.value);
              }}
              required
            />
          </div>
          <input type='submit' className='btn btn-primary btn-user btn-block' />
        </form>
      )}
      <hr />
      <div className='text-center'>
        <Link className='small' to='/login'>
          Already have an account? Login!
        </Link>
      </div>
    </CoverPageCard>
  );

  async function handleSubmit(event) {
    event.preventDefault();
    setSubmitting(true); // Hide immediately to avoid multiple inputs

    if (validateEmail()) {
      const postData = {
        userEmail: email,
      };
      const response = await fetch('/forgotPasswordSubmit', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(postData),
        cache: 'no-store',
      });
      const result = await response.json();

      if (result.emailExists && result.emailSent) {
        setFormVariables({
          header: 'An Email has been sent',
          headerClass: 'h4 text-success mb-2',
          text: `An email has been sent to ${email}, please check your inbox to reset your password.`,
        });
      } else if (!result.emailExists) {
        setFormVariables({
          header: 'Email Invalid',
          headerClass: 'h4 text-danger mb-2',
          text: 'Sorry, the email you entered is invalid, please try again.',
        });
        setSubmitting(false);
      } else {
        setFormVariables({
          header: 'Error',
          headerClass: 'h4 text-danger mb-2',
          text:
            'Sorry, we encountered an error. Please contact IntelliCulture at ' +
            '<a href="mailto:contact@intelliculture.ca">contact@intelliculture.ca</a>.',
        });
        setSubmitting(false);
      }
    } else {
      setFormVariables({
        header: 'Email Invalid',
        headerClass: 'h4 text-danger mb-2',
        text: 'Sorry, the email you entered is invalid, please try again.',
      });
      setSubmitting(false);
    }
  }

  function validateEmail() {
    // eslint-disable-next-line max-len
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}

export {ForgotPassword};

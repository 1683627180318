import {React, useState, useRef, useEffect} from 'react';

function CommentBox(props) {
  const [commentValue, setCommentValue] = useState('');
  const textRef = useRef(null);

  useEffect(() => {
    if (!textRef) return;

    textRef.current.style.height = 'auto';
    textRef.current.style.height = textRef.current.scrollHeight + 'px';
  }, [textRef, commentValue]);

  function onChange(e) {
    setCommentValue(e.target.value);
    if (typeof props.onChangeCallback != 'undefined') {
      props.onChangeCallback(e.target.id, e.target.value);
    }
  }

  function checkPlaceHolder() {
    if (typeof props.indicateManditory != 'undefined' && props.indicateManditory == true) {
      return 'Comment *';
    } else {
      return 'Comment';
    }
  }

  return (
    <textarea
      className='text-area-wide'
      ref={textRef}
      onChange={onChange}
      placeholder={checkPlaceHolder()}
      value={commentValue}
      name='commentBox'
      id={props.id}
    />
  );
}

export default CommentBox;

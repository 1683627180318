import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {CoverPageCard} from '../components/CoverPageCard';
import {sendGAPageview} from '../app/utils';
import {checkAuthentication} from '../appSlice';

function Error() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authenticationStatus = useSelector((state) => {
    return state.app.authenticationStatus;
  });

  const [defaultPage, setdefaultPage] = useState('/logout');

  useEffect(async () => {
    dispatch(checkAuthentication());
  }, []);

  useEffect(async () => {
    document.title = 'IntelliCulture | Error';
    sendGAPageview(document.title, true);

    if (
      !authenticationStatus.cabviewEnabled &&
      !authenticationStatus.shopviewEnabled &&
      !authenticationStatus.cropviewEnabled &&
      !authenticationStatus.inspectionEnabled
    ) {
      setdefaultPage('/logout');
    } else {
      setdefaultPage('/');
    }
  }, [authenticationStatus]);

  let errorMsg = 'Page Not Found';

  if (location.state && location.state.errorMsg) {
    errorMsg = location.state.errorMsg;
  }

  if (location.state && location.state.errorMsg && location.state.errorMsg == 'outOfSync') {
    errorMsg = 'Warning - Client data out of sync. You will now be logged out. Please log in again.';
  }

  return (
    <CoverPageCard>
      <div className='text-center'>
        <a href='https://www.intelliculture.ca/' target='_blank' className='card-logo' rel='noreferrer'>
          <img src='/img/Logo_Text_NoSlogan.png' alt='Logo' border='0' width='256' className='card-logo-img' />
        </a>
        <h1 id='heading' className='h4 text-gray-900 mb-4'>
          {errorMsg}
        </h1>
      </div>
      <hr />
      <div className='text-center'>
        <button
          type='button'
          className='btn border bg-white small'
          onClick={() => {
            return navigate(`${defaultPage}`);
          }}
        >
          Exit
        </button>
      </div>
    </CoverPageCard>
  );
}

export {Error};

import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {generateCsv, squareButton} from '../app/utils';
import {FileDropZone} from './FileDropZone';
import {ModalSubmitting, CollapsableSection} from './GeneralComponents';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function BulkUploadModal(props) {
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: smallScreen ? '100%' : 800,
    maxHeight: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY: 'auto',
    p: 2,
  };

  const [uploadedData, setUploadedData] = useState([]);
  const [uploadedDataMapped, setUploadedDataMapped] = useState([]);
  const [submitStatus, setSubmitStatus] = useState('pending');
  // submitStatus:  "pending" : Filling form
  //                "submitting" : Submitting
  //                "success" : Submission Completed
  //                "error" : Error submission
  const [uploadedFile, setUploadedFile] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [allowForceUpload, setAllowForceUpload] = useState(false);
  const [showTemplateDescription, setShowTemplateDescription] = useState(false);
  const [submitErrorMsg, setSubmitErrorMsg] = useState('');
  const [errorUsers, setErrorUsers] = useState([]);
  const steps = ['Upload CSV', 'Preview and Validate Data', 'Submit'];

  useEffect(() => {
    const dupCheckObject = {};
    const transformedData = uploadedData.map((data) => {
      return props.dataValidationAndMapping(data, dupCheckObject);
    });

    setUploadedDataMapped(transformedData);
  }, [uploadedData]);

  function downloadTemplateCsv() {
    const filename = `${props.entityName}_Upload_Template`;
    const csvHeaders = props.acceptedColumnHeaders.map((column) => {
      return column.key;
    });

    generateCsv(filename, csvHeaders, []);
  }

  useEffect(() => {}, [uploadedDataMapped]);

  function handleStep(step) {
    setActiveStep((value) => {
      const nextStep = value + step;
      if (nextStep < steps.length) {
        return value + step;
      } else {
        return 0;
      }
    });
  }

  function handleSetUploadedData(data) {
    setUploadedData(data);
  }

  function handleSetUploadedFile(files) {
    setUploadedFile(files);
  }

  function modalClose() {
    setActiveStep(0);
    setUploadedFile(null);
    setUploadedData([]);
    setUploadedDataMapped([]);
    setSubmitErrorMsg('');
    setErrorUsers([]);
    setSubmitStatus('pending');
    setAllowForceUpload(false);
    props.handleModalOpen(false);
  }

  async function handleBulkUploadSubmit() {
    setSubmitStatus('submitting');
    const docsRemovedErrors = [...uploadedDataMapped];
    docsRemovedErrors.forEach((doc) => {
      delete doc.errors;
      delete doc.warnings;
    });
    const result = await props.bulkUploadSubmit(docsRemovedErrors);
    if (props.refreshData) {
      props.refreshData();
    }
    if (result.success) {
      setSubmitStatus('success');
    } else {
      setSubmitStatus('error');
      if (Object.prototype.hasOwnProperty.call(result, 'errorMsg')) {
        setSubmitErrorMsg(result['errorMsg']);
      }
      if (Object.prototype.hasOwnProperty.call(result, 'errorUsers')) {
        setErrorUsers(result['errorUsers']);
      }
    }
  }

  return (
    <Modal open={props.modalOpen} onClose={modalClose}>
      <Box sx={boxStyle}>
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
          <h3>
            <strong>Create {props.entityName} from CSV</strong>
          </h3>
        </div>
        {activeStep === 0 && (
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            <FileDropZone
              setUploadedData={handleSetUploadedData}
              setUploadedFile={handleSetUploadedFile}
              acceptedColumns={props.acceptedColumnHeaders.map((column) => {
                return column.key;
              })}
            />
            {uploadedFile && (
              <div className='flex-row mb-3 col-12 px-1 text-center'>
                <strong>Uploaded File: </strong>
                {uploadedFile.path}
                <p>
                  {uploadedData.length == 0
                    ? `No Data Rows Detected, Please ensure the correct file is uploaded.`
                    : `${uploadedData.length} Rows of Data Detected`}
                </p>
              </div>
            )}
            <CollapsableSection
              title='File Template and Guide'
              collapseOpen={showTemplateDescription}
              setCollapseOpen={setShowTemplateDescription}
              section=''
            >
              <div className='card'>
                <div className='card-body'>
                  <Table size='small' aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Column Header</TableCell>
                        <TableCell>Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.acceptedColumnHeaders.map((column) => {
                        return (
                          <TableRow key={column.key}>
                            <TableCell>
                              {column.key} {column.required ? `(required)` : `(optional)`}
                            </TableCell>
                            <TableCell>{column.description}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
                <div className='card-footer'>
                  <Button
                    sx={squareButton}
                    variant='ic-button'
                    color='secondary'
                    className='ml-1'
                    title='Download Template'
                    onClick={downloadTemplateCsv}
                  >
                    <FontAwesomeIcon icon='fas fa-download' /> <span> Download Template</span>
                  </Button>
                </div>
              </div>
            </CollapsableSection>
          </div>
        )}
        {activeStep === 1 && (
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            <div>
              {uploadedDataMapped.length} {props.entityName} Uploaded
            </div>
            {uploadedDataMapped.some((rowData) => {
              return rowData.warnings.length > 0;
            }) && (
              <div>
                <FontAwesomeIcon className='text-warning' icon='fa fa-warning' />
                {`Rows: `}
                {uploadedDataMapped
                  .map((data, index) => {
                    return data.warnings.length > 0 ? index + 1 : -1;
                  })
                  .filter((index) => {
                    return index != -1;
                  })
                  .join(', ')}
              </div>
            )}
            {uploadedDataMapped.some((rowData) => {
              return rowData.errors && rowData.errors.length > 0;
            }) && (
              <div>
                <FontAwesomeIcon className='text-danger' icon='fa fa-warning' />
                {`Rows: `}
                {uploadedDataMapped
                  .map((data, index) => {
                    return data.errors.length > 0 ? index + 1 : -1;
                  })
                  .filter((index) => {
                    return index != -1;
                  })
                  .join(', ')}
              </div>
            )}
            {uploadedDataMapped.map((rowData, index) => {
              return props.dataPreview(rowData, index, uploadedDataMapped.length);
            })}
          </div>
        )}
        {activeStep === 2 && (
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            {submitStatus == 'submitting' && <ModalSubmitting />}
            {submitStatus == 'success' && (
              <div>
                <div style={{color: 'green', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-check' />
                </div>
                <span>Update Completed.</span>
              </div>
            )}
            {submitStatus == 'error' && (
              <div>
                <div style={{color: 'red', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-xmark' />
                </div>
                <span>An error has occured, please try again.</span>
                <div>
                  <strong>Error Message:</strong> {submitErrorMsg}
                </div>
                <div>
                  {errorUsers.map((username, index) => {
                    return (
                      <div key={index}>
                        User name <strong>{username}</strong> is already used, please use a different user name.
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
          {activeStep != 0 && activeStep != steps.length - 1 && (
            <Button
              color='inherit'
              onClick={() => {
                handleStep(-1);
                setAllowForceUpload(false);
              }}
              sx={{mr: 1}}
            >
              Back
            </Button>
          )}
          <Box sx={{flex: '1 1 auto'}} />
          {activeStep == 0 && (
            <Button
              onClick={() => {
                handleStep(1);
              }}
              disabled={!(uploadedFile && uploadedData.length > 0)}
            >
              Next
            </Button>
          )}
          {activeStep == 1 && (
            <React.Fragment>
              {!uploadedDataMapped.some((rowData) => {
                return rowData.errors.length > 0;
              }) &&
                uploadedDataMapped.some((rowData) => {
                  return rowData.warnings.length > 0;
                }) &&
                !allowForceUpload && (
                  <a
                    onClick={() => {
                      setAllowForceUpload(true);
                    }}
                    className='btn'
                  >
                    <small>Ignore Warnings, Upload with Default</small>
                  </a>
                )}
              <Button
                onClick={() => {
                  handleStep(1);
                  handleBulkUploadSubmit();
                }}
                disabled={
                  !(uploadedDataMapped && uploadedDataMapped.length > 0) ||
                  (uploadedDataMapped.some((data) => {
                    return data.errors.length > 0 || data.warnings.length > 0;
                  }) &&
                    !allowForceUpload)
                }
              >
                Submit
              </Button>
            </React.Fragment>
          )}
          {activeStep == 2 && submitStatus != 'submitting' && (
            <Button variant='contained' color='success' onClick={modalClose}>
              Close
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export {BulkUploadModal};

import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CoverPageCard} from '../components/CoverPageCard';
import {sendGAPageview, fetchPostAuthSafe} from '../app/utils';
import {useSelector} from 'react-redux';

function CreateUser() {
  const navigate = useNavigate();

  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [inputs, setInputs] = useState({
    username: '',
    email: '',
    adminCheck: false,
    password: '',
    confirmPassword: '',
    showPasswordCheck: false,
  });
  const [passwordVerify, setPasswordVerify] = useState({
    barClass: 'progress-bar bg-danger',
    textClass: 'text-danger',
    text: '',
    width: '0%',
  });
  const [warnings, setWarnings] = useState({});
  const [status, setStatus] = useState('');

  useEffect(() => {
    document.title = 'IntelliCulture | Create User';
    sendGAPageview(document.title);
  }, []);

  useEffect(() => {
    validateEmail();
  }, [inputs.email]);

  useEffect(() => {
    validatePassword();
  }, [inputs.password, inputs.confirmPassword]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setInputs((values) => {
      return {...values, [name]: value};
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    if (!warnings.passwordsNoMatch && !warnings.emailInvalid) {
      setStatus('submitting');
      const postData = {
        username: inputs.username.toLowerCase().trim(),
        userEmail: inputs.email.toLowerCase().trim(),
        password: inputs.password,
        admin: inputs.adminCheck,
        strength_idx: passwordVerify.text,
      };

      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(postData),
      };

      const response = await fetchPostAuthSafe(
        '/createUserSubmit',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const result = await response.json();

      if (result.status == 401) {
        navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
      } else if (result.usernameExists) {
        setStatus('');
        setWarnings((values) => {
          return {...values, usernameExists: true};
        });
      } else if (result.errorMsg) {
        navigate('/error', {state: {errorMsg: result.errorMsg}});
      } else if (result.status == 500) {
        navigate('/error', {
          state: {
            errorMsg:
              'Data out of sync. Ensure you are logged into the correct account. Refresh the page and try again.',
          },
        });
      } else {
        setStatus('success');
        setTimeout(() => {
          return navigate('/organization_settings');
        }, 2000);
      }
    }
  }

  function validatePassword() {
    const passwordStrength = zxcvbn(inputs.password);
    let barClass = 'progress-bar bg-danger';
    let textClass = 'text-danger';
    let text = '';
    let width = '0%';

    if (passwordStrength.score == 0 || passwordStrength.score == 1) {
      barClass = 'progress-bar bg-danger';
      textClass = 'text-danger';
      text = 'Weak';
    } else if (passwordStrength.score == 2 || passwordStrength.score == 3) {
      barClass = 'progress-bar bg-warning';
      textClass = 'text-warning';
      text = 'Average';
    } else {
      barClass = 'progress-bar bg-success';
      textClass = 'text-success';
      text = 'Strong';
    }

    if (inputs.password.length == 0) {
      text = '';
      width = '0%';
    } else {
      width = ((passwordStrength.score + 1) * 20).toString() + '%';
    }

    setPasswordVerify({
      barClass,
      textClass,
      text,
      width,
    });

    if (inputs.password == inputs.confirmPassword && inputs.password != '') {
      setWarnings((values) => {
        return {...values, passwordsNoMatch: false};
      });
    } else {
      setWarnings((values) => {
        return {...values, passwordsNoMatch: true};
      });
    }
  }

  function validateEmail() {
    // eslint-disable-next-line max-len
    const re =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setWarnings((values) => {
      return {...values, emailInvalid: !re.test(inputs.email)};
    });
  }

  return (
    <CoverPageCard>
      <div className='text-center'>
        <a href='https://www.intelliculture.ca/' target='_blank' className='card-logo' rel='noreferrer'>
          <img src='/img/Logo_Text_NoSlogan.png' alt='Logo' border='0' width='256' className='card-logo-img' />
        </a>
        <h1 id='heading' className='h4 text-gray-900 mb-4'>
          {status == 'success' ? 'User Created!' : 'Create User'}
        </h1>
      </div>
      {status != 'success' && (
        <React.Fragment>
          <hr />
          {/* Login needs a auth action and a post method */}
          <form className='user' onSubmit={handleSubmit}>
            <div className='form-group text-center'>
              <Link className='small' to='/invite_user'>
                Invite user by email
              </Link>
            </div>
            <div className='form-group'>
              <input
                type='text'
                name='username'
                className='form-control'
                placeholder='Enter Username...'
                value={inputs.username}
                onChange={handleChange}
                required
              />
              {warnings.usernameExists && (
                <div className='row text-s text-danger font-italic mb-1 ml-2'>Username already in use</div>
              )}
            </div>
            <div className='form-group'>
              <input
                type='email'
                className='form-control'
                name='email'
                placeholder='Enter Email...'
                value={inputs.email}
                onChange={handleChange}
                required
              />
              <div className='row my-1'>
                <div className='col-sm-12'>
                  <span>
                    {warnings.emailInvalid ? (
                      <FontAwesomeIcon icon='far fa-circle-xmark' style={{color: '#FF0004'}} />
                    ) : (
                      <FontAwesomeIcon icon='far fa-check-circle' style={{color: '#00A41E'}} />
                    )}
                  </span>{' '}
                  Email Valid
                </div>
              </div>
            </div>
            <div className='form-group ml-4'>
              <label className='form-check-label'>
                <input
                  type='checkbox'
                  name='adminCheck'
                  className='form-check-input'
                  value={inputs.adminCheck}
                  onChange={handleChange}
                />
                Create User as an Admin
              </label>
            </div>
            <div className='form-group'>
              <input
                type={inputs.showPasswordCheck ? 'text' : 'password'}
                name='password'
                className='form-control'
                placeholder='Enter Password...'
                value={inputs.password}
                onChange={handleChange}
                required
              />
              <div className='progress my-2'>
                <div className={passwordVerify.barClass} style={{width: passwordVerify.width}} />
              </div>
              <div className='small'>
                Password Strength: <span className={passwordVerify.textClass}>{passwordVerify.text}</span>
              </div>
            </div>
            <div className='form-group'>
              <input
                type={inputs.showPasswordCheck ? 'text' : 'password'}
                name='confirmPassword'
                className='form-control'
                placeholder='Confirm Password...'
                value={inputs.confirmPassword}
                onChange={handleChange}
                required
              />
              <div className='row my-1'>
                <div className='col-sm-12'>
                  <span>
                    {warnings.passwordsNoMatch ? (
                      <FontAwesomeIcon icon='far fa-circle-xmark' style={{color: '#FF0004'}} />
                    ) : (
                      <FontAwesomeIcon icon='far fa-check-circle' style={{color: '#00A41E'}} />
                    )}
                  </span>{' '}
                  Passwords Match
                </div>
              </div>
            </div>
            <div className='form-group ml-4'>
              <label className='form-check-label'>
                <input
                  type='checkbox'
                  name='showPasswordCheck'
                  className='form-check-input'
                  value={inputs.showPasswordCheck}
                  onChange={handleChange}
                />
                Show Password
              </label>
            </div>
            <input type='submit' className='btn btn-primary btn-block' disabled={status == 'submitting'} />
          </form>
          <hr />
          <div className='text-center'>
            <button
              type='button'
              className='btn border bg-white small'
              onClick={() => {
                return navigate('/organization_settings');
              }}
            >
              Cancel
            </button>
          </div>
        </React.Fragment>
      )}
    </CoverPageCard>
  );
}

export {CreateUser};

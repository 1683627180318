import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {FilterCard} from '../../../components/Card';
import {numberWithCommas, arrayToggle, unitsDisplayConversion, sortVehicleNamesHelper} from '../../../app/utils';
import {UpcomingTable} from './UpcomingTable';
import {DateTime} from 'luxon';

function UpcomingTab(props) {
  const loading = useSelector((state) => {
    return state.shopview.loading;
  });
  const maintenanceItems = useSelector((state) => {
    return state.shopview.maintenanceItems;
  });
  const maintenanceSummary = useSelector((state) => {
    return state.shopview.maintenanceSummary;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });
  const authenticationStatus = useSelector((state) => {
    return state.app.authenticationStatus;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const [tableData, setTableData] = useState([]);
  const [cardClass, setCardClass] = useState('col-6 my-1');
  const [cardValues, setCardValues] = useState({
    overdue: 0,
    upcoming: 0,
    inspectionFailure: 0,
  });
  const [reminderFilter, setReminderFilter] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    vehicles: [],
    tasks: [],
  });
  const [units, setUnits] = useState('km');

  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setUnits('mi');
    } else {
      setUnits('km');
    }
  }, [unitsSystem]);

  useEffect(() => {
    getUpcoming();
  }, [maintenanceItems]);

  useEffect(() => {
    if (authenticationStatus.inspectionEnabled) {
      setCardClass('col-6 col-md-4 my-1');
    }
  }, [authenticationStatus]);

  function handleFilterButtons(event) {
    const newFilters = arrayToggle(reminderFilter, event.currentTarget.name);
    setReminderFilter(newFilters);
  }

  function getUpcoming() {
    let rowList = [];
    const filterOptionsVehicle = [];
    const filterOptionsTask = [];

    // Iterate through maintenance items
    maintenanceItems.forEach((maintenanceItem) => {
      const serviceTask = maintenanceItem.serviceTask;

      // Create interval string
      let intervalString = '';
      if (serviceTask.time_interval) {
        intervalString = intervalString + `Every ${serviceTask.time_interval} ${serviceTask.time_units}`;
      }
      if (serviceTask.odometer_interval) {
        intervalString =
          intervalString +
          (intervalString != '' ? '<br>' : '') +
          `Every ${numberWithCommas(unitsDisplayConversion(serviceTask.odometer_interval, units))} ${units}`;
      }
      if (serviceTask.engine_hours_interval) {
        intervalString =
          intervalString +
          (intervalString != '' ? '<br>' : '') +
          `Every ${numberWithCommas(serviceTask.engine_hours_interval)} engine hours`;
      }

      // Create due in string
      let dueString = '';
      if (
        serviceTask.time_interval ||
        (serviceTask.next_service_date &&
          (maintenanceItem.noHistory ||
            (serviceTask.inspectionReportIds && serviceTask.inspectionReportIds.length > 0)))
      ) {
        const timeDiff = maintenanceItem.timeDiffDays;

        // Determine display based on units
        let unitsDisplay = 'Days';
        let timeDisplay = Math.abs(timeDiff);
        if (timeDisplay >= 30) {
          unitsDisplay = 'Months';
          timeDisplay = Math.floor(timeDisplay / 30);
        } else if (timeDisplay >= 7) {
          unitsDisplay = 'Weeks';
          timeDisplay = Math.floor(timeDisplay / 7);
        }

        if (serviceTask.inspectionReportIds && serviceTask.inspectionReportIds.length > 0) {
          dueString += `<b>${timeDisplay} ${unitsDisplay} Since Reported</b>`;
        } else if (timeDiff < 0) {
          dueString += `<b>${timeDisplay} ${unitsDisplay} Overdue</b>`;
        } else {
          dueString += `${timeDisplay} ${unitsDisplay}`;
        }
      }
      if (serviceTask.odometer_interval || (serviceTask.next_service_odometer && maintenanceItem.noHistory)) {
        const odometerDiff = maintenanceItem.odometerDiffKm;
        if (odometerDiff < 0) {
          dueString +=
            (dueString ? '<br>' : '') +
            `<b>${numberWithCommas(Math.abs(unitsDisplayConversion(odometerDiff, units)))} ${units} Overdue</b>`;
        } else {
          dueString +=
            (dueString ? '<br>' : '') +
            `${numberWithCommas(Math.abs(unitsDisplayConversion(odometerDiff, units)))} ${units}`;
        }
      }
      if (serviceTask.engine_hours_interval || (serviceTask.next_service_engine_hours && maintenanceItem.noHistory)) {
        const engineHoursDiff = maintenanceItem.engineHoursDiff;
        if (engineHoursDiff < 0) {
          dueString +=
            (dueString ? '<br>' : '') +
            `<b>${numberWithCommas(Math.round(Math.abs(engineHoursDiff)))} Engine Hours Overdue</b>`;
        } else {
          dueString +=
            (dueString ? '<br>' : '') + `${numberWithCommas(Math.round(Math.abs(engineHoursDiff)))} Engine Hours`;
        }
      }

      // Determine how much time ago was completed
      const timeDiffToLast = maintenanceItem.lastCompletedTimeDiffDays;
      let unitsDisplay = 'Days';
      let timeDisplay = Math.abs(timeDiffToLast);
      if (timeDisplay >= 30) {
        unitsDisplay = 'Months';
        timeDisplay = Math.floor(timeDisplay / 30);
      } else if (timeDisplay >= 7) {
        unitsDisplay = 'Weeks';
        timeDisplay = Math.floor(timeDisplay / 7);
      }
      const lastCompletedTimeDiffString = `${timeDisplay} ${unitsDisplay} Ago`;

      const rowInfo = {
        serviceTask: serviceTask,
        vehicle: maintenanceItem.vehicleName,
        vehicleSN: maintenanceItem.vehicleSN,
        machineType: maintenanceItem.machineType,
        task: serviceTask.name,
        overdue: maintenanceItem.overdue,
        upcoming: maintenanceItem.upcoming,
        inspectionFailure: maintenanceItem.inspectionFailure,
        intervalString: intervalString,
        dueString: dueString,
        noHistory: maintenanceItem.noHistory,
        lastCompletedDate: maintenanceItem.lastCompleted.slice(0, 10),
        lastCompletedTimeDiff: lastCompletedTimeDiffString,
        lastCompletedOdometer: maintenanceItem.lastCompletedOdometerKm,
        lastCompletedEngineHours: maintenanceItem.lastCompletedEngineHours,
        reviewedBy: maintenanceItem.lastCompletedReviewedBy,
        timeDiffDays: maintenanceItem.timeDiffDays === null ? Infinity : maintenanceItem.timeDiffDays,
        odometerDiff: maintenanceItem.odometerDiffKm === null ? Infinity : maintenanceItem.odometerDiffKm,
        engineHoursDiff: maintenanceItem.engineHoursDiff === null ? Infinity : maintenanceItem.engineHoursDiff,
        currentOdometer: maintenanceItem.currentOdometerKm,
        currentEngineHours: maintenanceItem.currentEngineHours,
      };
      rowList.push(rowInfo);

      // Update filter options
      filterOptionsVehicle.push(maintenanceItem.vehicleName);
      filterOptionsTask.push(serviceTask.name);
    });

    // Add filter options to dropdowns
    filterOptionsVehicle.sort((a, b) => {
      return sortVehicleNamesHelper(a, b);
    });
    filterOptionsTask.sort();
    setFilterOptions((values) => {
      return {...values, vehicles: [...new Set(filterOptionsVehicle)], tasks: [...new Set(filterOptionsTask)]};
    });

    // Sort rows based on overdue, upcoming, and no tags
    const rowListInspection = rowList.filter((row) => {
      return row.inspectionFailure;
    });
    const rowListOverdue = rowList.filter((row) => {
      return row.overdue;
    });
    const rowListUpcoming = rowList.filter((row) => {
      return row.upcoming && !row.overdue;
    });
    const rowListNormal = rowList.filter((row) => {
      return !row.upcoming && !row.overdue && !row.inspectionFailure;
    });

    rowListInspection.sort((a, b) => {
      return (
        Math.min(a.timeDiffDays, a.odometerDiff, a.engineHoursDiff) -
        Math.min(b.timeDiffDays, b.odometerDiff, b.engineHoursDiff)
      );
    });
    rowListOverdue.sort((a, b) => {
      return (
        Math.min(a.timeDiffDays, a.odometerDiff, a.engineHoursDiff) -
        Math.min(b.timeDiffDays, b.odometerDiff, b.engineHoursDiff)
      );
    });
    rowListUpcoming.sort((a, b) => {
      return (
        Math.min(a.timeDiffDays, a.odometerDiff, a.engineHoursDiff) -
        Math.min(b.timeDiffDays, b.odometerDiff, b.engineHoursDiff)
      );
    });
    rowListNormal.sort((a, b) => {
      return (
        Math.min(a.timeDiffDays, a.odometerDiff, a.engineHoursDiff) -
        Math.min(b.timeDiffDays, b.odometerDiff, b.engineHoursDiff)
      );
    });
    rowList = rowListInspection.concat(rowListOverdue, rowListUpcoming, rowListNormal);

    setTableData(rowList);
    setCardValues({
      overdue: maintenanceSummary.totalOverdue,
      upcoming: maintenanceSummary.totalUpcoming,
      inspectionFailure: maintenanceSummary.totalInspectionFailure,
    });
  }

  return (
    <div className='tab-wrapper'>
      {/* Card for OverDue etc. in Shopview */}
      <div className='row my-3 my-sm-3 tab-top-row'>
        <div className={cardClass}>
          <FilterCard
            color='danger'
            value={cardValues.overdue}
            text='Overdue'
            icon='fa-exclamation-circle'
            name='overdue'
            filterOnClick={handleFilterButtons}
            filterActive={reminderFilter.includes('overdue')}
          />
        </div>
        <div className={cardClass}>
          <FilterCard
            color='warning'
            value={cardValues.upcoming}
            text='Upcoming'
            icon='fa-stopwatch'
            name='upcoming'
            filterOnClick={handleFilterButtons}
            filterActive={reminderFilter.includes('upcoming')}
          />
        </div>

        {authenticationStatus.inspectionEnabled && (
          <div className='col-12 col-md-4 my-1'>
            <FilterCard
              color='primary'
              value={cardValues.inspectionFailure}
              text='Inspection Failure'
              smallText={!smallScreen}
              icon='fa-exclamation-triangle'
              name='inspectionFailure'
              filterOnClick={handleFilterButtons}
              filterActive={reminderFilter.includes('inspectionFailure')}
            />
          </div>
        )}
      </div>
      <UpcomingTable
        tableData={tableData}
        filterOptions={filterOptions}
        reminderFilter={reminderFilter}
        loading={loading}
      />
    </div>
  );
}

export {UpcomingTab};

import React from 'react';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalWideColumnSection,
  ModalBody,
} from '../../../components/Modal';

function DiagnosticsDataModal(props) {
  const initialValues = useSelector((state) => {
    return state.diagnosticsModal;
  });

  return createPortal(
    <ModalFramework id='shopview-diagnostics-data-modal'>
      <ModalHeader title='Fault Details'>
        <span className='h5'>{initialValues.vehicle}</span>
      </ModalHeader>
      <ModalBody>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Fault Code</div>
            <div className='small pl-2'>{initialValues.faultCode}</div>
          </ModalWideColumnSection>
        </ModalRowSection>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Fault Description</div>
            <div className='small pl-2'>{initialValues.faultDescription}</div>
          </ModalWideColumnSection>
        </ModalRowSection>
      </ModalBody>
      <ModalFooter />
    </ModalFramework>,
    document.getElementById('app')
  );
}

export {DiagnosticsDataModal};

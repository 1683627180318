import {createSlice} from '@reduxjs/toolkit';

const coverageModalSlice = createSlice({
  name: 'coverageModal',
  initialState: {
    type: '',
    zone: '',
    time: '',
    timeInField: '',
    timeOutField: '',
    timeIdle: '',
    timeTotal: '',
    coverage: '',
    fullEquipmentDict: {},
    equipment: '',
    task: '',
  },
  reducers: {
    setInitialValues(state, action) {
      const modalData = action.payload;
      state.type = modalData.type;

      if (modalData.type == 'equipment') {
        state.name = modalData.name;
        state.timeInField = modalData.timeInFieldFormatted;
        state.timeOutField = modalData.timeOutFieldFormatted;
        state.timeIdle = modalData.timeIdleFormatted;
        state.timeTotal = modalData.timeTotalFormatted;
        state.coverage = modalData.coverage;
        state.taskName = modalData.taskName;
      } else {
        state.zoneName = modalData.zoneName;
        state.zoneTimeTotal = modalData.zoneTimeTotalFormatted;
        state.coverage = modalData.coverage;
        state.fullEquipmentDict = modalData.fullEquipmentDict;
      }
    },
  },
});

export const {setInitialValues} = coverageModalSlice.actions;
export default coverageModalSlice.reducer;

import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Tabs} from '../../../components/Tabs';
import {TabContent} from '../../../components/TabContent';
import {VehicleInfo} from './VehicleInfo';
import {ImplementsSettings} from './Implements';
import {LabelsTab} from './LabelsTab';
import {BtTagsTab} from './BtTagsTab';

function AssetsTab(props) {
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const [assetsTabs, setAssetsTabs] = useState([]);
  const [assetsIcons, setAssetsIcons] = useState([]);
  const [assetsContent, setAssetsContent] = useState([]);

  useEffect(async () => {
    determineTabDisplay();
  }, [userSettings, customerSettings]);

  function determineTabDisplay() {
    const tabs = [];
    const icons = [];
    const content = [];

    // Vehicles
    if (
      userSettings.roleViewAccess['vehicleAttributesManagement'] ||
      userSettings.roleViewAccess['vehicleStatusManagement'] ||
      userSettings.roleViewAccess['vehicleFeaturesManagement']
    ) {
      tabs.push('Vehicles');
      icons.push('fa-solid fa-tractor');
      content.push(<VehicleInfo key='Vehicles' />);
    }

    // Implements
    if (userSettings.roleViewAccess['implementManagement']) {
      tabs.push('Implements');
      icons.push('fa-solid fa-tag');
      content.push(<ImplementsSettings key='Implements' />);
    }

    // Labels
    if (userSettings.roleViewAccess['labelManagement']) {
      tabs.push('QR Code');
      icons.push('fa-solid fa-qrcode');
      content.push(<LabelsTab key='QR Code' />);
    }

    // Bt Tags
    if (userSettings.roleViewAccess['bluetoothManagement'] && customerSettings.general.enableBluetoothTags) {
      tabs.push('BT Tags');
      icons.push('fa-solid fa-wifi');
      content.push(<BtTagsTab key='BT Tags' />);
    }

    setAssetsTabs(tabs);
    setAssetsIcons(icons);
    setAssetsContent(content);
  }

  return (
    <div className='tab-wrapper'>
      <div className='row'>
        <div className='col-12'>
          <Tabs tabs={assetsTabs} icons={assetsIcons} sub={true} />
        </div>
      </div>
      <TabContent content={assetsContent} />
    </div>
  );
}

export {AssetsTab};

import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CoverPageCard} from '../components/CoverPageCard';
import {sendGAPageview, fetchPostAuthSafe} from '../app/utils';

function InviteUser() {
  const navigate = useNavigate();
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [inputs, setInputs] = useState({
    email: '',
  });
  const [warnings, setWarnings] = useState({});
  const [status, setStatus] = useState('');

  useEffect(() => {
    document.title = 'IntelliCulture | Invite User';
    sendGAPageview(document.title);
  }, []);

  useEffect(() => {
    validateEmail();
  }, [inputs.email]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => {
      return {...values, [name]: value};
    });
  };

  return (
    <CoverPageCard>
      <div className='text-center'>
        <a href='https://www.intelliculture.ca/' target='_blank' className='card-logo' rel='noreferrer'>
          <img src='/img/Logo_Text_NoSlogan.png' alt='Logo' border='0' width='256' className='card-logo-img' />
        </a>
        <h1 id='heading' className='h4 text-gray-900 mb-4'>
          {status == 'success' ? 'Email Sent!' : 'Invite User'}
        </h1>
      </div>
      {status != 'success' && (
        <React.Fragment>
          <hr />
          {/* Login needs a auth action and a post method */}
          <form className='user' onSubmit={handleSubmit}>
            <div className='form-group text-center'>
              <Link className='small' to='/create_user'>
                Create user yourself
              </Link>
            </div>
            <div className='form-group'>
              <input
                type='email'
                className='form-control'
                name='email'
                placeholder='Enter Email...'
                value={inputs.email}
                onChange={handleChange}
                required
              />
              <div className='row my-1'>
                <div className='col-sm-12'>
                  <span>
                    {warnings.emailInvalid ? (
                      <FontAwesomeIcon icon='far fa-circle-xmark' style={{color: '#FF0004'}} />
                    ) : (
                      <FontAwesomeIcon icon='far fa-check-circle' style={{color: '#00A41E'}} />
                    )}
                  </span>{' '}
                  Email Valid
                </div>
              </div>
            </div>
            <input type='submit' className='btn btn-primary btn-block' disabled={status == 'submitting'} />
          </form>
          <hr />
          <div className='text-center'>
            <button
              type='button'
              className='btn border bg-white small'
              onClick={() => {
                return navigate('/organization_settings');
              }}
            >
              Cancel
            </button>
          </div>
        </React.Fragment>
      )}
    </CoverPageCard>
  );

  async function handleSubmit(event) {
    event.preventDefault();
    if (!warnings.emailInvalid) {
      setStatus('submitting');
      const postData = {
        userEmail: inputs.email,
      };
      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(postData),
      };
      const response = await fetchPostAuthSafe(
        '/inviteUserSubmit',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const result = await response.json();

      if (result.status == 401) {
        navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
      } else if (result.errorMsg) {
        navigate('/error', {state: {errorMsg: result.errorMsg}});
      } else {
        setStatus('success');
        setTimeout(() => {
          return navigate('/organization_settings');
        }, 2000);
      }
    }
  }

  function validateEmail() {
    // eslint-disable-next-line max-len
    const re =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setWarnings((values) => {
      return {...values, emailInvalid: !re.test(inputs.email)};
    });
  }
}

export {InviteUser};

import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function Tabs(props) {
  return (
    <ul
      className={`nav nav-pills nav-justified ${props.sub ? 'nav-custom-color-sub' : 'nav-custom-color'} mt-1`}
      role='tablist'
    >
      {props.tabs.map((tab, index) => {
        if (props.onClickHandler) {
          let opacity = '100%';
          if (props.activeTab) {
            if (tab.toLowerCase() != props.activeTab.toLowerCase()) {
              opacity = '70%';
            }
          }

          return (
            <li className='nav-item px-0 text-center' key={index}>
              <a
                onClick={() => {
                  props.onClickHandler(tab);
                }}
                className={`nav-link ${
                  props?.activeTab
                    ? tab.toLowerCase() == props.activeTab.toLowerCase() && 'active'
                    : index == 0
                    ? 'active'
                    : ''
                }`}
                style={{opacity: opacity}}
                data-toggle='tab'
                href={'#' + tab.toLowerCase().replace(/^[^a-z]+|[^\w:.-]+/gi, '')} // regex to remove invalid id characters
                aria-controls={tab.toLowerCase().replace(/^[^a-z]+|[^\w:.-]+/gi, '')} // regex to remove invalid id characters
                aria-selected={index === 0 ? 'true' : 'false'}
              >
                {props?.icons && <FontAwesomeIcon icon={props?.icons[index]} />}
                <span className={props?.icons ? 'tab-name' : ''}>
                  {props.displayNames ? props?.displayNames[index] : tab}
                </span>
              </a>
            </li>
          );
        } else {
          return (
            <li className='nav-item px-0 text-center' key={index}>
              <a
                className={`nav-link ${
                  props?.activeTab
                    ? tab.toLowerCase() == props.activeTab.toLowerCase() && 'active'
                    : index == 0
                    ? 'active'
                    : ''
                }`}
                data-toggle='tab'
                href={'#' + tab.toLowerCase().replace(/^[^a-z]+|[^\w:.-]+/gi, '')} // regex to remove invalid id characters
                aria-controls={tab.toLowerCase().replace(/^[^a-z]+|[^\w:.-]+/gi, '')} // regex to remove invalid id characters
                aria-selected={index === 0 ? 'true' : 'false'}
              >
                {props?.icons && <FontAwesomeIcon icon={props?.icons[index]} />}
                <span className={props?.icons ? 'tab-name' : ''}>{tab}</span>
              </a>
            </li>
          );
        }
      })}
    </ul>
  );
}

export {Tabs};

import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {ModalRowSection, ModalWideColumnSectionCentered, ModalBody} from '../../components/Modal';

import {setInspectionSelectedVehicle, setInspectionFormSubmitted, setInspectionReport} from './inspectionFormSlice';

function InspectionFormSubmissionConfirmModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleCreate() {
    // Reset all inspection form temp data
    dispatch(setInspectionSelectedVehicle({}));
    dispatch(setInspectionFormSubmitted(false));
    dispatch(setInspectionReport({}));
  }

  function handleHome() {
    // Reset all inspection form temp data
    dispatch(setInspectionSelectedVehicle({}));
    dispatch(setInspectionFormSubmitted(false));
    dispatch(setInspectionReport({}));

    // Navigate back to home page
    navigate('/');
  }

  // Create modal for inspection report submission confirmation
  return (
    <div>
      <ModalBody>
        <ModalRowSection>
          <ModalWideColumnSectionCentered>
            <h2 className='font-weight-bold'>Inspection Log Submitted!</h2>
          </ModalWideColumnSectionCentered>

          <ModalWideColumnSectionCentered>
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleHome}
              style={{marginTop: 10, marginBottom: 10, width: 200, height: 50}}
            >
              Home
            </button>
          </ModalWideColumnSectionCentered>

          <ModalWideColumnSectionCentered>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={handleCreate}
              style={{marginTop: 10, width: 200, height: 50}}
            >
              Create Another
            </button>
          </ModalWideColumnSectionCentered>
        </ModalRowSection>
      </ModalBody>
    </div>
  );
}

export {InspectionFormSubmissionConfirmModal};

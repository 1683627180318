import React, {useState, useEffect} from 'react';
import DoughnutChart from '../../../components/DoughnutChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, machineTypeMapping, machineTypeReverseMapping, deepCopy} from '../../../app/utils';
import {inVsOutPie} from '../dashboardDataProcessing';
import {KpiTooltip, ScorecardSelectHolder, KpiTable} from '../dashboardUtils';
import {Select, MenuItem, CircularProgress} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

function KpiOperationalEfficiency(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const inOutTime = useSelector((state) => {
    return state.dashboard.inOutTime;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const [inVsOutContent, setInVsOutContent] = useState(null);
  const [totalHours, setTotalHours] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [selectedMachineType, setSelectedMachineType] = useState('All');
  const [machineTypeOptions, setMachineTypeOptions] = useState([]);
  const [targetsObject, setTargetsObject] = useState({});
  const [filters, setFilters] = useState({
    'oppEffVehSNs': [],
  });
  const [filterOptions, setFilterOptions] = useState({
    'oppEffVehSNs': [],
  });
  function handleFilters(value) {
    setFilters({
      'oppEffVehSNs': value,
    });
  }

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [operationsData, inOutTime, vehicleSNDict, selectedMachineType, inOutTime, props.pageStates, filters]);

  useEffect(() => {
    if (props.pageStates?.selectedMachineType) {
      setSelectedMachineType(props.pageStates?.selectedMachineType);
    } else {
      setSelectedMachineType('All');
    }
    if (props.pageStates?.filters) {
      setFilters(props.pageStates.filters);
    }
  }, [props.pageStates]);

  useEffect(() => {
    if (operationsData && operationsData.hasOwnProperty('vehiclesObject')) {
      let vehFilterOptionsTemp = Object.keys(operationsData.vehiclesObject)
        .map((vehSN) => {
          return {
            value: vehSN,
            text: operationsData.vehiclesObject[vehSN].vehicleName,
          };
        })
        .sort((optA, optB) => {
          return optA.text.localeCompare(optB.text);
        });

      // If we have scorecard vehicles filtered, apply that
      if (props.pageStates?.filters) {
        vehFilterOptionsTemp = vehFilterOptionsTemp.filter((opt) => {
          return props.pageStates.filters['oppEffVehSNs'].includes(opt.value);
        });
      }

      // If we have specific type selected apply that as well
      if (selectedMachineType != 'All') {
        vehFilterOptionsTemp = vehFilterOptionsTemp.filter((opt) => {
          return operationsData.vehiclesObject[opt.value].type == machineTypeReverseMapping[selectedMachineType];
        });

        setFilterOptions({'oppEffVehSNs': vehFilterOptionsTemp});
      } else {
        setFilterOptions({'oppEffVehSNs': vehFilterOptionsTemp});
      }
    }
  }, [selectedMachineType, props.pageStates, operationsData]);

  function generateData() {
    const tempTargetsObject = {};

    // Get targets
    let inFieldTarget = customerSettings?.targets?.totalInFieldPercent ?? 0;
    if (selectedMachineType != 'All') {
      inFieldTarget = customerSettings?.targets?.typeInFieldPercent[machineTypeReverseMapping[selectedMachineType]];
      if (typeof inFieldTarget === 'undefined') {
        if (customerSettings?.targets?.totalInFieldPercent) {
          inFieldTarget = customerSettings?.targets?.totalInFieldPercent;
        } else {
          inFieldTarget = 0;
        }
      }
    }
    tempTargetsObject['All'] = inFieldTarget;
    if (
      props.hasOwnProperty('pageStates') &&
      props.pageStates != undefined &&
      props.pageStates.hasOwnProperty('pageTargets')
    ) {
      inFieldTarget = props.pageStates.pageTargets['All'];
    }
    // Build in field vs out of field by type / all
    const typeOptions = [];
    const inVsOutObject = {
      'All': {
        inField: Math.round(inOutTime.inFieldTime),
        outField: Math.round(inOutTime.outFieldTime),
      },
    };

    Object.keys(machineTypeMapping).forEach((key) => {
      if (operationsData.vehicleHoursByTypeObject.hasOwnProperty(key)) {
        typeOptions.push({value: machineTypeMapping[key], label: machineTypeMapping[key]});
        inVsOutObject[machineTypeMapping[key]] = {
          inField: Math.round(operationsData.vehicleHoursByTypeObject[key].inFieldTime * 100) / 100,
          outField: Math.round(operationsData.vehicleHoursByTypeObject[key].outOfFieldTime * 100) / 100,
        };
      }
    });
    typeOptions.unshift({value: 'All', label: 'All'});

    // Generate chart data
    const inVsOutContentTemp = inVsOutPie(
      inVsOutObject[selectedMachineType]?.inField,
      inVsOutObject[selectedMachineType]?.outField,
      Math.round(
        inFieldTarget * (inVsOutObject[selectedMachineType]?.inField + inVsOutObject[selectedMachineType]?.outField)
      ) / 100,
      inFieldTarget
    );

    if (Object.keys(operationsData.vehiclesObject).length > 0 && filters['oppEffVehSNs'].length == 0) {
      setInVsOutContent(inVsOutContentTemp);
      const totalHoursTemp = inVsOutObject[selectedMachineType]?.inField + inVsOutObject[selectedMachineType]?.outField;
      setTotalHours(totalHoursTemp);
    } else if (filters['oppEffVehSNs'].length > 0) {
      let inFieldSum = 0;
      let outFieldSum = 0;
      if (selectedMachineType == 'All') {
        Object.keys(operationsData.vehiclesObject).forEach((vehicleSN) => {
          if (filters['oppEffVehSNs'].includes(vehicleSN)) {
            inFieldSum += operationsData.vehiclesObject[vehicleSN]['inFieldTime'];
            outFieldSum += operationsData.vehiclesObject[vehicleSN]['outOfFieldTime'];
          }
        });
      } else {
        Object.keys(operationsData.vehiclesObject).forEach((vehicleSN) => {
          if (
            filters['oppEffVehSNs'].includes(vehicleSN) &&
            operationsData.vehiclesObject[vehicleSN]['type'] == machineTypeReverseMapping[selectedMachineType]
          ) {
            inFieldSum += operationsData.vehiclesObject[vehicleSN]['inFieldTime'];
            outFieldSum += operationsData.vehiclesObject[vehicleSN]['outOfFieldTime'];
          }
        });
      }

      const inVsOutContentFiltered = inVsOutPie(
        inFieldSum,
        outFieldSum,
        Math.round(inFieldTarget * (inFieldSum + outFieldSum)) / 100,
        inFieldTarget
      );
      setInVsOutContent(inVsOutContentFiltered);
      const totalHoursTemp = inFieldSum + outFieldSum;
      setTotalHours(totalHoursTemp);
    } else {
      setInVsOutContent(null);
      const totalHoursTemp = inVsOutObject[selectedMachineType]?.inField + inVsOutObject[selectedMachineType]?.outField;
      setTotalHours(totalHoursTemp);
    }

    // Generate table data
    const tableData = [];
    const vehicleKeysFiltered = Object.keys(operationsData.vehiclesObject).filter((key) => {
      return filters['oppEffVehSNs'].includes(key) || filters['oppEffVehSNs'].length == 0;
    });
    vehicleKeysFiltered.forEach((vehicleSN) => {
      const vehicle = operationsData.vehiclesObject[vehicleSN];
      const inFieldDenominator = vehicle.inFieldTime + vehicle.outOfFieldTime;

      let inFieldPercent = 0;
      if (inFieldDenominator != 0) {
        inFieldPercent = Math.round((vehicle.inFieldTime / inFieldDenominator) * 100);
      }

      if (
        Object.prototype.hasOwnProperty.call(vehicleSNDict, vehicleSN) &&
        (selectedMachineType == 'All' ||
          machineTypeMapping[vehicleSNDict[vehicleSN].machineType] == selectedMachineType)
      ) {
        let vehicleInFieldTarget = inFieldTarget;
        if (customerSettings?.targets?.typeInFieldPercent[vehicleSNDict[vehicleSN].machineType]) {
          vehicleInFieldTarget = customerSettings?.targets?.typeInFieldPercent[vehicleSNDict[vehicleSN].machineType];
        }
        tableData.push({
          vehicleName: vehicle.vehicleName,
          inFieldPercent: inFieldPercent,
          type: machineTypeMapping[vehicleSNDict[vehicleSN].machineType],
          inFieldTarget:
            props.hasOwnProperty('pageStates') &&
            props.pageStates != undefined &&
            props.pageStates.hasOwnProperty('pageTargets') &&
            props.pageStates.pageTargets.hasOwnProperty(vehicleSN)
              ? props.pageStates.pageTargets[vehicleSN]
              : vehicleInFieldTarget,
        });
        tempTargetsObject[vehicleSN] = vehicleInFieldTarget;
      }
    });
    setTableData(tableData);
    setTargetsObject(tempTargetsObject);
    // Populate filters
    setMachineTypeOptions(typeOptions);
  }

  function operationalEfficiencyGraph() {
    return (
      <div style={{height: '50vh', maxHeight: '400px'}}>
        {/* Chart */}
        <DoughnutChart
          data={inVsOutContent}
          options={{
            plugins: {
              datalabels: {
                color: '#fff', // Label text color
                font: {
                  size: 16,
                  weight: 'bold',
                },
                formatter: (value, context) => {
                  if (value == 0 || totalHours == 0) {
                    return '';
                  }
                  return Math.round((value / totalHours) * 100) + '%';
                },
              },
              targetLine: inVsOutContent.datasets.length > 0 && inVsOutContent.datasets[0]?.target !== 0,
              title: {
                display: true,
                text: 'In Field vs. Out of Field Time',
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiOperationalEfficiency' />
        </div>
        {!inVsOutContent ? (
          <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
        ) : (
          <React.Fragment>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              {operationalEfficiencyGraph()}
            </div>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              <KpiTable
                pageStates={props.pageStates}
                tableData={tableData}
                type={'operationalEff'}
                filters={filters}
                filterOptions={filterOptions}
                handleFilters={handleFilters}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return (
    <div className='row d-flex mx-0 px-0 justify-content-center align-items-center mt-1'>
      {/* Dropdowns */}

      {/* Tooltip */}
      <div className='col-4 flex-col ml-n1'>
        <KpiTooltip
          selectedSubset='kpiOperationalEfficiency'
          pageStates={{
            selectedMachineType: selectedMachineType,
          }}
        />
      </div>
      <div className='col-4 flex-col text-center'>
        <Select
          size='small'
          className='col-12'
          displayEmpty
          value={selectedMachineType}
          onChange={(e) => {
            setSelectedMachineType(e.target.value);
          }}
        >
          {machineTypeOptions.map((option, idx) => {
            return (
              <MenuItem key={idx} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div className='col-4 px-0'>
        <ScorecardSelectHolder
          pageStates={{pageTargets: targetsObject, selectedMachineType: selectedMachineType, filters: filters}}
          autofillPageName={'Farming Operational Efficiency'}
        />
      </div>

      {!inVsOutContent ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
            {operationalEfficiencyGraph()}
          </div>
          <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
            <KpiTable
              pageStates={props.pageStates}
              tableData={tableData}
              type={'operationalEff'}
              filters={filters}
              filterOptions={filterOptions}
              handleFilters={handleFilters}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export {KpiOperationalEfficiency};

import React, {useState, useEffect} from 'react';
import DoughnutChart from '../../../components/DoughnutChart';
import {useSelector} from 'react-redux';
import {customChartProperties} from '../../../app/utils';
import {genPieDataInspections} from '../dashboardDataProcessing';
import {KpiTooltip, ScorecardSelectHolder, KpiTable} from '../dashboardUtils';
import {DateTime} from 'luxon';
import {CircularProgress} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import {sortVehicleNamesHelper} from '../../../app/utils';

ChartJS.register(annotationPlugin);

function KpiInspectionRates(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });

  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });

  const inspectionsData = useSelector((state) => {
    return state.dashboard.inspectionsData;
  });
  const inspectionsDict = useSelector((state) => {
    return state.dashboard.inspectionsDict;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const [filters, setFilters] = useState({
    'inspectionVehicleSNs': [],
  });

  const [filterOptions, setFilterOptions] = useState({
    'inspectionVehicleSNs': [],
  });

  function handleFilters(value) {
    setFilters((values) => {
      return {...values, 'inspectionVehicleSNs': value};
    });
  }

  const [inspectionPieData, setInspectionPieData] = useState(null);
  const [totalInspections, setTotalInspections] = useState(0);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (props?.pageFilters) {
      setFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
  }, [props.pageStates, props.pageFilters]);

  useEffect(() => {
    const vehicleSNs = Object.keys(vehicleSNDict);
    vehicleSNs.sort((vehicleSNa, vehicleSNb) => {
      const aName = vehicleSNDict[vehicleSNa].name;
      const bName = vehicleSNDict[vehicleSNb].name;
      return sortVehicleNamesHelper(aName, bName);
    });
    const vehOptions = vehicleSNs.map((sn) => {
      return {
        value: sn,
        text: vehicleSNDict[sn].name,
      };
    });
    setFilterOptions({'inspectionVehicleSNs': vehOptions});
  }, [operationsData, vehicleSNDict]);

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [operationsData, inspectionsData, inspectionsDict, filters]);

  function generateData() {
    if (inspectionsData && inspectionsDict) {
      const vehFiltersMappedToNames = filters['inspectionVehicleSNs'].map((sn) => {
        if (vehicleSNDict.hasOwnProperty(sn) && vehicleSNDict[sn].hasOwnProperty('name')) {
          return vehicleSNDict[sn].name;
        }
      });

      // Generate data
      const inspectionPieDataTemp = genPieDataInspections(inspectionsData.totalResults);
      if (inspectionsData.totalResults.pass + inspectionsData.totalResults.fail > 0) {
        setInspectionPieData(inspectionPieDataTemp);
      } else {
        setInspectionPieData(null);
      }
      const totalInspectionsTemp = inspectionsData.totalResults.pass + inspectionsData.totalResults.fail;
      setTotalInspections(totalInspectionsTemp);

      if (filters['inspectionVehicleSNs'].length > 0) {
        const filteredTotalResults = {pass: 0, fail: 0};
        Object.keys(inspectionsData.vehicleResults).forEach((vehName) => {
          if (vehFiltersMappedToNames.includes(vehName)) {
            filteredTotalResults.pass += inspectionsData.vehicleResults[vehName].pass;
            filteredTotalResults.fail += inspectionsData.vehicleResults[vehName].fail;
          }
        });

        const inspectionPieDataFiltered = genPieDataInspections(filteredTotalResults);
        if (filteredTotalResults.pass + filteredTotalResults.fail > 0) {
          setInspectionPieData(inspectionPieDataFiltered);
          const totalInspectionsTemp = filteredTotalResults.pass + filteredTotalResults.fail;
          setTotalInspections(totalInspectionsTemp);
        } else {
          setInspectionPieData(null);
        }
      }

      // Generate table data
      const inspectionsTableDataUnfiltered = Object.keys(inspectionsData.vehicleResults).map((vehicleName) => {
        let failedItemsString = '';
        let failedItemsDatesString = '';
        inspectionsData.vehicleResults[vehicleName].failedItems.forEach((item) => {
          let dateTimeString = item.dateTime;
          if (typeof dateTimeString == 'object') {
            dateTimeString = item.dateTime['@ts'];
          }
          failedItemsString += inspectionsDict[item.id].name + ',\n';
          failedItemsDatesString +=
            DateTime.fromISO(dateTimeString).setZone(customerSettings.general.timeZone).toLocaleString() + ',\n';
        });
        return {
          vehicleName: vehicleName,
          failures: inspectionsData.vehicleResults[vehicleName].fail,
          failedItems: failedItemsString.slice(0, -2),
          failedItemsDates: failedItemsDatesString.slice(0, -2),
        };
      });

      const inspectionsTableData = inspectionsTableDataUnfiltered.filter((row) => {
        return (
          row.failures > 0 &&
          (vehFiltersMappedToNames.includes(row.vehicleName) || filters['inspectionVehicleSNs'].length == 0)
        );
      });
      setTableData(inspectionsTableData);
    }
  }

  function inspectionRatesGraph() {
    return (
      <div style={{height: '50vh', maxHeight: '400px'}}>
        {/* Chart */}
        <DoughnutChart
          data={inspectionPieData}
          options={{
            plugins: {
              datalabels: {
                color: '#fff', // Label text color
                font: {
                  size: 16,
                  weight: 'bold',
                },
                formatter: (value, context) => {
                  if (value == 0 || totalInspections == 0) {
                    return '';
                  }
                  return Math.round((value / totalInspections) * 100) + '%';
                },
              },
              title: {
                display: true,
                text: 'Inspection Pass Rate',
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }
  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiInspectionRates' />
        </div>
        {!inspectionPieData ? (
          <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
        ) : (
          <React.Fragment>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              {inspectionRatesGraph()}
            </div>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              <KpiTable
                pageFilters={props.pageFilters}
                tableData={tableData}
                type={'inspections'}
                filters={filters}
                filterOptions={filterOptions}
                handleFilters={handleFilters}
                pageStates={props.pageStates}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return (
    <div className='row d-flex mx-0 px-0 justify-content-center align-items-center'>
      {/* Tooltip */}
      <div className='col-6 flex-col ml-n1'>
        <KpiTooltip selectedSubset='kpiInspectionRates' />
      </div>
      <div className='col-6 px-0'>
        <ScorecardSelectHolder pageFilters={filters} autofillPageName={'Machine Inspection Rates'} />
      </div>

      {!inspectionPieData ? (
        <React.Fragment>
          <div className='col-lg-6 flex-col text-center px-0 align-items-center mt-5 mb-auto pb-3'>
            No data available
          </div>
          <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
            <KpiTable
              pageFilters={props.pageFilters}
              tableData={tableData}
              type={'inspections'}
              filters={filters}
              filterOptions={filterOptions}
              handleFilters={handleFilters}
              pageStates={props.pageStates}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
            {inspectionRatesGraph()}
          </div>
          <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
            <KpiTable
              pageFilters={props.pageFilters}
              tableData={tableData}
              type={'inspections'}
              filters={filters}
              filterOptions={filterOptions}
              handleFilters={handleFilters}
              pageStates={props.pageStates}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export {KpiInspectionRates};

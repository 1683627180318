import {createSlice} from '@reduxjs/toolkit';

const diagnosticsModalSlice = createSlice({
  name: 'diagnosticsModal',
  initialState: {
    vehicle: '',
    faultCode: '',
    faultDescription: '',
  },
  reducers: {
    setInitialValues(state, action) {
      const faultData = action.payload;
      state.vehicle = faultData.vehicle;
      state.faultCode = faultData.faultCode;
      state.faultDescription = faultData.faultDescription;
    },
  },
});

export const {setInitialValues} = diagnosticsModalSlice.actions;
export default diagnosticsModalSlice.reducer;

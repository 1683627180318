import React from 'react';
import Box from '@mui/material/Box';

function TabMenuTableWrapper(props) {
  return (
    <Box component='div' sx={{flexDirection: 'column', display: 'flex', height: '100%'}}>
      {props.menu && (
        <Box component='div' sx={{flexShrink: 0}}>
          {props.menu}
        </Box>
      )}
      <Box component='div' sx={{flexGrow: 1, overflow: 'auto', marginBottom: props.tableNoMargin ? '0px' : '10px'}}>
        {props.table}
      </Box>
      {props.pagination && (
        <Box component='div' sx={{flexShrink: 0}}>
          {props.pagination}
        </Box>
      )}
    </Box>
  );
}

export {TabMenuTableWrapper};

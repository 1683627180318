import React from 'react';

function TabContent(props) {
  return (
    <div className='tab-content d-flex overflow-auto'>
      {props.content.map((content, index) => {
        if (props.noFade) {
          if (!props.defaultTabSelection) {
            return (
              <div
                className={index === 0 ? 'tab-pane show active' : 'tab-pane'}
                // eslint-disable-next-line max-len
                id={content.key.toLowerCase().replace(/^[^a-z]+|[^\w:.-]+/gi, '')} // regex to remove invalid id characters
                key={index}
                role='tabpanel'
              >
                {content}
              </div>
            );
          } else {
            const isActive = props.activeTab.toLowerCase() == content.key.toLowerCase();
            return (
              <div
                className={isActive ? 'tab-pane show active' : 'tab-pane'}
                // eslint-disable-next-line max-len
                id={content.key.toLowerCase().replace(/^[^a-z]+|[^\w:.-]+/gi, '')} // regex to remove invalid id characters
                key={index}
                role='tabpanel'
              >
                {content}
              </div>
            );
          }
        } else {
          if (!props.defaultTabSelection) {
            return (
              <div
                className={index === 0 ? 'tab-pane fade show active' : 'tab-pane fade'}
                // eslint-disable-next-line max-len
                id={content.key.toLowerCase().replace(/^[^a-z]+|[^\w:.-]+/gi, '')} // regex to remove invalid id characters
                key={index}
                role='tabpanel'
              >
                {content}
              </div>
            );
          } else {
            const isActive = props.activeTab.toLowerCase() == content.key.toLowerCase();
            return (
              <div
                className={isActive ? 'tab-pane fade show active' : 'tab-pane fade'}
                // eslint-disable-next-line max-len
                id={content.key.toLowerCase().replace(/^[^a-z]+|[^\w:.-]+/gi, '')} // regex to remove invalid id characters
                key={index}
                role='tabpanel'
              >
                {content}
              </div>
            );
          }
        }
      })}
    </div>
  );
}

export {TabContent};

// Import for framework tools
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sendGAPageview, formatTime, sortVehicleNamesHelper, machineTypeMapping} from '../../app/utils';
import {DateTime} from 'luxon';

// Import dependent components
import {Framework} from '../../components/Framework';
import {CoverageTable} from './CoverageTable';
import {SprayTable} from './SprayTable';
import {CoverageDataModal} from './CoverageDataModal';
import {Map} from './Map';
import {Menu} from './Menu';
import {updateSpraysComplete, updateMapView} from './cropviewSlice';

function Cropview(props) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => {
    return state.cropview.loading;
  });
  const dates = useSelector((state) => {
    return state.cropview.dates;
  });
  const zoneAnalytics = useSelector((state) => {
    return state.cropview.zoneAnalytics;
  });
  const zonesDict = useSelector((state) => {
    return state.cropview.zonesDict;
  });
  const equipmentAnalytics = useSelector((state) => {
    return state.cropview.equipmentAnalytics;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.cropview.vehicleSNDict;
  });
  const taskConfigIdDict = useSelector((state) => {
    return state.cropview.taskConfigIdDict;
  });
  const displayedTable = useSelector((state) => {
    return state.cropview.displayedTable;
  });
  const displayedColumn = useSelector((state) => {
    return state.cropview.displayedColumn;
  });
  const mapView = useSelector((state) => {
    return state.cropview.mapView;
  });
  const sortMethod = useSelector((state) => {
    return state.cropview.sortMethod;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [tableData, setTableData] = useState({
    blocks: [],
    fields: [],
    regions: [],
    equipment: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    zones: [],
    equipment: [],
    tasks: [],
  });
  const [filters, setFilters] = useState({
    zones: [],
    equipment: [],
    tasks: [],
  });

  const [drag, setDrag] = useState({
    active: false,
    x: '',
    y: '',
  });

  const [dims, setDims] = useState({
    w: window.innerWidth / 2,
    h: window.innerHeight / 2,
  });

  const [showMap, setShowMap] = useState(props.mapModeEnabled);
  const [showGps, setShowGps] = useState(props.gpsModeEnabled);

  useEffect(() => {
    document.title = 'IntelliCulture | CropView';
    sendGAPageview(document.title);
    getSpraysComplete();
    // When init cropview-export, also ensure mobile mode does not show map
    if (!props.mapModeEnabled) {
      dispatch(updateMapView(0));
    }
  }, []);

  useEffect(() => {
    getCoverage();
  }, [zoneAnalytics, equipmentAnalytics, sortMethod, displayedColumn]);

  function startResize(e) {
    setDrag({
      active: true,
      x: e.clientX || e.touches[0].pageX,
      y: e.clientY || e.touches[0].pageY,
    });
  }

  function resizeFrame(e) {
    const {active, x, y} = drag;
    if (active) {
      const pointerX = e.clientX || e.touches[0].pageX;
      const pointerY = e.clientY || e.touches[0].pageY;
      if (!smallScreen) {
        const xDiff = Math.abs(x - pointerX);
        let newW = x > pointerX ? dims.w - xDiff : dims.w + xDiff;

        const minWidth = 200;
        const maxWidth = window.innerWidth - 300;
        newW = newW < minWidth ? minWidth : newW;
        newW = newW > maxWidth ? maxWidth : newW;

        setDrag({...drag, x: pointerX, y: pointerY});
        setDims({w: newW, h: dims.h});
      } else {
        const yDiff = Math.abs(y - pointerY) * 3;
        const newH = y > pointerY ? dims.h - yDiff : dims.h + yDiff;
        setDrag({...drag, x: pointerX, y: pointerY});
        setDims({w: dims.w, h: newH});
      }
    }
  }

  function stopResize() {
    setDrag({...drag, active: false});
  }

  function setFilterDefault() {
    setFilters({
      zones: [],
      equipment: [],
      tasks: [],
    });
  }

  function toggleShowMap() {
    setShowMap((value) => {
      // Force showGps state to sync with showMap
      setShowGps(!value);
      return !value;
    });
  }

  return (
    <React.Fragment>
      <Framework activePage='cropview' pageName='CropView'>
        <div
          className='container-fluid'
          id='tab-wrapper'
          onMouseMove={resizeFrame}
          onTouchMove={resizeFrame}
          onMouseUp={stopResize}
          onMouseLeave={stopResize}
          onTouchEnd={stopResize}
        >
          <Menu
            setFilterDefault={setFilterDefault}
            gpsModeEnabled={showGps}
            mapModeEnabled={showMap}
            toggleShowMap={toggleShowMap}
          />
          {smallScreen ? (
            <React.Fragment>
              {mapView <= 1 && renderTable()}
              {mapView >= 1 && <Map filters={filters} />}
            </React.Fragment>
          ) : (
            <div className='d-flex flex-row cropview-main'>
              {renderTable()}
              <div className='cropview-handle-horizontal ml-1 mr-3 mb-5' onPointerDown={startResize}></div>
              <div className='flex-grow-1 pt-2 pb-0'>{showMap && <Map filters={filters} />}</div>
            </div>
          )}
        </div>
      </Framework>
      <CoverageDataModal />
    </React.Fragment>
  );

  function renderTable() {
    switch (displayedTable) {
      case 'block':
        return (
          <CoverageTable
            type='block'
            tableData={tableData.blocks}
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'field':
        return (
          <CoverageTable
            type='field'
            tableData={tableData.fields}
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'region':
        return (
          <CoverageTable
            type='region'
            tableData={tableData.regions}
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'equipment':
        return (
          <CoverageTable
            type='equipment'
            tableData={tableData.equipment}
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'spray':
        return <SprayTable filterOptions={filterOptions} filters={filters} setFilters={setFilters} dims={dims} />;
    }
  }

  function getCoverage() {
    // If the current day is not selected and zonesAnalStruct and equipmentData is not empty
    // Using a list based approach to better account for accounts with
    // zone mappings not following a 1-to-1 relationship
    const allRegions = [];
    const allFields = [];
    const allBlocks = [];
    const filterOptionsZone = [];
    const filterOptionsEquipment = [];
    const filterOptionsTask = [];

    // Collect into top level arrays for each zone type
    // Using a array based approach to better account for accounts with
    // zone mappings not following a 1-to-1 relationship
    // NOTE: Each object in the array uses
    // dictionaries for vehicleList and taskList etc.
    Object.keys(zoneAnalytics).forEach((regionId) => {
      const region = {
        ...zoneAnalytics[regionId],
        name: zonesDict.regions[regionId].region_name,
        id: regionId,
      };
      allRegions.push(region);

      Object.keys(region.fieldList).forEach((fieldId) => {
        const fieldName = zonesDict.fields[fieldId].field_name;
        const field = {
          ...region.fieldList[fieldId],
          name: fieldName,
          id: fieldId,
        };
        allFields.push(field);

        Object.keys(field.blockList).forEach((blockId) => {
          const blockName = zonesDict.blocks[blockId].block_name;
          const block = {
            ...field.blockList[blockId],
            name: blockName,
            id: blockId,
          };
          allBlocks.push(block);

          // Options for filters
          if (Math.round((block.acreTotal / block.area) * 100) > 0) {
            filterOptionsZone.push({
              value: blockId,
              text: blockName,
              group: fieldName,
            });
          }
        });
      });
    });

    // Equipment filter
    Object.keys(equipmentAnalytics).forEach((vehicleSN) => {
      if (vehicleSNDict[vehicleSN]){
        // Generate Filter Option only when user have access to vehicle
        const vehicleName = vehicleSNDict[vehicleSN].name;

        filterOptionsEquipment.push({
          value: vehicleSN,
          text: vehicleName,
          group: machineTypeMapping[equipmentAnalytics[vehicleSN].machineType],
        });
      }
    });
    filterOptionsEquipment.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });

    const blockTableData = generateZoneTableData(allBlocks, 'block');
    const fieldTableData = generateZoneTableData(allFields, 'field');
    const regionTableData = generateZoneTableData(allRegions, 'region');
    const equipmentTableData = generateEquipmentTableData(equipmentAnalytics);

    const regionTableDataSorted = sortZoneData(regionTableData);
    const fieldTableDataSorted = sortZoneData(fieldTableData);
    const blockTableDataSorted = sortZoneData(blockTableData);
    const equipmentTableDataSorted = sortEquipmentData(equipmentTableData);

    // Task filter
    Object.values(zoneAnalytics).forEach((region) => {
      Object.values(region.vehicleList).forEach((vehicle) => {
        Object.keys(vehicle.taskList).forEach((taskId) => {
          if (taskId != '') {
            const filterOptionsTaskExists = filterOptionsTask.filter((filterOption) => {
              return filterOption.value === taskId;
            });

            // Check task option doesnt exist
            const taskName = taskConfigIdDict[taskId].name;
            if (filterOptionsTaskExists.length === 0) {
              filterOptionsTask.push({
                value: taskId,
                text: taskName,
              });
            }
          }
        });
      });
    });
    filterOptionsTask.sort((a, b) => {
      return a.text == 'No Task' ? -1 : b.text == 'No Task' ? 1 : a.text.localeCompare(b.text);
    });
    // Add empty option
    filterOptionsTask.unshift({value: '', text: '*'});

    setFilterOptions((values) => {
      return {
        ...values,
        zones: [...new Set(filterOptionsZone)],
        equipment: [...new Set(filterOptionsEquipment)],
        tasks: [...new Set(filterOptionsTask)],
      };
    });

    setTableData({
      blocks: blockTableDataSorted,
      fields: fieldTableDataSorted,
      regions: regionTableDataSorted,
      equipment: equipmentTableDataSorted,
    });
  }

  function generateZoneTableData(zones, type) {
    const zoneData = [];

    // Iterate through zones - only "zones" is a array
    // NOTE: Each object in the array uses
    // dictionaries for vehicleList and taskList etc.
    for (let i = 0; i < zones.length; i++) {
      // Get the zone data from the object
      const zone = zones[i];
      const zoneId = zone.id;
      const zoneName = zone.name;

      // Check if all vehicles are passenger vehicles
      let allPassenger = true;
      Object.keys(zone.vehicleList).forEach((vehicleSN) => {
        const vehicle = zone.vehicleList[vehicleSN];
        if (vehicle.machineType != 1) {
          allPassenger = false;
          return;
        }
      });

      // Iterate through vehicles grouped under zones
      Object.keys(zone.vehicleList).forEach((vehicleSN) => {
        const vehicle = zone.vehicleList[vehicleSN];

        // Check if vehicle is passenger vehicle
        const vehPassenger = vehicle.machineType == 1;
        const vehicleName = vehicleSNDict[vehicleSN].name;

        Object.keys(vehicle.taskList).forEach((taskId) => {
          const task = vehicle.taskList[taskId];

          let taskName = '';
          if (taskConfigIdDict[taskId]) {
            taskName = taskConfigIdDict[taskId].name;
          }

          Object.keys(task.recList).forEach((recId) => {
            const rec = task.recList[recId];
            const rowItem = rec;

            // Create equipment coverage text
            const equipmentTime = rowItem.timeTotal;
            const equipmentAcreage = rowItem.acreTotal;
            let equipmentAcreageRate = 0;
            if (equipmentTime) {
              equipmentAcreageRate = (equipmentAcreage / equipmentTime) * 60;
            }
            const equipmentAcreageAcString = !vehPassenger
              ? `${Math.round(equipmentAcreage * 100) / 100} ac | `
              : ' - ';
            const equipmentAcreageRateString = !vehPassenger
              ? `${Math.round(equipmentAcreageRate * 10) / 10} ac/hr`
              : '';
            let equipmentCoverageString =
              `${formatTime(equipmentTime)} | ` + equipmentAcreageAcString + equipmentAcreageRateString;

            if (
              customerSettings.general.zoneEntryAndExitTimeEnabled &&
              typeof rowItem.entryTime !== 'undefined' &&
              rowItem.entryTime !== null &&
              rowItem.entryTime !== '' &&
              typeof rowItem.exitTime !== 'undefined' &&
              rowItem.exitTime !== null &&
              rowItem.exitTime !== ''
            ) {
              const entryTime = DateTime.fromISO(rowItem.entryTime).setZone(customerSettings.general.timeZone);
              const exitTime = DateTime.fromISO(rowItem.exitTime).setZone(customerSettings.general.timeZone);
              const selectedDateRangeSameDay = DateTime.fromISO(dates.start)
                .setZone(customerSettings.general.timeZone)
                .hasSame(DateTime.fromISO(dates.end).setZone(customerSettings.general.timeZone), 'day');
              const entryExitSameDay = entryTime.hasSame(exitTime, 'day');
              if (selectedDateRangeSameDay && entryExitSameDay) {
                equipmentCoverageString += ` | ${entryTime.toFormat('h:mm a')} - ${exitTime.toFormat('h:mm a')}`;
              } else {
                equipmentCoverageString += ` | ${entryTime.toFormat('LL/dd/yy h:mm a')} - ${exitTime.toFormat(
                  'LL/dd/yy h:mm a'
                )}`;
              }
            }

            const vehicleSpeedKph = rowItem.distanceTotal / (rowItem.timeTotal / 60);
            let vehicleSpeedFormatted = `${vehicleSpeedKph.toFixed(1)} kph`;
            if (userSettings.general.units == 'imperial') {
              vehicleSpeedFormatted = `${(vehicleSpeedKph * 0.621).toFixed(1)} mph`;
            }

            // Implement used for checking if task selection implement matches implement of row
            // Cannot use if more than one implement per task
            let implementSN = '';
            if (task.implementList == 1) {
              implementSN = task.implementList[0];
            }

            const zoneDataObj = {
              type: type,
              zoneId: zoneId,
              zoneName: zoneName,
              zoneTimeTotal: zone.timeTotal,
              zoneTimeTotalFormatted: formatTime(zone.timeTotal),
              zoneAcreTotal: zone.acreTotal,
              zoneAcreTotalRaw: zone.acreTotalRaw,
              zoneArea: zone.area,
              coverage: !allPassenger ? `${Math.round(zone.acreTotal * 100) / 100} ac ` : ' - ',
              vehicleName: vehicleName,
              vehicleSN: vehicleSN,
              vehicleAcreTotal: equipmentAcreage,
              vehicleTimeTotal: equipmentTime,
              vehicleCoverage: equipmentCoverageString,
              vehicleSpeed: vehicleSpeedFormatted,
              taskName: taskName,
              taskId: taskId,
              recId: recId,
              implementSN: implementSN,
              fullEquipmentDict: zone.vehicleList,
              zoneMaxAreaZone: zone.area,
              entryTime: rowItem.entryTime,
              exitTime: rowItem.exitTime,
            };

            zoneData.push(zoneDataObj);
          });
        });
      });
    }

    return zoneData;
  }

  function generateEquipmentTableData(vehicles) {
    const vehicleData = [];
    // Iterate through vehciles
    for (const [vehicleSN, vehicle] of Object.entries(vehicles)) {
      if (!vehicleSNDict[vehicleSN]){
        // Skip Equipment Table data generation if user does not have access to vehicle 
        console.log(`Equipment Analytics - VehicleSN: ${vehicleSN} Not Found`)
        continue
      }
      const vehicleName = vehicleSNDict[vehicleSN].name;

      // Check if vehicle is passenger vehicle
      const vehPassenger = vehicle.machineType == 1;

      let timeTotal = vehicle.timeInField + vehicle.timeOutField;
      if (customerSettings.general.idleTime) {
        timeTotal += vehicle.timeIdle;
      }

      let acreageRate = 0;
      if (vehicle.timeInField) {
        acreageRate = (vehicle.acreTotal / vehicle.timeInField) * 60;
      }

      let taskName = '';
      if (taskConfigIdDict[vehicle.taskId]) {
        taskName = taskConfigIdDict[vehicle.taskId].name;
      }

      const equipmentDataObj = {
        type: 'equipment',
        name: vehicleName,
        vehicleSN: vehicleSN,
        machineType: vehicle.machineType,
        timeInField: vehicle.timeInField,
        timeInFieldFormatted: formatTime(vehicle.timeInField),
        timeOutField: vehicle.timeOutField,
        timeOutFieldFormatted: formatTime(vehicle.timeOutField),
        timeIdle: vehicle.timeIdle,
        timeIdleFormatted: formatTime(vehicle.timeIdle),
        timeTotal: timeTotal,
        timeTotalFormatted: formatTime(timeTotal),
        acreTotal: vehicle.acreTotal,
        acreageRate: vehicle.acreageRate,
        coverage: !vehPassenger ? `${Math.round(vehicle.acreTotal * 10) / 10} ac` : ' - ',
        acreageRateFormatted: !vehPassenger ? `${Math.round(acreageRate * 10) / 10} ac/hr` : ' - ',
        taskName: taskName,
        taskId: vehicle.taskId,
        taskPerm: vehicle.taskPerm,
        gpsFound: vehicle.latestGpsPoint.length > 0,
        recId: '',
      };
      vehicleData.push(equipmentDataObj);
    }

    return vehicleData;
  }

  function sortZoneData(dataList) {
    // Make copy of list or else will get error when trying to sort
    const zoneList = JSON.parse(JSON.stringify(dataList));
    // Sort zones and vehicle list within zone by method specified
    // Will sort a after b if compare function returns > 0 and sort a before b if compare function returns < 0
    if (sortMethod == 'name') {
      zoneList.sort((a, b) => {
        return a.zoneName.localeCompare(b.zoneName) || sortVehicleNamesHelper(a.vehicleName, b.vehicleName);
      });
    } else if (sortMethod == 'acreage') {
      zoneList.sort((a, b) => {
        return b.zoneAcreTotal - a.zoneAcreTotal || b.vehicleAcreTotal - a.vehicleAcreTotal;
      });
    } else if (sortMethod == 'percent') {
      zoneList.sort((a, b) => {
        return (
          (b.zoneAcreTotal / b.zoneArea) * 100 - (a.zoneAcreTotal / a.zoneArea) * 100 ||
          b.vehicleAcreTotal - a.vehicleAcreTotal
        ); // Sort vehicles by time as they do not have percentage
      });
    } else {
      zoneList.sort((a, b) => {
        return b.zoneTimeTotal - a.zoneTimeTotal || b.vehicleTimeTotal - a.vehicleTimeTotal;
      });
    }

    return zoneList;
  }

  function sortEquipmentData(dataList) {
    // Make copy of list or else will get error when trying to sort
    const equipmentList = JSON.parse(JSON.stringify(dataList));
    // Sort equipment by method specified
    // Will sort a after b if compare function returns > 0 and sort a before b if compare function returns < 0
    if (sortMethod == 'name') {
      equipmentList.sort((a, b) => {
        return sortVehicleNamesHelper(a.name, b.name);
      });
    } else if (sortMethod == 'acreage') {
      equipmentList.sort((a, b) => {
        return b.acreTotal > a.acreTotal ? 1 : -1;
      });
    } else if (sortMethod == 'timeTotal') {
      equipmentList.sort((a, b) => {
        let aTotal = a.timeInField + a.timeOutField;
        let bTotal = b.timeInField + b.timeOutField;
        if (customerSettings.general.idleTime) {
          aTotal += a.timeIdle;
          bTotal += b.timeIdle;
        }
        return aTotal > bTotal ? -1 : 1;
      });
    } else if (sortMethod == 'type') {
      // Type order [Tractor, Platform, ATV, Construction, Road Vehicle, Trailer, Not Assigned]
      const typeSortOrder = [2, 5, 4, 6, 1, 3, 0];
      equipmentList.sort((a, b) => {
        const aTypeIdx = typeSortOrder.indexOf(a.machineType);
        const bTypeIdx = typeSortOrder.indexOf(b.machineType);
        return aTypeIdx - bTypeIdx || sortVehicleNamesHelper(a.name, b.name);
      });
    } else {
      equipmentList.sort((a, b) => {
        return b[sortMethod] - a[sortMethod];
      });
    }

    return equipmentList;
  }

  async function getSpraysComplete() {
    // Get data
    let sprayCompleteDict = {};
    try {
      const sprayCompleteResponse = await fetch('/cropview/getSprayComplete', {cache: 'no-store'});
      sprayCompleteDict = await sprayCompleteResponse.json();
    } catch (err) {
      console.error(err);
    }

    // Update store
    dispatch(updateSpraysComplete(sprayCompleteDict));
  }
}

export {Cropview};

import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Navigate} from 'react-router-dom';

function Home() {
  const authenticationStatus = useSelector((state) => {
    return state.app.authenticationStatus;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  if (customerSettings.landing.landingPageEnabled) {
    return <Navigate replace to='/landing' />;
  } else if (authenticationStatus.cabviewEnabled) {
    return <Navigate replace to='/cabview' />;
  } else if (authenticationStatus.shopviewEnabled) {
    return <Navigate replace to='/shopview' />;
  } else if (authenticationStatus.cropviewEnabled) {
    return <Navigate replace to='/cropview' />;
  } else if (authenticationStatus.inspectionEnabled) {
    return <Navigate replace to='/inspectionForm' />;
  } else if (authenticationStatus.zoneInfoEnabled) {
    return <Navigate replace to='/mapview' />;
  } else {
    return <Navigate replace to='/error' />;
  }
}

export {Home};

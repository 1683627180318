import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, isNumeric} from '../../../app/utils';
import {Tailselect} from '../../../components/Tailselect';
import {genTotalMachineUsage} from '../dashboardDataProcessing';
import {KpiTooltip, ScorecardSelectHolder} from '../dashboardUtils';
import {Checkbox, FormControlLabel, CircularProgress, Divider} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

function KpiMachineUsage(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const daysDiff = useSelector((state) => {
    return state.dashboard.daysDiff;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const activeDevices = useSelector((state) => {
    return state.dashboard.activeDevices;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });
  const [totalMachineUsage, setTotalMachineUsage] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [displayedAxis, setDisplayedAxis] = useState('Total');
  const [hourDollarRate, setHourDollarRate] = useState('');
  const [fuelRate, setFuelRate] = useState(0);
  const [fuelCost, setFuelCost] = useState(0);
  const [fuelRateInput, setFuelRateInput] = useState('');
  const [fuelCostInput, setFuelCostInput] = useState('');

  const [usageFilters, setUsageFilters] = useState({
    usageVehicleSNs: [],
    usageVehicleType: [],
    usageVehicleCount: '25',
    usageVehicleSort: 'Desc',
  });

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [operationsData, vehicleSNDict, usageFilters, displayedAxis, activeDevices, hourDollarRate, fuelRate, fuelCost]);

  useEffect(() => {
    if (props.pageStates?.displayedAxis) {
      setDisplayedAxis(props.pageStates?.displayedAxis);
    } else {
      setDisplayedAxis('Total');
    }
    if (props.pageStates?.fuelRate) {
      setFuelRate(props.pageStates?.fuelRate);
    }
    if (props.pageStates?.fuelCost) {
      setFuelCost(props.pageStates?.fuelCost);
      setHourDollarRate('');
    }
    if (props.pageStates?.hourDollarRate) {
      setHourDollarRate(props.pageStates?.hourDollarRate);
      setFuelCost(0);
    }
    if (props?.pageFilters) {
      setUsageFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
  }, [props.pageStates, props.pageFilters]);

  function handleHourDollarRateChange(e) {
    if (isNumeric(e.target.value) || e.target.value == '') {
      setFuelCostInput('');
      setFuelCost(0);
      setHourDollarRate(e.target.value);
    }
  }

  function handleFuelRateChange(e) {
    const value = e.target.value;
    if (isNumeric(value) || value == '') {
      setFuelRateInput(value);
      // Always save value in metric
      setFuelRate(unitsSystem == 'imperial' ? value * 3.785 : value);
    }
  }

  function handleFuelCostChange(e) {
    const value = e.target.value;
    if (isNumeric(value) || value == '') {
      setHourDollarRate('');
      // Always save value in metric
      setFuelCostInput(value);
      setFuelCost(unitsSystem == 'imperial' ? value / 3.785 : value);
    }
  }

  function generateData() {
    // Generate data
    const totalMachineUsageTemp = genTotalMachineUsage(
      operationsData.vehiclesObject,
      usageFilters,
      daysDiff,
      operationsData.vehicleHoursByTypeObject,
      vehicleSNDict,
      activeDevices,
      unitsSystem,
      hourDollarRate,
      fuelRate,
      fuelCost
    );
    if (Object.keys(operationsData.vehiclesObject).length > 0) {
      setTotalMachineUsage(totalMachineUsageTemp);
    } else {
      setTotalMachineUsage(null);
    }

    const tempDatasets = [];
    totalMachineUsageTemp.datasets.forEach((setObj) => {
      if (setObj.id == displayedAxis) {
        tempDatasets.push(setObj);
      }
    });
    setDatasets(tempDatasets);
  }

  function handleVehiclesFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }

    const value = select.multiple ? selected : selected[0];
    const name = select.name;
    setUsageFilters((values) => {
      return {...values, [name]: value};
    });
  }

  function machineUsageGraph(rateCostText = null) {
    let graphTitle;
    let axisTitle;

    switch (displayedAxis) {
      case 'Total':
        graphTitle = 'Total Machine Usage';
        axisTitle = 'Usage Hours';
        break;
      case 'Distance':
        graphTitle = 'Total Machine Travel Distance';
        axisTitle = unitsSystem == 'imperial' ? 'Distance (mi)' : 'Distance (km)';
        break;
      case 'Avg':
        // Deprecated, but keeping for old scorecards that may need it
        graphTitle = 'Average Machine Hours / Day';
        axisTitle = 'Hours';
        break;
      case 'Fuel':
        graphTitle = 'Total Fuel Consumption';
        axisTitle = unitsSystem == 'imperial' ? 'Fuel Consumption (Gal)' : 'Fuel Consumption (L)';
        break;
      case 'GHG':
        graphTitle = 'Total GHG emissions';
        axisTitle = 'Emissions (Tonnes)';
        break;
      case 'Cost':
        if (hourDollarRate != '') {
          graphTitle = 'Total Machine Operating Cost';
          axisTitle = 'Machine Operating Cost ($)';
        } else {
          graphTitle = 'Total Fuel Cost';
          axisTitle = 'Fuel Cost ($)';
        }
        break;
      default:
        graphTitle = 'Total Machine Hours';
        axisTitle = 'Usage Hours';
    }

    return (
      <div style={{height: '60vh', minHeight: '500px'}}>
        {/* Chart */}
        <BarChart
          data={{...totalMachineUsage, datasets: datasets}}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                text: graphTitle,
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              subtitle: {
                display: rateCostText != null,
                text: rateCostText,
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              leftAxis: {
                title: {
                  text: axisTitle,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                display: displayedAxis != 'Avg',
                position: 'left',
              },
              rightAxis: {
                title: {
                  text: axisTitle,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                display: displayedAxis == 'Avg',
                position: 'right',
              },
              x: {
                ticks: {
                  autoSkip: totalMachineUsage.labels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 90,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: 'Vehicle',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    let rateCostText = '';
    switch (displayedAxis) {
      case 'Fuel':
      case 'GHG':
        const fuelRateString =
          unitsSystem == 'imperial'
            ? `${parseFloat((fuelRate / 3.785).toFixed(2))} Gal/Hr`
            : `${fuelRate.toFixed(2)} L/Hr`;
        rateCostText += `Fuel Rate: ${fuelRateString}`;
        break;
      case 'Cost':
        if (hourDollarRate != '') {
          rateCostText += `Machine Operating Rate: $${parseFloat(hourDollarRate).toFixed(2)}/hr`;
        } else {
          const fuelRateString =
            unitsSystem == 'imperial'
              ? `${parseFloat((fuelRate / 3.785).toFixed(2))} Gal/Hr`
              : `${fuelRate.toFixed(2)} L/Hr`;
          rateCostText += `Fuel Rate: ${fuelRateString}`;
          const fuelCostString =
            unitsSystem == 'imperial'
              ? `${parseFloat((fuelCost * 3.785).toFixed(2))} $/Gal`
              : `${fuelCost.toFixed(2)} $/L`;
          rateCostText += ` | Fuel Cost: ${fuelCostString}`;
        }
        break;
      default:
        rateCostText = null;
    }

    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiMachineUsage' />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!totalMachineUsage ? (
            <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
          ) : (
            machineUsageGraph(rateCostText)
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className='row d-flex mx-0 px-0 justify-content-center align-items-center'>
      {/* Tooltip */}
      <div className='col-6 flex-col ml-n1'>
        <KpiTooltip selectedSubset='kpiMachineUsage' />
      </div>
      <div className='col-6 px-0'>
        <ScorecardSelectHolder
          autofillPageName={'Machine Usage'}
          pageFilters={usageFilters}
          pageStates={{
            displayedAxis: displayedAxis,
            fuelRate: fuelRate,
            fuelCost: fuelCost,
            hourDollarRate: hourDollarRate,
          }}
        />
      </div>

      {!totalMachineUsage ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>{machineUsageGraph()}</div>
          <div className='col-lg-4 flex-col text-center px-0 align-items-center my-auto'>
            {(displayedAxis == 'Fuel' || displayedAxis == 'Cost' || displayedAxis == 'GHG') && (
              <div className='text-center align-items-center my-auto'>
                <Grid alignItems={'center'} justifyContent={'center'} container fullWidth spacing={2}>
                  <Grid item xs={12}>
                    <KpiTooltip selectedSubset='kpiMachineUsageInput' />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Fuel Rate ({unitsSystem == 'imperial' ? 'Gal' : 'L'}/Hr)</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                      size='small'
                      onChange={handleFuelRateChange}
                      value={fuelRateInput}
                    />
                  </Grid>
                  {displayedAxis == 'Cost' && (
                    <React.Fragment>
                      <Grid item xs={6}>
                        <Typography>Fuel Cost ($/{unitsSystem == 'imperial' ? 'Gal' : 'L'})</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField size='small' onChange={handleFuelCostChange} value={fuelCostInput} />
                      </Grid>
                      <Grid item xs={10}>
                        <Divider>or</Divider>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>Hour Rate ($/Hr)</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField size='small' onChange={handleHourDollarRateChange} value={hourDollarRate} />
                      </Grid>
                    </React.Fragment>
                  )}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </div>
            )}
            <div className='text-center align-items-center my-auto'>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('Total');
                    }}
                    checked={displayedAxis == 'Total'}
                  />
                }
                label='Total Hours'
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('Avg');
                    }}
                    checked={displayedAxis == 'Avg'}
                  />
                }
                label='Avg Hrs / Day'
              /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('Distance');
                    }}
                    checked={displayedAxis == 'Distance'}
                  />
                }
                label='Total Distance'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('Fuel');
                    }}
                    checked={displayedAxis == 'Fuel'}
                  />
                }
                label='Fuel'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('Cost');
                    }}
                    checked={displayedAxis == 'Cost'}
                  />
                }
                label='Cost'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setDisplayedAxis('GHG');
                    }}
                    checked={displayedAxis == 'GHG'}
                  />
                }
                label='GHG'
              />
            </div>
            <div className='text-centeralign-items-center my-auto pb-3'>
              <Tailselect
                id='dashboard-vehicles-usageVehicleSNs-filter'
                name='usageVehicleSNs'
                multiple={true}
                search={true}
                value={usageFilters.usageVehicleSNs}
                options={filterOptions.vehicles.vehicleSNs}
                onChange={handleVehiclesFilters}
                placeholder='Filter for Vehicles'
              />
              <Tailselect
                id='dashboard-vehicles-usageVehicleType-filter'
                name='usageVehicleType'
                multiple={true}
                search={true}
                value={usageFilters.usageVehicleType}
                options={filterOptions.vehicles.vehicleType}
                onChange={handleVehiclesFilters}
                placeholder='Filter for Vehicle Type'
              />
              <Tailselect
                id='dashboard-vehicles-usageVehicleCount-filter'
                name='usageVehicleCount'
                multiple={false}
                value={usageFilters.usageVehicleCount}
                options={filterOptions.vehicles.vehicleCount}
                onChange={handleVehiclesFilters}
                placeholder='Number Of Vehicles To Show'
              />
              <Tailselect
                id='dashboard-vehicles-usageVehicleSort-filter'
                name='usageVehicleSort'
                multiple={false}
                value={usageFilters.usageVehicleSort}
                options={filterOptions.vehicles.usageVehicleSort}
                onChange={handleVehiclesFilters}
                placeholder='Sorting method'
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export {KpiMachineUsage};

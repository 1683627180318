import {createSlice} from '@reduxjs/toolkit';

const frameworkSlice = createSlice({
  name: 'framework',
  initialState: {
    sidebarOpen: false,
    smallScreen: false,
  },
  reducers: {
    updateSidebarOpen(state, action) {
      state.sidebarOpen = action.payload;
    },
    updateSmallScreen(state, action) {
      state.smallScreen = action.payload;
    },
  },
});

export const {updateSidebarOpen, updateSmallScreen} = frameworkSlice.actions;
export default frameworkSlice.reducer;

import React from 'react';

function CoverPageCard(props) {
  return (
    <div className={props.secondary ? 'bg-gradient-secondary min-vh-100' : 'bg-login-image min-vh-100'}>
      <div className='container px-1'>
        {/* Outer Row */}
        <div className='row justify-content-center mx-0'>
          <div className={props.secondary ? 'col-xl-10 col-lg-12 col-md-9' : 'col-xl-6 col-lg-8 col-md-9'}>
            <div className='card o-hidden border-0 shadow-lg my-5'>
              <div className='card-body p-0'>
                {/* Nested Row within Card Body */}
                <div className='row'>
                  {props.secondary && (
                    <div className='col-lg-6 d-none d-lg-block '>
                      <a
                        href='https://www.intelliculture.ca/'
                        target='_blank'
                        style={{display: 'inline-block', padding: '36px 36px'}}
                        rel='noreferrer'
                      >
                        <img
                          src='../img/wine_grapes.jpg'
                          alt='Logo'
                          border='5'
                          width='450'
                          style={{
                            display: 'block',
                            width: '450px',
                            maxWidth: '450px',
                            minWidth: '450px',
                            borderRadius: '5%',
                          }}
                        />
                      </a>
                    </div>
                  )}
                  <div className={props.secondary ? 'col-lg-6' : 'col-lg-12'}>
                    <div className={props.secondary ? 'p-5' : 'card-content'}>{props.children}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export {CoverPageCard};

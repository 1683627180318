import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createPortal} from 'react-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import {DateTime} from 'luxon';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {polygonArea} from '../../app/utils';
import {updateZoneZoom, updateVehicleZoom} from './cropviewSlice';
import {drawVehicleMarker} from './Markers';

// Label for adding onto map
const LabelComponent = (props) => {
  return (
    <label
      style={{
        color: 'black',
        fontWeight: 'bold',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        textAlign: 'center',
        minWidth: '25px',
      }}
    >
      {props.text}
      <br />
      {typeof props.remainingRei != 'undefined' && props.remainingRei > 0 && (
        <span style={{color: '#a30000'}}>Remaining REI: {props.remainingRei}h</span>
      )}
    </label>
  );
};

// Control button div defined here so that it can be populated using jsx and then appended to the map once initialized
const controlButtonDiv = document.createElement('div');
const alertMessage = document.createElement('div');
// Global variables
let autoZoomInProgress = false; // Prevents event handlers during non-user triggered map zooming
let map = null; // Reference to google 'map' object
let maps = null; // Reference to google 'maps' object

// Colors to be used for plotting paths
const colorsList = ['#000080', '#b03060', '#ff0000', '#ffd700', '#00ff00', '#00bfff', '#ff00ff', '#ffe4c4', '#000000'];
const zoneColorsList = ['#4daf4a', '#4a4daf', '#af4a99', '#000000', '#ffffff'];
let zoneColorIndex = 0;
let zoneInfoWindow = null; // Single zone info window so that only one is open at a time
let vehInfoWindow = null; // Single vehicle info window so that only one is open at a time

function Map(props) {
  const dispatch = useDispatch();

  const zonesData = useSelector((state) => {
    return state.cropview.zonesData;
  });
  const pathsLoaded = useSelector((state) => {
    return state.cropview.pathsLoaded;
  });
  const geoFences = useSelector((state) => {
    return state.cropview.geoFences;
  });
  const geoFenceIntelliBlockDict = useSelector((state) => {
    return state.cropview.geoFenceIntelliBlockDict;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.cropview.vehicleSNDict;
  });
  const vehiclePathSNDict = useSelector((state) => {
    return state.cropview.vehiclePathSNDict;
  });
  const vehicleRowLinesDict = useSelector((state) => {
    return state.cropview.vehicleRowLinesDict;
  });
  const taskConfigIdDict = useSelector((state) => {
    return state.cropview.taskConfigIdDict;
  });
  const blockNameToBlockId = useSelector((state) => {
    return state.cropview.blockNameToBlockId;
  });
  const reiActiveDict = useSelector((state) => {
    return state.cropview.reiActiveDict;
  });
  const todayOnly = useSelector((state) => {
    return state.cropview.todayOnly;
  });
  const zoneZoom = useSelector((state) => {
    return state.cropview.zoneZoom;
  });
  const vehicleZoom = useSelector((state) => {
    return state.cropview.vehicleZoom;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const displayedTable = useSelector((state) => {
    return state.cropview.displayedTable;
  });
  const equipmentAnalytics = useSelector((state) => {
    return state.cropview.equipmentAnalytics;
  });
  const pathQueryStatus = useSelector((state) => {
    return state.cropview.pathQueryStatus;
  });
  // Note vehicleMarkers is a dict that is indexed/keyed by the vehicle seial number
  const [vehicleMarkers, setVehicleMarkers] = useState({});
  const [mapState, setMapState] = useState({
    locate: false,
    showAll: false,
  });

  const [displayOutOfZoneTravel, setDisplayOutOfZoneTravel] = useState(false);
  const [allVehiclePathsTripTask, setAllVehiclePathsTripTask] = useState([]);
  const [inZoneVehiclePathsMixed, setInZoneVehiclePathsMixed] = useState([]);
  const [outOfFieldPathsIdxbyVeh, setOutOfFieldPathsIdxbyVeh] = useState({});
  const [markersOnMap, setMarkersOnMap] = useState([]);

  const [pathExtremes, setPathExtremes] = useState({
    latMax: null,
    latMin: null,
    lngMax: null,
    lngMin: null,
  });
  const [mapZoomLevel, setMapZoomLevel] = useState(0);
  const [displayZones, setDisplayZones] = useState(true);
  const [zoneLabelsIndex, setZoneLabelsIndex] = useState(0);
  const [geoFencePolygons, setGeoFencePolygons] = useState([]);
  const [zoneLabels, setZoneLabels] = useState([]);
  const [vehicleZoomed, setVehicleZoomed] = useState('');
  const zoneInfoEnabled =
    typeof userSettings.general.zoneInfoEnabled != 'undefined' && userSettings.general.zoneInfoEnabled;

  const [toggleRerenderGpsPoints, setToggleRerenderGpsPoints] = useState(true);
  const [showGpsTime, setShowGpsTime] = useState(false);
  const [displayPathStatus, setDisplayPathStatus] = useState(true);

  useEffect(() => {
    if (map != null && maps != null) {
      updateMapArbitrated();
    }
  }, [pathQueryStatus, vehiclePathSNDict]);

  useEffect(() => {
    if (map != null && maps != null) {
      plotGeoFences();
    }
  }, [geoFences, zonesData, reiActiveDict]);

  useEffect(() => {
    if (map != null && maps != null) {
      showGeoFences();
    }
  }, [displayZones]);

  useEffect(() => {
    if (map != null && maps != null) {
      showAll();
    }
  }, [pathExtremes]);

  useEffect(() => {
    if (map != null && maps != null) {
      zoomToZone();
    }
  }, [zoneZoom]);

  useEffect(() => {
    if (map != null && maps != null) {
      locateVehicle();
    }
  }, [vehicleZoom]);

  useEffect(() => {
    setDisplayPathStatus(true);
  }, [pathsLoaded]);

  useEffect(() => {
    if (map != null && maps != null) {
      filterPaths();
      if (displayZones) {
        for (let i = 0; i < geoFencePolygons.length; i++) {
          const maxMinPoints = geoFencePolygons[i].maxMinPoints;
          if (
            !maxMinPoints ||
            mapZoomLevel < 10 ||
            !maxMinPoints.some((point) => {
              return map.getBounds().contains(point);
            })
          ) {
            geoFencePolygons[i].setMap(null);
          } else {
            if (geoFencePolygons[i]?.map == null) {
              geoFencePolygons[i].setMap(map);
            }
          }
        }
      }
    }
  }, [props.filters, displayOutOfZoneTravel, showGpsTime, toggleRerenderGpsPoints]);

  useEffect(() => {
    if (map != null && maps != null) {
      setZoneLabels(generateZoneLabels());
    }
  }, [zoneLabelsIndex, geoFences, zonesData, reiActiveDict]);

  useEffect(() => {
    // Show Vehicle Marker only in equipment Table
    if (map != null && maps != null) {
      if (displayedTable == 'equipment') {
        updateVehicleMarker(true, vehicleZoomed);
      } else {
        updateVehicleMarker(false);
      }
    }
  }, [displayedTable, vehicleZoomed, equipmentAnalytics]);

  // Options for google map appearance and functionality
  const mapOptions = {
    disableDefaultUI: true,
    mapTypeId: 'hybrid',
    rotateControl: false,
    tilt: 0,
  };

  function handleApiLoaded(gmap, gmaps) {
    // Set global 'map' and 'maps' objects to those received from the google api
    map = gmap;
    maps = gmaps;

    // Append custom control buttons to map
    map.controls[maps.ControlPosition.TOP_RIGHT].push(controlButtonDiv);
    map.controls[maps.ControlPosition.LEFT_TOP].push(alertMessage);

    // Create infowindow object
    zoneInfoWindow = new maps.InfoWindow();
    vehInfoWindow = new maps.InfoWindow();
    map.addListener('click', () => {
      zoneInfoWindow.close();
      vehInfoWindow.close();
    });

    map.addListener('zoom_changed', () => {
      setMapZoomLevel(map.getZoom());
    });
    // Event listeners for clearing map states
    map.addListener('dragstart', function () {
      if (!autoZoomInProgress) {
        setMapState({locate: false, showAll: false});
      }
    });
    map.addListener('zoom_changed', function () {
      if (!autoZoomInProgress) {
        setMapState({locate: false, showAll: false});
      }
      setMapZoomLevel(map.getZoom());
    });
    map.addListener('idle', function () {
      // Function to toggle state that Trigger Effects
      handleToggleRerenderGpsPoints();
    });

    // Update map with markers once initialized
    setMapZoomLevel(map.getZoom());
    updateMapArbitrated();
    plotGeoFences();
  }

  function handleToggleRerenderGpsPoints() {
    // Toggle State to trigger Effects that re-renders paths, reduce number of GPS points rendered;
    setToggleRerenderGpsPoints((value) => {
      return !value;
    });
  }

  function updateMapArbitrated(updateExtremes = true) {
    // Update map makes determination based on intelliblock # whether to display triptask vs. rowpass lines
    // Track extremes of plotted data
    const extremes = {
      latMax: null,
      latMin: null,
      lngMax: null,
      lngMin: null,
    };

    const geoFencesDict = {
      '-1': {
        'rowPassEnabled': false,
      },
    };
    geoFences.forEach((geoFence) => {
      geoFencesDict[geoFence.properties.intelliblock_num.toString()] = geoFence.properties;
    });

    // Remove existing paths and points from map

    for (let i = 0; i < inZoneVehiclePathsMixed.length; i++) {
      inZoneVehiclePathsMixed[i].path.setMap(null);
    }
    for (let i = 0; i < allVehiclePathsTripTask.length; i++) {
      allVehiclePathsTripTask[i].path.setMap(null);
    }

    // Plot all the traces for each vehicle, arbitration on which method is used is done up stream
    const vehicleSNKeys = Object.keys(vehicleSNDict);
    const inZonePathList = [];
    const outOfZonePathList = [];
    const vehicleRowPassBlockNumsBySN = {};

    vehicleSNKeys.forEach((vehicleSNKey, index) => {
      const vehicleName = vehicleSNDict[vehicleSNKey].name;

      if (!Object.prototype.hasOwnProperty.call(vehicleRowPassBlockNumsBySN, vehicleSNKey)) {
        vehicleRowPassBlockNumsBySN[vehicleSNKey] = [];
      }

      // If the block has rowlines, block based process can be used
      if (Object.prototype.hasOwnProperty.call(vehicleRowLinesDict, vehicleSNKey)) {
        for (let i = 0; i < vehicleRowLinesDict[vehicleSNKey].length; i++) {
          // Find task name
          let taskName = '';
          if (Object.prototype.hasOwnProperty.call(taskConfigIdDict, vehicleRowLinesDict[vehicleSNKey][i].taskId)) {
            taskName = taskConfigIdDict[vehicleRowLinesDict[vehicleSNKey][i].taskId].name;
          }

          const rowLinePoints = [
            {
              'lng': vehicleRowLinesDict[vehicleSNKey][i].rowLine[0][0],
              'lat': vehicleRowLinesDict[vehicleSNKey][i].rowLine[0][1],
            },
            {
              'lng': vehicleRowLinesDict[vehicleSNKey][i].rowLine[1][0],
              'lat': vehicleRowLinesDict[vehicleSNKey][i].rowLine[1][1],
            },
          ];

          // Plot rowpass
          const color = colorsList[index % 10];
          const path = new maps.Polyline({
            path: rowLinePoints,
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });

          // Create line symbols for directionality
          setPathlineTravelDirection(map, path);

          if (vehicleRowLinesDict[vehicleSNKey][i].intelliblockNum != -1) {
            // Format path data
            const pathObj = {
              blockId: vehicleRowLinesDict[vehicleSNKey][i].blockId,
              vehicleSN: vehicleSNKey,
              taskId: vehicleRowLinesDict[vehicleSNKey][i].taskId,
              path: path,
              intelliblockNum: vehicleRowLinesDict[vehicleSNKey][i].intelliblockNum,
            };
            inZonePathList.push(pathObj);

            if (
              !vehicleRowPassBlockNumsBySN[vehicleSNKey].includes(vehicleRowLinesDict[vehicleSNKey][i].intelliblockNum)
            ) {
              vehicleRowPassBlockNumsBySN[vehicleSNKey].push(vehicleRowLinesDict[vehicleSNKey][i].intelliblockNum);
            }
          }

          // Add info window
          if (path != undefined) {
            const infowindow = new maps.InfoWindow({
              content: vehicleName,
            });

            path.addListener('mouseover', (event) => {
              infowindow.setPosition(event.latLng);
              infowindow.open({
                map,
                shouldFocus: false,
              });
            });

            path.addListener('mouseout', () => {
              infowindow.close();
            });
          }

          // Update extremes
          if (!updateExtremes) {
            continue;
          }
          const pathCoords = rowLinePoints;
          for (let i = 0; i < pathCoords.length; i++) {
            const lat = pathCoords[i].lat;
            const lng = pathCoords[i].lng;

            if (lat > extremes.latMax || extremes.latMax == null) {
              extremes.latMax = lat;
            }
            if (lat < extremes.latMin || extremes.latMin == null) {
              extremes.latMin = lat;
            }
            if (lng > extremes.lngMax || extremes.lngMax == null) {
              extremes.lngMax = lng;
            }
            if (lng < extremes.lngMin || extremes.lngMin == null) {
              extremes.lngMin = lng;
            }
          }
        }
      }

      // Otherwise use the trip task determined vehicle paths
      if (Object.prototype.hasOwnProperty.call(vehiclePathSNDict, vehicleSNKey)) {
        for (let i = 0; i < vehiclePathSNDict[vehicleSNKey].length; i++) {
          // Find task name
          let taskName = '';
          if (Object.prototype.hasOwnProperty.call(taskConfigIdDict, vehiclePathSNDict[vehicleSNKey][i].taskId)) {
            taskName = taskConfigIdDict[vehiclePathSNDict[vehicleSNKey][i].taskId].name;
          }

          // Plot trip
          const color = colorsList[index % 10];
          const path = new maps.Polyline({
            path: vehiclePathSNDict[vehicleSNKey][i].points,
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });

          // Create line symbols for directionality
          setPathlineTravelDirection(map, path);

          const pathObj = {
            blockId: vehiclePathSNDict[vehicleSNKey][i].blockId,
            vehicleSN: vehicleSNKey,
            taskId: vehiclePathSNDict[vehicleSNKey][i].taskId,
            path: path,
            points: vehiclePathSNDict[vehicleSNKey][i].points,
            intelliblockNum: vehiclePathSNDict[vehicleSNKey][i].intelliblockNum,
          };

          if (
            vehiclePathSNDict[vehicleSNKey][i].intelliblockNum != -1 &&
            !vehicleRowPassBlockNumsBySN[vehicleSNKey].includes(vehiclePathSNDict[vehicleSNKey][i].intelliblockNum)
          ) {
            inZonePathList.push(pathObj);
          }

          outOfZonePathList.push(pathObj);

          // Add info window
          if (path != undefined) {
            const infowindow = new maps.InfoWindow({
              content: vehicleName,
            });

            path.addListener('mouseover', (event) => {
              infowindow.setPosition(event.latLng);
              infowindow.open({
                map,
                shouldFocus: false,
              });
            });

            path.addListener('mouseout', () => {
              infowindow.close();
            });
          }

          // Update extremes
          if (!updateExtremes) {
            continue;
          }
          const pathCoords = vehiclePathSNDict[vehicleSNKey][i].points;
          for (let i = 0; i < pathCoords.length; i++) {
            const lat = pathCoords[i].lat;
            const lng = pathCoords[i].lng;

            if (lat > extremes.latMax || extremes.latMax == null) {
              extremes.latMax = lat;
            }
            if (lat < extremes.latMin || extremes.latMin == null) {
              extremes.latMin = lat;
            }
            if (lng > extremes.lngMax || extremes.lngMax == null) {
              extremes.lngMax = lng;
            }
            if (lng < extremes.lngMin || extremes.lngMin == null) {
              extremes.lngMin = lng;
            }
          }
        }
      }
    });

    if (updateExtremes) {
      setPathExtremes(extremes);
    }

    markersOnMap.forEach((marker) => {
      marker.setMap(null);
    });
    setMarkersOnMap([]);
    if (displayOutOfZoneTravel) {
      for (let i = 0; i < inZonePathList.length; i++) {
        inZonePathList[i].path.setMap(null);
      }
      for (let i = 0; i < outOfZonePathList.length; i++) {
        outOfZonePathList[i].path.setMap(map);
        showGpsPoints(
          outOfZonePathList[i]?.points,
          outOfZonePathList[i].path.strokeColor,
          outOfZonePathList[i].equipment
        );
      }
    } else {
      for (let i = 0; i < outOfZonePathList.length; i++) {
        outOfZonePathList[i].path.setMap(null);
      }
      for (let i = 0; i < inZonePathList.length; i++) {
        inZonePathList[i].path.setMap(map);
        showGpsPoints(inZonePathList[i]?.points, inZonePathList[i].path.strokeColor, inZonePathList[i].equipment);
      }
    }

    // All out of field pathlines, indexed by the equipment name, values are the index number of the activePathList
    const outOfFieldPathsIdx = {};

    for (let i = 0; i < outOfZonePathList.length; i++) {
      // Collect the out of field paths
      // Select all -1 block paths and
      if (outOfZonePathList[i].intelliblockNum == -1) {
        if (!Object.prototype.hasOwnProperty.call(outOfFieldPathsIdx, outOfZonePathList[i].equipment)) {
          outOfFieldPathsIdx[outOfZonePathList[i].equipment] = [];
        }
        outOfFieldPathsIdx[outOfZonePathList[i].equipment].push(i);
      }
    }

    setAllVehiclePathsTripTask(outOfZonePathList);
    setInZoneVehiclePathsMixed(inZonePathList);
    setOutOfFieldPathsIdxbyVeh(outOfFieldPathsIdx);
  }

  function updateVehicleMarker(showMarker, vehicleZoomed = '') {
    // Iterate through all vehicle markers
    const currVehMarkers = vehicleMarkers;
    // Remove existing markers from map
    const vehKeys = Object.keys(currVehMarkers);
    for (let i = 0; i < vehKeys.length; i++) {
      currVehMarkers[vehKeys[i]].setMap(null);
    }

    Object.keys(equipmentAnalytics).forEach((vehicleSN) => {
      const equipmentAnalytic = equipmentAnalytics[vehicleSN];
      const vehicle = vehicleSNDict[vehicleSN];
      if (showMarker && equipmentAnalytic.latestGpsPoint.length > 0) {
        const vehicleMarkerInfo = {
          name: vehicle.name,
          serialNumber: vehicle.serialNumber,
          bearing: equipmentAnalytic.bearing,
          gpsCoords: equipmentAnalytic.latestGpsPoint,
          machineType: vehicle?.machineType,
        };
        // Add new vehicle markers to map
        const marker = drawVehicleMarker(map, maps, vehicleMarkerInfo, vehicleZoomed, vehInfoWindow);
        currVehMarkers[vehicle.serialNumber] = marker;
      }
      setVehicleMarkers(currVehMarkers);
    });
  }

  function plotGeoFences() {
    // Clear previous geoFences
    for (let i = 0; i < geoFencePolygons.length; i++) {
      geoFencePolygons[i].setMap(null);
    }

    // SHOULD REALLY COMBINE ALL THE MAP COMPONENTS INTO ONE AND CLEAN UP
    // Check if blocks within zoneData
    if (
      !Object.prototype.hasOwnProperty.call(zonesData, 'blocks') ||
      Object.keys(geoFenceIntelliBlockDict).length < 1
    ) {
      return;
    }

    const polygonsList = [];
    // Iterate through blocks
    for (let i = 0; i < zonesData.blocks.length; i++) {
      // Find area of the block
      let blockArea = 0;
      const blockIntelliBlockNums = zonesData.blocks[i].intelliblock_nums;
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[j]];

        const geofenceRowPass =
          customerSettings.general.rowPassStatisticsEnabled &&
          geoFence.properties.rowPassEnabled &&
          geoFence.properties.trustRowBearing &&
          geoFence.properties.rowPassAnalysisAvailable &&
          geoFence.properties.rowPassEstimatedArea;
        if (geofenceRowPass) {
          blockArea += geoFence.properties.rowPassEstimatedArea;
        } else {
          blockArea += geoFence.properties.area_meters2;
        }
      }

      // Override block area if manually inputted area exists
      if (
        Object.prototype.hasOwnProperty.call(zonesData.blocks[i], 'manual_area_meters2') &&
        zonesData.blocks[i].manual_area_meters2 !== 0
      ) {
        blockArea = zonesData.blocks[i].manual_area_meters2;
      }

      // Iterate through each multipolygon for plotting
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[j]];
        // Iterate through each polygon in the multipolygon
        for (let k = 0; k < geoFence.geometry.coordinates.length; k++) {
          const latLngList = [];
          let maxMinPoints = [];
          let maxLatPoint;
          let maxLngPoint;
          let minLatPoint;
          let minLngPoint;
          // Iterate through each coordinate set in the polygon.
          // Sets after the first one correspond to holes inside the polygon
          let outerClockwise = false;
          for (let n = 0; n < geoFence.geometry.coordinates[k].length; n++) {
            const coordinates = geoFence.geometry.coordinates[k][n];
            const clockwise = polygonArea(coordinates) > 0;

            let latLngMap = coordinates.map((coordPair) => {
              const tempPoint = {lat: coordPair[1], lng: coordPair[0]};
              if (!maxLatPoint || tempPoint.lat > maxLatPoint.lat) {
                maxLatPoint = tempPoint;
              }
              if (!maxLngPoint || tempPoint.lng > maxLngPoint.lng) {
                maxLngPoint = tempPoint;
              }
              if (!minLatPoint || tempPoint.lat < minLatPoint.lat) {
                minLatPoint = tempPoint;
              }
              if (!minLngPoint || tempPoint.lng < minLngPoint.lng) {
                minLngPoint = tempPoint;
              }
              return tempPoint;
            });

            if (n == 0) {
              outerClockwise = clockwise;
            } else if ((outerClockwise && clockwise) || (!outerClockwise && !clockwise)) {
              latLngMap = latLngMap.reverse();
            }

            latLngList.push(latLngMap);
          }

          if (maxLatPoint && maxLngPoint && minLatPoint && minLngPoint) {
            maxMinPoints = [maxLatPoint, maxLngPoint, minLatPoint, minLngPoint];
          }

          // Determine if REI warning should show
          let reiWarningShow = false;
          let remRei = 0;
          if (todayOnly && Object.prototype.hasOwnProperty.call(reiActiveDict, zonesData.blocks[i].block_id)) {
            const reiObj = reiActiveDict[zonesData.blocks[i].block_id];
            const reiEndTime = DateTime.fromISO(reiObj.exitTime['@ts']).plus({hours: reiObj.reiHours});
            remRei = reiEndTime.diff(DateTime.now(), 'hours').toObject()?.hours;

            // Final check to ensure rei interval is valid
            if (remRei && remRei > 0 && remRei <= reiObj.reiHours) {
              reiWarningShow = true;
              remRei = Math.ceil(remRei);
            }
          }

          // Create polygon object
          let strokeColor = zoneColorsList[zoneColorIndex];
          let strokeWeight = 0.5;
          let fillColor = zoneColorsList[zoneColorIndex];
          let reiActive = false;
          if (reiWarningShow) {
            strokeColor = '#ff0000';
            strokeWeight = 6;
            fillColor = '#af4a4d';
            reiActive = true;
          }
          const polygon = new maps.Polygon({
            paths: latLngList,
            strokeColor: strokeColor,
            strokeWeight: strokeWeight,
            strokePosition: google.maps.StrokePosition.INSIDE,
            fillColor: fillColor,
            fillOpacity: 0.3,
            zIndex: -1,
            reiActive: reiActive,
            maxMinPoints: maxMinPoints,
          });

          // Add info window
          if (polygon != undefined) {
            let rowSpacingWithUnit = 'N/A';
            if (geoFence.properties.row_spacing_meters > 0) {
              rowSpacingWithUnit =
                userSettings.general.units == 'imperial'
                  ? `${(geoFence.properties.row_spacing_meters * 3.28084).toFixed(2)} ft`
                  : `${geoFence.properties.row_spacing_meters.toFixed(2)} m`;
            }
            const acreageWithUnit = `${(blockArea / 4046.856422).toFixed(2)} ac`;
            let infoWindowContent = `<div><b>${geoFence.properties.block_name}</b></div>`;

            // Display remaining rei
            if (reiWarningShow) {
              infoWindowContent +=
                `<div style="font-size:12px;color:red"><b>DO NOT ENTER</b></div>` +
                `<div style="font-size:10px;color:red"><b>Remaining REI:</b> ${remRei} hours</div>`;
            }

            // Display field and region
            infoWindowContent +=
              `<div style="font-size:10px"><b>Field:</b> ${geoFence.properties.field_name}</div>` +
              `<div style="font-size:10px"><b>Region:</b> ${geoFence.properties.region_name}</div>`;

            // Display zone info
            if (zoneInfoEnabled) {
              infoWindowContent += `<div style="font-size:10px"><b>Row Spacing:</b> ${rowSpacingWithUnit}</div>`;
              +`<div style="font-size:10px"><b>Acreage:</b> ${acreageWithUnit}</div>`;
            }

            polygon.addListener('click', (event) => {
              zoneInfoWindow.setContent(infoWindowContent);
              zoneInfoWindow.setPosition(event.latLng);
              zoneInfoWindow.open({
                map,
                shouldFocus: false,
              });
            });
          }

          polygon.setMap(null);
          polygonsList.push(polygon);
        }
      }
    }

    setGeoFencePolygons(polygonsList);
  }

  function showGpsPoints(array, color, vehicleSN) {
    const points = [];
    if (array && array.length > 0 && showGpsTime) {
      for (let j = 0; j < array.length; j++) {
        const pointData = array[j];
        const plottablePoint = {...pointData};
        delete plottablePoint['dateTime'];

        const pointInBound = map.getBounds().contains(plottablePoint);
        const zoomLevelRelevant = mapZoomLevel > 17;

        if (pointInBound && zoomLevelRelevant) {
          const pointMarker = new maps.Circle({
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color,
            fillOpacity: 0.7,
            center: plottablePoint,
            radius: 1,
          });

          const vehicleName = vehicleSNDict[vehicleSN].name;
          const infowindow = new maps.InfoWindow({
            content: `<div>${vehicleName}</div>
              <div>${DateTime.fromISO(pointData.dateTime)
                .setZone(customerSettings.general.timeZone)
                .toLocaleString(DateTime.DATETIME_SHORT)}</div>`,
          });

          pointMarker.addListener('mouseover', (event) => {
            infowindow.setPosition(event.latLng);
            infowindow.open({
              map,
              shouldFocus: false,
            });
          });

          pointMarker.addListener('mouseout', () => {
            infowindow.close();
          });
          pointMarker.setMap(map);
          points.push(pointMarker);
        }
      }
      setMarkersOnMap((values) => {
        return [...values, ...points];
      });
    }
  }

  function generateZoneLabels() {
    let allZoneLabels = [];
    if (maps != null) {
      // Check if blocks within zoneData
      if (
        !Object.prototype.hasOwnProperty.call(zonesData, 'blocks') ||
        Object.keys(geoFenceIntelliBlockDict).length < 1
      ) {
        return;
      }

      allZoneLabels = zonesData.blocks.map((block, index) => {
        // Find area of the block
        let blockArea = 0;
        const blockIntelliBlockNums = block.intelliblock_nums;
        for (let j = 0; j < blockIntelliBlockNums.length; j++) {
          if (!Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[j])) {
            continue;
          }
          const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[j]];

          const geofenceRowPass =
            customerSettings.general.rowPassStatisticsEnabled &&
            geoFence.properties.rowPassEnabled &&
            geoFence.properties.trustRowBearing &&
            geoFence.properties.rowPassAnalysisAvailable &&
            geoFence.properties.rowPassEstimatedArea;
          if (geofenceRowPass) {
            blockArea += geoFence.properties.rowPassEstimatedArea;
          } else {
            blockArea += geoFence.properties.area_meters2;
          }
        }

        // Override block area if manually inputted area exists
        if (Object.prototype.hasOwnProperty.call(block, 'manual_area_meters2') && block.manual_area_meters2 !== 0) {
          blockArea = block.manual_area_meters2;
        }

        const latCenter = (block.lat_max + block.lat_min) / 2;
        const lngCenter = (block.lng_max + block.lng_min) / 2;

        // Determine if REI warning should show
        let remRei = '';
        if (todayOnly && Object.prototype.hasOwnProperty.call(reiActiveDict, block.block_id)) {
          const reiObj = reiActiveDict[block.block_id];
          const reiEndTime = DateTime.fromISO(reiObj.exitTime['@ts']).plus({hours: reiObj.reiHours});
          remRei = reiEndTime.diff(DateTime.now(), 'hours').toObject()?.hours;

          // Final check to ensure rei interval is valid
          if (remRei && remRei > 0 && remRei <= reiObj.reiHours) {
            remRei = Math.ceil(remRei);
          }
        }

        // Get one geoFence of block
        if (!Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[0])) {
          <LabelComponent key={index} lat={latCenter} lng={lngCenter} text={''} />;
        }
        const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[0]];

        let zoneName = geoFence.properties.block_name;
        if (zoneLabelsIndex == 1) {
          zoneName = geoFence.properties.field_name;
        } else if (zoneLabelsIndex == 2) {
          zoneName = geoFence.properties.region_name;
        } else if (zoneLabelsIndex == 3 && zoneInfoEnabled) {
          let rowSpacingWithUnit = 'N/A';
          if (geoFence.properties.row_spacing_meters > 0) {
            rowSpacingWithUnit =
              userSettings.general.units == 'imperial'
                ? `${(geoFence.properties.row_spacing_meters * 3.28084).toFixed(2)} ft`
                : `${geoFence.properties.row_spacing_meters.toFixed(2)} m`;
          }
          zoneName = `Row spacing: ${rowSpacingWithUnit}`;
        } else if (zoneLabelsIndex == 4 && zoneInfoEnabled) {
          const acreageWithUnit = `${(blockArea / 4046.856422).toFixed(2)} ac`;
          zoneName = `Acreage: ${acreageWithUnit}`;
        }
        return <LabelComponent key={index} lat={latCenter} lng={lngCenter} text={zoneName} remainingRei={remRei} />;
      });
    }
    return allZoneLabels;
  }

  function filterPaths() {
    let activePathList = inZoneVehiclePathsMixed;
    let inactivePathList = allVehiclePathsTripTask;
    markersOnMap.forEach((marker) => {
      marker.setMap(null);
    });
    setMarkersOnMap([]);

    // If displayOutOfZoneTravel, show non rowline paths
    if (displayOutOfZoneTravel) {
      activePathList = allVehiclePathsTripTask;
      inactivePathList = inZoneVehiclePathsMixed;
    }

    for (let i = 0; i < inactivePathList.length; i++) {
      inactivePathList[i].path.setMap(null);
    }

    // Tracks all vehicles that have paths displayed
    //  (when not showing out of field), such when toggled on only this list of
    // out of field paths will show
    const vehicleFiltersMet = [];
    for (let i = 0; i < activePathList.length; i++) {
      if (
        (props.filters.zones.includes(activePathList[i].blockId.toString()) || props.filters.zones.length == 0) &&
        (props.filters.equipment.includes(activePathList[i].vehicleSN) || props.filters.equipment.length == 0) &&
        (props.filters.tasks.includes(activePathList[i].taskId) || props.filters.tasks.length == 0) &&
        (displayOutOfZoneTravel || activePathList[i].intelliblockNum != -1)
      ) {
        activePathList[i].path.setMap(map);
        showGpsPoints(activePathList[i].points, activePathList[i].path.strokeColor, activePathList[i].vehicleSN);

        // Add to the list of currently displayed vehicles
        if (!vehicleFiltersMet.includes(activePathList[i].vehicleSN)) {
          vehicleFiltersMet.push(activePathList[i].vehicleSN);
        }
      } else {
        activePathList[i].path.setMap(null);
      }
    }

    // Show out of field paths if they met the criteria
    if (displayOutOfZoneTravel) {
      for (let i = 0; i < vehicleFiltersMet.length; i++) {
        if (Object.prototype.hasOwnProperty.call(outOfFieldPathsIdxbyVeh, vehicleFiltersMet[i])) {
          outOfFieldPathsIdxbyVeh[vehicleFiltersMet[i]].forEach((idx) => {
            allVehiclePathsTripTask[idx].path.setMap(map);
            showGpsPoints(
              allVehiclePathsTripTask[idx].points,
              allVehiclePathsTripTask[idx].path.strokeColor,
              allVehiclePathsTripTask[idx].vehicleSN
            );
          });
        }
      }
    }
  }

  function showAll() {
    if (pathExtremes.latMin == null) {
      return;
    }
    const sw = {lat: pathExtremes.latMin, lng: pathExtremes.lngMin};
    const ne = {lat: pathExtremes.latMax, lng: pathExtremes.lngMax};

    const bounds = new maps.LatLngBounds();
    bounds.extend(sw);
    bounds.extend(ne);

    map.fitBounds(bounds);
    map.setZoom(map.getZoom() + 0.0);
  }

  function showGeoFences() {
    if (displayZones) {
      for (let i = 0; i < geoFencePolygons.length; i++) {
        geoFencePolygons[i].setMap(map);
      }
    } else {
      for (let i = 0; i < geoFencePolygons.length; i++) {
        geoFencePolygons[i].setMap(null);
      }
    }
  }

  function zoomToZone() {
    Object.values(zonesData).forEach((zoneArray) => {
      for (let i = 0; i < zoneArray.length; i++) {
        if (
          zoneArray[i].block_name == zoneZoom ||
          zoneArray[i].field_name == zoneZoom ||
          zoneArray[i].region_name == zoneZoom
        ) {
          const zoneObj = zoneArray[i];

          const latMax = parseFloat(zoneObj.lat_max);
          const latMin = parseFloat(zoneObj.lat_min);
          const lngMax = parseFloat(zoneObj.lng_max);
          const lngMin = parseFloat(zoneObj.lng_min);

          const sw = {lat: latMin, lng: lngMin};
          const ne = {lat: latMax, lng: lngMax};

          const bounds = new maps.LatLngBounds();
          bounds.extend(sw);
          bounds.extend(ne);

          map.fitBounds(bounds);
          map.setZoom(map.getZoom() + 0.0);

          dispatch(updateZoneZoom(''));
        }
      }
    });
  }

  function locateVehicle() {
    // Zoom into the selected vehicle
    if (Object.keys(vehicleMarkers).length > 0 && vehicleMarkers.hasOwnProperty(vehicleZoom)) {
      const selectedMarker = vehicleMarkers[vehicleZoom];
      // autoZoomInProgress prevents map event listeners from triggering
      autoZoomInProgress = true;
      map.setZoom(17);
      map.panTo(selectedMarker.position);
      autoZoomInProgress = false;
      setVehicleZoomed(vehicleZoom);
      dispatch(updateVehicleZoom(''));
    }
  }

  function changeZonesColor() {
    // Update color index
    zoneColorIndex = (zoneColorIndex + 1) % zoneColorsList.length;
    const currPolygons = geoFencePolygons;
    for (let i = 0; i < currPolygons.length; i++) {
      if (currPolygons[i].reiActive) {
        continue;
      }
      currPolygons[i].setOptions({
        strokeColor: zoneColorsList[zoneColorIndex],
        fillColor: zoneColorsList[zoneColorIndex],
      });
    }
    setGeoFencePolygons(currPolygons);
  }

  /**
   * Add directional markers to the pathlines.
   *
   * @param {object} map  Google map object
   * @param {object} path maps.Polyline object
   */
  function setPathlineTravelDirection(map, path) {
    // Add event listener to add directional markers to the pathlines when zoom is close enough
    google.maps.event.addListener(map, 'zoom_changed', function () {
      const zoom = map.getZoom();
      if (zoom > 14) {
        path.setOptions({
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 1.2,
                fillOpacity: 1,
                fillColor: '#ffffff',
                strokeColor: '#ffffff',
              },
              repeat: '100px',
            },
          ],
        });
      } else {
        path.setOptions({
          icons: [],
        });
      }
    });
  }

  function changeZoneLabels() {
    const zoneInfoEnabled =
      typeof userSettings.general.zoneInfoEnabled != 'undefined' && userSettings.general.zoneInfoEnabled;
    const labelCycleLength = zoneInfoEnabled ? 6 : 4;
    setZoneLabelsIndex((zoneLabelsIndex + 1) % labelCycleLength);
  }

  function changeOutOfZoneDisplay() {
    setDisplayOutOfZoneTravel(!displayOutOfZoneTravel);
  }
  return (
    <React.Fragment>
      <GoogleMapReact
        bootstrapURLKeys={{key: 'AIzaSyANXSBQLKbCPDicQsIvWsMvUWaTinztW6Q'}}
        defaultCenter={[0, 0]}
        defaultZoom={5}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({map, maps}) => {
          return handleApiLoaded(map, maps);
        }}
      >
        {displayZones &&
          zoneLabelsIndex < (zoneInfoEnabled ? 5 : 3) &&
          mapZoomLevel > 13 &&
          zoneLabels &&
          zoneLabels.length > 0 &&
          map != null &&
          maps != null &&
          zoneLabels.filter((zoneLabel) => {
            return map.getBounds().contains({lat: zoneLabel.props.lat, lng: zoneLabel.props.lng});
          })}
      </GoogleMapReact>
      {map != null &&
        createPortal(
          <React.Fragment>
            <div>
              <button className='btn-lg bg-light mt-2 mr-2' onClick={showAll}>
                <FontAwesomeIcon icon='fas fa-map-marker-alt' fixedWidth />
              </button>
            </div>
            <div>
              <button
                className='btn-lg bg-light mr-2'
                onClick={() => {
                  return setDisplayZones(!displayZones);
                }}
              >
                <FontAwesomeIcon icon={displayZones ? 'fas fa-clone' : 'far fa-clone'} fixedWidth />
              </button>
            </div>
            {displayZones && (
              <React.Fragment>
                <div>
                  <button
                    className='btn-lg bg-light mr-2 mt-n1'
                    style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
                    onClick={() => {
                      return changeZoneLabels();
                    }}
                  >
                    <FontAwesomeIcon icon={'fas fa-comment-alt'} fixedWidth />
                  </button>
                </div>
                <div>
                  <button
                    className='btn-lg bg-light mr-2 mt-n1'
                    style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
                    onClick={() => {
                      return changeZonesColor();
                    }}
                  >
                    <FontAwesomeIcon icon={'fas fa-palette'} fixedWidth />
                  </button>
                </div>
                <div>
                  <button
                    className='btn-lg bg-light mr-2 mt-n1'
                    style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
                    onClick={() => {
                      changeOutOfZoneDisplay();
                    }}
                  >
                    <FontAwesomeIcon icon='fa-solid fa-road' fixedWidth />
                  </button>
                </div>
                <div>
                  <button
                    className='btn-lg bg-light mr-2 mt-n1'
                    style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
                    onClick={() => {
                      setShowGpsTime(!showGpsTime);
                    }}
                  >
                    <span className='fa-layers fa-fw'>
                      <FontAwesomeIcon icon='fas fa-location-dot' />
                      <FontAwesomeIcon
                        icon='far fa-clock'
                        transform='shrink-5 up-8 right-8'
                        style={{color: showGpsTime ? 'green' : 'red'}}
                      />
                    </span>
                  </button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>,
          controlButtonDiv
        )}
      {map != null &&
        (!pathsLoaded || pathQueryStatus.success == false) &&
        createPortal(
          <Collapse in={displayPathStatus} className='w-100 px-2 mt-3 mx-2'>
            <Alert
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setDisplayPathStatus(false);
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              icon={pathsLoaded ? undefined : false}
              variant='outlined'
              severity={!pathsLoaded ? 'success' : pathQueryStatus.pathDataTooLarge ? 'warning' : 'error'}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                width: map.getDiv().offsetWidth * 0.65,
                '.MuiAlert-action': {
                  paddingLeft: '4px',
                },
                '.MuiAlert-message': {
                  display: 'contents',
                },
              }}
            >
              {!pathsLoaded ? (
                <div className='row d-flex p-2 w-100 align-items-center justify-content-center text-center'>
                  <div className='loader d-inline-flex' style={{height: '50px', width: '50px', flexShrink: 0}}></div>
                  <div className='pl-1'>Loading Coverage Paths...</div>
                </div>
              ) : (
                <div className='row d-flex p-2 w-100 align-items-center justify-content-center text-center'>
                  {pathQueryStatus.pathDataTooLarge
                    ? `Path data too large to be displayed, please select a smaller data set.`
                    : `Failed to retrieve all path data, please try again or select a smaller data set.`}
                </div>
              )}
            </Alert>
          </Collapse>,
          alertMessage
        )}
    </React.Fragment>
  );
}

export {Map};

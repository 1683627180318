import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function Table(props) {
  function renderTableHeader() {
    return props.poo.map((column, index) => {
      return (
        <th key={column} width={props.widths[index]} className={props.columnClass[index]}>
          {column}
        </th>
      );
    });
  }

  function renderTableBody() {
    if (props.loading) {
      return (
        <tbody id='table-body'>
          <tr></tr>
          <tr>
            <td colSpan='100'>
              <div className='m-2'>
                <div className='loader mx-auto'></div>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
    return props.body;
  }

  return (
    <div className='table-responsive'>
      <div className='card min-height-table'>
        <div className='table-wrapper'>
          <table
            className={`table table-striped ${props.nestedTable ? 'nested-table' : ''}`}
            style={{'minHeight': props.minHeightOverride ? props.minHeightOverride : '200px'}}
          >
            <thead className={`text-dark ${props?.stickyHeaders == false ? '' : 'sticky-top'} bg-white table-head`}>
              {props.headers ? props.headers : <tr>{renderTableHeader()}</tr>}
            </thead>
            {renderTableBody()}
          </table>
        </div>
      </div>
    </div>
  );
}

function TableHeadFilterButtonTitleWrapper(props) {
  return (
    <div className='d-flex tablehead-filter'>
      {props.children}
      <span className='align-self-center'>{props.title}</span>
      {props.bulkEditMode && props.handleTogglePropagateAll && props.bulkEditPropertyName && (
        <button
          className='btn btn-sm'
          style={props.propagateAllColumns?.[props.bulkEditPropertyName] ? {color: '#4e73df'} : {}}
          onClick={() => {
            props.handleTogglePropagateAll(props.bulkEditPropertyName);
          }}
        >
          <span className='fa-layers fa-fw fa-lg'>
            <FontAwesomeIcon icon='fa-solid fa-circle' />
            <FontAwesomeIcon icon='fa fa-rotate' transform='shrink-4' inverse />
          </span>
        </button>
      )}
    </div>
  );
}

function TableHeadFilterButtonTitleWrapperWithSort(props) {
  return (
    <div className='d-flex tablehead-filter'>
      {props.children}
      <span className='align-self-center'>{props.title}</span>
      <FontAwesomeIcon onClick={props.onClickHandler} style={{'margin': 5, cursor: 'pointer'}} icon='fa fa-sort' />
    </div>
  );
}

function TableHeadHiddenDropdownWrapper(props) {
  return <div className='d-flex hidden-select'>{props.children}</div>;
}

export {
  Table,
  TableHeadFilterButtonTitleWrapper,
  TableHeadHiddenDropdownWrapper,
  TableHeadFilterButtonTitleWrapperWithSort,
};

import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Tabs} from '../../../components/Tabs';
import {TabContent} from '../../../components/TabContent';
import {InspectionTab} from './InspectionTab';
import {KPIsTab} from './KPIsTab';
import {ReportConfigTab} from './ReportConfigTab';

function ReportsTab(props) {
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [reportsTabs, setReportsTabs] = useState([]);
  const [reportsIcons, setReportsIcons] = useState([]);
  const [reportsContent, setReportsContent] = useState([]);

  useEffect(async () => {
    determineTabDisplay();
  }, []);

  function determineTabDisplay() {
    const tabs = [];
    const icons = [];
    const content = [];

    // Report Configuration
    if (userSettings.roleViewAccess['reportsManagement']) {
      tabs.push('Report Configuration');
      icons.push('fa-solid fa-file');
      content.push(<ReportConfigTab key='ReportConfiguration' />);
    }

    // Inspection
    if (userSettings.roleViewAccess['inspectionManagement']) {
      tabs.push('Inspection');
      icons.push('fa-solid fa-check-to-slot');
      content.push(<InspectionTab key='Inspection' />);
    }

    if (userSettings.roleViewAccess['dashboardManagement']) {
      tabs.push('KPI');
      icons.push('fa-solid fa-chart-simple');
      content.push(<KPIsTab key='KPI' />);
    }

    setReportsTabs(tabs);
    setReportsIcons(icons);
    setReportsContent(content);
  }

  return (
    <div className='tab-wrapper'>
      <div className='row'>
        <div className='col-12'>
          <Tabs tabs={reportsTabs} icons={reportsIcons} sub={true} />
        </div>
      </div>
      <TabContent content={reportsContent} />
    </div>
  );
}

export {ReportsTab};

import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {TextField, Autocomplete, Switch} from '@mui/material';
import {DateTime} from 'luxon';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import {handleAllowOnlyNumbers} from '../../app/utils';

const dynamicOptions = ['assetId'];

function BulkEditInputs(props) {
  const dispatch = useDispatch();
  const entityUpdateDoc = useSelector((state) => {
    return state.settings[props.bulkEditStateName][props.entityId];
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const [inputStyling, setInputStyling] = useState(
    Object.prototype.hasOwnProperty.call(entityUpdateDoc, props.propertyName) &&
      entityUpdateDoc?.[props.propertyName] != props.defaultValue
      ? {border: 2, borderColor: '#42a5f5', borderRadius: 1.5}
      : {}
  );
  const [assetOptions, setAssetOptions] = useState([]);

  useEffect(() => {
    if (dynamicOptions.includes(props.propertyName)) {
      const options = props.renderOptions(entityUpdateDoc['assetType']);
      setAssetOptions(options);
    }
  }, [entityUpdateDoc]);

  useEffect(() => {
    if (props.submissionErrors) {
      setInputStyling({border: 2, borderColor: '#FF0000', borderRadius: 1.5});
    }
    // If we edited we can remove the red border
    else {
      setInputStyling(
        Object.prototype.hasOwnProperty.call(entityUpdateDoc, props.propertyName) &&
          entityUpdateDoc?.[props.propertyName] != props.defaultValue
          ? {border: 2, borderColor: '#42a5f5', borderRadius: 1.5}
          : {}
      );
    }
  }, [props.submissionErrors, entityUpdateDoc]);

  if (props.type == 'text' || props.type == 'number') {
    let type = props.type;
    if (type == 'number') {
      type = 'text';
    }

    return (
      <Tooltip title={props.submissionErrors} placement='top' arrow>
        <TextField
          key={`${props.bulkEditStateName}_${props.entityId}_${props.propertyName}`}
          sx={inputStyling}
          size='small'
          value={
            entityUpdateDoc.hasOwnProperty(props.propertyName)
              ? entityUpdateDoc[props.propertyName]
              : props.defaultValue
          }
          type={type}
          InputProps={props.InputProps}
          onChange={(e) => {
            if (props.hasOwnProperty('inputValidation')) {
              if (props.inputValidation(e.target.value, props.propertyName)) {
                props.handleBulkEditUpdate(props.entityIdName, props.entityId, props.propertyName, e.target.value);
              }
            } else {
              props.handleBulkEditUpdate(props.entityIdName, props.entityId, props.propertyName, e.target.value);
            }
          }}
          onKeyDown={props.type == 'number' ? handleAllowOnlyNumbers : null}
        />
      </Tooltip>
    );
  }

  if (props.type == 'select') {
    return (
      <Tooltip title={props.submissionErrors} placement='top' arrow>
        <div className='w-100'>
          <Autocomplete
            key={`${props.bulkEditStateName}_${props.entityId}_${props.propertyName}`}
            sx={inputStyling}
            size='small'
            multiple={props.multiple}
            value={
              entityUpdateDoc.hasOwnProperty(props.propertyName)
                ? entityUpdateDoc[props.propertyName]
                : props.defaultValue
            }
            options={
              !dynamicOptions.includes(props.propertyName) ? props.renderOptions(props.defaultValue) : assetOptions
            }
            getOptionLabel={(option) => {
              if (typeof option == 'object') {
                return option.label;
              } else {
                let optionObj;
                if (!dynamicOptions.includes(props.propertyName)) {
                  optionObj = props.renderOptions(props.defaultValue).find((opt) => {
                    return opt.value == option;
                  });
                } else {
                  optionObj = assetOptions.find((opt) => {
                    return opt.value == option;
                  });
                }

                if (optionObj) {
                  return optionObj.label;
                } else {
                  return '';
                }
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} placeholder='Search...' />;
            }}
            isOptionEqualToValue={(option, value) => {
              return option.value == value;
            }}
            onChange={(event, value) => {
              if (value === null) {
                value = {value: ''};
              }
              props.handleBulkEditUpdate(props.entityIdName, props.entityId, props.propertyName, value.value);
            }}
          />
        </div>
      </Tooltip>
    );
  }

  if (props.type == 'multiSelect') {
    return (
      <Tooltip title={props.submissionErrors} placement='top' arrow>
        <div className='w-100'>
          <Autocomplete
            key={`${props.bulkEditStateName}_${props.entityId}_${props.propertyName}`}
            sx={inputStyling}
            size='small'
            multiple={props.multiple}
            value={
              entityUpdateDoc.hasOwnProperty(props.propertyName)
                ? entityUpdateDoc[props.propertyName]
                : props.defaultValue
            }
            options={
              !dynamicOptions.includes(props.propertyName) ? props.renderOptions(props.defaultValue) : assetOptions
            }
            getOptionLabel={(option) => {
              if (typeof option == 'object' && option != null) {
                return option.label;
              } else {
                let optionObj;
                if (!dynamicOptions.includes(props.propertyName)) {
                  optionObj = props.renderOptions(props.defaultValue).find((opt) => {
                    return opt.value == option;
                  });
                } else {
                  optionObj = assetOptions.find((opt) => {
                    return opt.value == option;
                  });
                }

                if (optionObj) {
                  return optionObj.label;
                } else {
                  return '';
                }
              }
            }}
            renderInput={(params) => {
              return <TextField {...params} placeholder='Search...' />;
            }}
            isOptionEqualToValue={(option, value) => {
              return option.value == value;
            }}
            onChange={(event, value) => {
              if (value === null) {
                value = {value: props.defaultValue || ''};
              }
              let newVal = null;
              let deletedVal = null;
              if (props.propertyName == 'vehicleType') {
                entityUpdateDoc.vehicleType.forEach((item) => {
                  if (!value.includes(item)) {
                    deletedVal = item;
                  }
                });
              }
              const newArr = value.map((val) => {
                if (val !== null && typeof val === 'object') {
                  newVal = val.value;
                  return val.value;
                } else {
                  return val;
                }
              });
              props.handleBulkEditUpdate(
                props.entityIdName,
                props.entityId,
                props.propertyName,
                newArr,
                newVal,
                deletedVal
              );
            }}
          />
        </div>
      </Tooltip>
    );
  }

  if (props.type == 'date') {
    // Using for Perm Implements/Task Expiry, need to move InputProps to allow other date selection
    const hasNewImplementandTask =
      entityUpdateDoc.hasOwnProperty('linkedImplementSN') && entityUpdateDoc.hasOwnProperty('linkedTaskId');
    let newImpTaskInvalid = false;
    if (hasNewImplementandTask && entityUpdateDoc['linkedImplementSN'] == '' && entityUpdateDoc['linkedTaskId'] == '') {
      newImpTaskInvalid = true;
    }
    return (
      <TextField
        key={`${props.bulkEditStateName}_${props.entityId}_${props.propertyName}`}
        sx={inputStyling}
        disabled={hasNewImplementandTask ? newImpTaskInvalid : props.disabled}
        size='small'
        type='date'
        name='expiryDate'
        InputProps={{
          inputProps: {
            min: DateTime.local({zone: customerSettings.general.timeZone}).plus({days: 1}).toISO().slice(0, 10),
          },
        }}
        value={
          entityUpdateDoc.hasOwnProperty(props.propertyName) ? entityUpdateDoc[props.propertyName] : props.defaultValue
        }
        onChange={(e) => {
          props.handleBulkEditUpdate(props.entityIdName, props.entityId, props.propertyName, e.target.value);
        }}
      />
    );
  }

  if (props.type == 'checkbox') {
    return (
      <Checkbox
        key={`${props.bulkEditStateName}_${props.entityId}_${props.propertyName}`}
        sx={inputStyling}
        size='small'
        checked={
          entityUpdateDoc.hasOwnProperty(props.propertyName) ? entityUpdateDoc[props.propertyName] : props.defaultValue
        }
        onChange={(e) => {
          props.handleBulkEditUpdate(props.entityIdName, props.entityId, props.propertyName, e.target.checked);
        }}
      />
    );
  }

  if (props.type == 'switch') {
    return (
      <Switch
        key={`${props.bulkEditStateName}_${props.entityId}_${props.propertyName}`}
        sx={inputStyling}
        size='small'
        checked={
          entityUpdateDoc.hasOwnProperty(props.propertyName) ? entityUpdateDoc[props.propertyName] : props.defaultValue
        }
        onChange={(e) => {
          props.handleBulkEditUpdate(props.entityIdName, props.entityId, props.propertyName, e.target.checked);
        }}
      />
    );
  }

  return null;
}

export {BulkEditInputs};

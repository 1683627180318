import {createSlice} from '@reduxjs/toolkit';

const inspectionFormSlice = createSlice({
  name: 'inspectionForm',
  initialState: {
    // inspection item cache
    inspectionItems: [],
    activeInspectionItems: [],
    // states used to manage display transitions
    inspectionSelectedVehicle: {},
    inspectionFormSubmitted: false,
    // state to cache the user entered data
    inspectionReport: {},
    // state to cache any scanned vehicle
    scannedVehicle: '',
    inspectionNameInput: '',
  },
  reducers: {
    updateInspectionItems(state, action) {
      const inspectionItems = action.payload;

      // Filter out any archived inspectionItems as they are not needed
      const activeInspectionItems = inspectionItems.filter((inspectionItemDoc) => {
        return typeof inspectionItemDoc.archived !== 'undefined' && !inspectionItemDoc.archived;
      });

      // Set the arrays into the states
      state.inspectionItems = inspectionItems;
      state.activeInspectionItems = activeInspectionItems;
    },
    setInspectionSelectedVehicle(state, action) {
      state.inspectionSelectedVehicle = action.payload;
    },
    setInspectionScannedVehicle(state, action) {
      state.scannedVehicle = action.payload;
    },
    setInspectionFormSubmitted(state, action) {
      state.inspectionFormSubmitted = action.payload;
    },
    setInspectionReport(state, action) {
      state.inspectionReport = action.payload;
    },
    setInspectionNameInput(state, action) {
      state.inspectionNameInput = action.payload;
    },
  },
});

export const {
  updateInspectionItems,
  setInspectionSelectedVehicle,
  setInspectionScannedVehicle,
  setInspectionFormSubmitted,
  setInspectionReport,
  setInspectionNameInput,
} = inspectionFormSlice.actions;
export default inspectionFormSlice.reducer;

import {createSlice} from '@reduxjs/toolkit';

const zoneInfoSlice = createSlice({
  name: 'zoneinfo',
  initialState: {
    loading: true,
    zonesData: {
      'blocks': [],
      'fields': [],
      'regions': [],
    },
    geoFences: [],
    geoFenceIntelliBlockDict: {},
    geoFenceAttributesIntelliBlockDict: {},
    blockToIBlockDict: {},
    parentFieldLookup: {},
    parentRegionLookup: {},
    displayedTable: 'block',
    cropTypesDict: {}, // Store crop data as {primaryClass: [subClass...]}
    cropTypesDropdownOptions: [], // Store formated crop type edit dropdown options
    editZoneInfo: false,
    editZoneInfoValues: {},
    sortMethod: 'name',
    mapView: 1,
    zoneZoom: '',
    todayOnly: false,
  },
  reducers: {
    updateLoading(state, action) {
      state.loading = action.payload;
    },
    updateZoneInfoData(state, action) {
      state.zonesData = action.payload.zonesData;
      state.geoFences = action.payload.geoFences;

      // Collect a dictionary of geoFences and geoFenceAttributes based on intelliblock_num
      const geoFenceIntelliBlockDict = {};
      for (let i = 0; i < state.geoFences.length; i++) {
        if (typeof state.geoFences[i].properties.block_id === 'undefined') {
          continue;
        }

        if (
          !Object.prototype.hasOwnProperty.call(
            geoFenceIntelliBlockDict,
            state.geoFences[i].properties.intelliblock_num
          )
        ) {
          geoFenceIntelliBlockDict[state.geoFences[i].properties.intelliblock_num] = state.geoFences[i];
        }
      }
      state.geoFenceIntelliBlockDict = geoFenceIntelliBlockDict;
      state.geoFenceAttributesIntelliBlockDict = action.payload.geoFenceAttributes;

      const zonesData = action.payload.zonesData;
      const fieldIdToFieldName = {};
      const regionIdToRegionName = {};
      const blockNameToFieldName = {};
      const fieldNameToRegionName = {};
      for (let i = 0; i < zonesData.regions.length; i++) {
        regionIdToRegionName[zonesData.regions[i].region_id] = zonesData.regions[i].region_name;
      }
      for (let i = 0; i < zonesData.fields.length; i++) {
        fieldIdToFieldName[zonesData.fields[i].field_id] = zonesData.fields[i].field_name;
        fieldNameToRegionName[zonesData.fields[i].field_name] =
          regionIdToRegionName[zonesData.fields[i].parent_region_id];
      }
      for (let i = 0; i < zonesData.blocks.length; i++) {
        blockNameToFieldName[zonesData.blocks[i].block_name] = fieldIdToFieldName[zonesData.blocks[i].parent_field_id];
      }

      state.parentFieldLookup = blockNameToFieldName;
      state.parentRegionLookup = fieldNameToRegionName;
    },
    updateDisplayedTable(state, action) {
      state.displayedTable = action.payload;
    },
    updateCropTypesDict(state, action) {
      state.cropTypesDict = action.payload;
    },
    updateCropTypesDropdownOptions(state, action) {
      state.cropTypesDropdownOptions = action.payload;
    },
    updateEditZoneInfo(state, action) {
      const editZoneInfoBool = action.payload;

      state.editZoneInfo = editZoneInfoBool;
      if (!editZoneInfoBool) {
        state.editZoneInfoValues = {};
      }
    },
    updateEditZoneInfoValues(state, action) {
      state.editZoneInfoValues = action.payload;
    },
    updateDisplayedColumn(state, action) {
      state.displayedColumn = action.payload;
    },
    updateSortMethod(state, action) {
      state.sortMethod = action.payload;
    },
    updateMapView(state, action) {
      // 0: Only table, 1: Table and map, 2: Only map
      const mapSetting = action.payload;
      if (mapSetting == undefined) {
        state.mapView = (state.mapView + 1) % 3;
      } else {
        state.mapView = mapSetting;
      }
    },
    updateZoneZoom(state, action) {
      state.zoneZoom = action.payload;
    },
  },
});

export const {
  updateLoading,
  updateDisplayedTable,
  updateSortMethod,
  updateMapView,
  updateZoneZoom,
  updateZoneInfoData,
  updateCropTypesDict,
  updateCropTypesDropdownOptions,
  updateEditZoneInfo,
  updateEditZoneInfoValues,
} = zoneInfoSlice.actions;
export default zoneInfoSlice.reducer;

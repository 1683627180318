import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Framework} from '../../components/Framework';
import {Tabs} from '../../components/Tabs';
import {TabContent} from '../../components/TabContent';
import {Menu} from './Menu';
import {KpiEfficiency} from './Kpi/KpiEfficiency';
import {KpiIdling} from './Kpi/KpiIdling';
import {KpiInspectionRates} from './Kpi/KpiInspectionRates';
import {KpiMachineUsage} from './Kpi/KpiMachineUsage';
import {KpiMissingTasks} from './Kpi/KpiMissingTasks';
import {KpiOperationalEfficiency} from './Kpi/KpiOperationalEfficiency';
import {KpiServiceCosts} from './Kpi/KpiServiceCosts';
import {KpiServiceCompletion} from './Kpi/KpiServiceCompletion';
import {KpiTasksLogged} from './Kpi/KpiTasksLogged';
import {KpiLanding} from './KpiLanding';
import {ScoreCardWrapper} from './scoreCardWrapper';
import {setFilterOptions, setSelectedKPI, setSelectedSubset} from './dashboardSlice';
import {sortVehicleNamesHelper, isNumeric, unitsDisplayConversion} from '../../app/utils';

function Statsview(props) {
  const dispatch = useDispatch();

  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const kpiLandingPageOn = useSelector((state) => {
    return state.dashboard.kpiLandingPageOn;
  });
  const selectedKpi = useSelector((state) => {
    return state.dashboard.selectedKPI;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });
  const [dashboardTabs, setDashboardTabs] = useState([]);
  const [dashboardIcons, setDashboardIcons] = useState([]);
  const [dashboardContent, setDashboardContent] = useState([]);
  const [farmingTabs, setFarmingTabs] = useState([]);
  const [machineryTabs, setMachineryTabs] = useState([]);

  useEffect(async () => {
    determineTabDisplay();
  }, [customerSettings]);

  useEffect(() => {
    const newFilterOptions = {
      ...filterOptions,
    };
    if (operationsData && 'vehiclesObject' in operationsData) {
      const vehicleSNList = Object.keys(operationsData.vehiclesObject);
      const missingTaskVehicleSNList = [];
      vehicleSNList.forEach((vehicleSN) => {
        if (operationsData.vehiclesObject[vehicleSN].missingTaskHours > 0) {
          missingTaskVehicleSNList.push({value: vehicleSN, text: operationsData.vehiclesObject[vehicleSN].vehicleName});
        }
      });

      vehicleSNList.sort((vehicleSNa, vehicleSNb) => {
        const aName = operationsData.vehiclesObject[vehicleSNa].vehicleName;
        const bName = operationsData.vehiclesObject[vehicleSNb].vehicleName;

        return sortVehicleNamesHelper(aName, bName);
      });

      newFilterOptions.vehicles = {
        ...newFilterOptions.vehicles,
        vehicleSNs: vehicleSNList.map((vehicleSN) => {
          return {value: vehicleSN, text: operationsData.vehiclesObject[vehicleSN].vehicleName};
        }),
      };

      missingTaskVehicleSNList.sort((vehiclea, vehicleb) => {
        const aName = vehiclea.text;
        const bName = vehicleb.text;

        return sortVehicleNamesHelper(aName, bName);
      });

      newFilterOptions.tasks = {
        ...newFilterOptions.tasks,
        missingTaskVehicleSNs: missingTaskVehicleSNList,
      };
    }

    if (operationsData && 'blocksObject' in operationsData) {
      const blockNameList = Object.keys(operationsData.blocksObject);
      const rowSpacingList = Array.from(
        new Set(
          blockNameList.map((blockName) => {
            const blockRowSpacing = operationsData.blocksObject[blockName]['row_spacing_meters'];
            return isNumeric(blockRowSpacing) ? blockRowSpacing : 0;
          })
        )
      ).sort();
      const rowSpacingOptions = [];
      rowSpacingList.forEach((rowSpacing) => {
        const rowSpacingDisplayText =
          rowSpacing == 0
            ? 'Not Assigned'
            : unitsSystem == 'imperial'
            ? `${unitsDisplayConversion(rowSpacing, 'ft')} ft`
            : `${Math.round(rowSpacing * 100) / 100} m`;
        rowSpacingOptions.push({
          value: Math.round(rowSpacing * 100) / 100,
          text: rowSpacingDisplayText,
        });
      });

      newFilterOptions.efficiency = {
        ...newFilterOptions.efficiency,
        efficiencyRowSpacing: rowSpacingOptions,
      };
    }
    if (operationsData && 'fieldsObject' in operationsData) {
      const fieldsNameList = Object.keys(operationsData.fieldsObject);
      fieldsNameList.sort((a, b) => {
        return a.localeCompare(b);
      });
      newFilterOptions.tasks = {
        ...newFilterOptions.tasks,
        fields: fieldsNameList,
      };
    }
    if (operationsData && 'regionsObject' in operationsData) {
      const regionsNameList = Object.keys(operationsData.regionsObject);
      regionsNameList.sort((a, b) => {
        return a.localeCompare(b);
      });
      newFilterOptions.tasks = {
        ...newFilterOptions.tasks,
        regions: regionsNameList,
      };
    }

    dispatch(setFilterOptions(newFilterOptions));
  }, [operationsData]);

  function scorecardNavigateToKPI() {
    updateSelectedKpi('Farming');
  }

  function updateSelectedKpi(tab) {
    dispatch(setSelectedKPI(tab));
    if (tab == 'Farming') {
      dispatch(setSelectedSubset(farmingTabs[0]));
    }
    if (tab == 'Machinery') {
      dispatch(setSelectedSubset(machineryTabs[0]));
    }
  }

  function updateSelectedSubset(tab) {
    dispatch(setSelectedSubset(tab));
  }

  function determineTabDisplay() {
    const dashboardTabsTemp = [];
    const dashboardIconsTemp = [];
    const dashboardContentTemp = [];
    const farmingTabs = [];
    const farmingIcons = [];
    const farmingContent = [];
    const machineryTabs = [];
    const machineryIcons = [];
    const machineryContent = [];

    // Farming
    if (customerSettings.dashboard.kpiEfficiencyEnabled) {
      farmingTabs.push('Efficiency');
      farmingIcons.push('fa-solid fa-chart-column');
      farmingContent.push(<KpiEfficiency key='Efficiency' />);
    }
    if (customerSettings.dashboard.kpiOperationalEfficiencyEnabled) {
      farmingTabs.push('Operational Efficiency');
      farmingIcons.push('fa-regular fa-clock');
      farmingContent.push(<KpiOperationalEfficiency key='OperationalEfficiency' />);
    }
    if (customerSettings.dashboard.kpiTasksLoggedEnabled) {
      farmingTabs.push('Tasks Logged');
      farmingIcons.push('fa-solid fa-calendar-check');
      farmingContent.push(<KpiTasksLogged key='TasksLogged' />);
    }
    if (customerSettings.dashboard.kpiMissingTasksEnabled) {
      farmingTabs.push('Missing Tasks');
      farmingIcons.push('fa-solid fa-calendar-times');
      farmingContent.push(<KpiMissingTasks key='MissingTasks' />);
    }
    if (farmingTabs.length > 0) {
      dashboardTabsTemp.push('Farming');
      dashboardIconsTemp.push('fa-solid fa-seedling');
      dashboardContentTemp.push(
        <ParentTab
          key='Farming'
          tabs={farmingTabs}
          icons={farmingIcons}
          content={farmingContent}
          onClickHandler={updateSelectedSubset}
        />
      );
      dispatch(setSelectedKPI('Farming'));
      dispatch(setSelectedSubset(farmingTabs[0]));
    }

    // Machinery
    if (customerSettings.dashboard.kpiMachineUsageEnabled) {
      machineryTabs.push('Machine Usage');
      machineryIcons.push('fa-solid fa-truck-fast');
      machineryContent.push(<KpiMachineUsage key='MachineUsage' />);
    }
    if (customerSettings.dashboard.kpiIdlingEnabled) {
      machineryTabs.push('Idling');
      machineryIcons.push('fa-solid fa-hourglass-half');
      machineryContent.push(<KpiIdling key='Idling' />);
    }
    if (customerSettings.dashboard.kpiServiceCostsEnabled) {
      machineryTabs.push('Service Costs');
      machineryIcons.push('fa-solid fa-money-check-dollar');
      machineryContent.push(<KpiServiceCosts key='ServiceCosts' />);
    }
    if (customerSettings.dashboard.kpiServiceCompletionEnabled) {
      machineryTabs.push('Service Health');
      machineryIcons.push('fa-solid fa-tools');
      machineryContent.push(<KpiServiceCompletion key='ServiceHealth' />);
    }
    if (customerSettings.dashboard.kpiInspectionRatesEnabled) {
      machineryTabs.push('Inspection Rates');
      machineryIcons.push('fa-solid fa-clipboard-list');
      machineryContent.push(<KpiInspectionRates key='InspectionRates' />);
    }
    if (machineryTabs.length > 0) {
      dashboardTabsTemp.push('Machinery');
      dashboardIconsTemp.push('fa-solid fa-tractor');
      dashboardContentTemp.push(
        <ParentTab
          key='Machinery'
          tabs={machineryTabs}
          icons={machineryIcons}
          content={machineryContent}
          onClickHandler={updateSelectedSubset}
        />
      );
      if (farmingTabs.length == 0) {
        dispatch(setSelectedKPI('Machinery'));
        dispatch(setSelectedSubset(machineryTabs[0]));
      }
    }

    setDashboardTabs(dashboardTabsTemp);
    setDashboardIcons(dashboardIconsTemp);
    setDashboardContent(dashboardContentTemp);
    setFarmingTabs(farmingTabs);
    setMachineryTabs(machineryTabs);
  }

  return (
    <React.Fragment>
      <Framework activePage='statsview' pageName='StatsView'>
        <Menu />
        {kpiLandingPageOn ? (
          <div className='container-fluid tab-wrapper'>
            <KpiLanding dashboardTabs={dashboardTabs} updateSelectedKpi={updateSelectedKpi} />
          </div>
        ) : selectedKpi == 'Scorecards' ? (
          <ScoreCardWrapper scorecardNavigateToKPI={scorecardNavigateToKPI} />
        ) : selectedKpi == 'Scorecards' ? (
          <ScoreCardWrapper scorecardNavigateToKPI={scorecardNavigateToKPI} />
        ) : (
          <div className='container-fluid tab-wrapper'>
            <div className='row'>
              <div className='col-12'>
                <Tabs
                  tabs={dashboardTabs}
                  icons={dashboardIcons}
                  onClickHandler={updateSelectedKpi}
                  activeTab={selectedKpi}
                />
              </div>
            </div>
            <TabContent content={dashboardContent} activeTab={selectedKpi} defaultTabSelection={true} />
          </div>
        )}
      </Framework>
    </React.Fragment>
  );
}

function ParentTab(props) {
  return (
    <div className='tab-wrapper'>
      <div className='row'>
        <div className='col-12'>
          <Tabs tabs={props.tabs} icons={props.icons} sub={true} onClickHandler={props.onClickHandler} />
        </div>
      </div>
      <TabContent content={props.content} />
    </div>
  );
}

export {Statsview};

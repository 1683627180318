import {createSlice} from '@reduxjs/toolkit';

const searchBarSlice = createSlice({
  name: 'searchBar',
  initialState: {
    searchText: '',
  },
  reducers: {
    updateSearchText(state, action) {
      state.searchText = action.payload;
    },
  },
});

export const {updateSearchText} = searchBarSlice.actions;
export default searchBarSlice.reducer;

// Import for framework tools
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  rowApplicationTypeMapping,
  sendGAPageview,
  fetchPostAuthSafe,
  unitsDisplayConversion,
  unitsSubmitConversion,
} from '../../../../app/utils';
import {Menu} from './Menu';
// Import dependent components
import {ImplementsTable} from './ImplementsTable';
import {ImplementEditModal} from './ImplementEditModal';
import {
  getImplementsData,
  getTaskConfigsData,
  updateTableLoading,
  setImplementsBulkEditMode,
  initializeBulkEditImplements,
} from '../../settingsSlice';
import {TabMenuTableWrapper} from '../../../../components/TabMenuTableWrapper';
import {BulkUploadModal} from '../../../../components/BulkUploadModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function ImplementsSettings(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => {
    return state.settings.tableLoading.implements;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const implementsBulkEditMode = useSelector((state) => {
    return state.settings.implementsBulkEditMode;
  });
  const implementSNDict = useSelector((state) => {
    return state.settings.implementSNDict;
  });
  const btTags = useSelector((state) => {
    return state.settings.btTags;
  });
  const labels = useSelector((state) => {
    return state.settings.labels;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const [sortMethod, setSortMethod] = useState('name');
  const [implementNamesList, setImplementNamesList] = useState([]);
  const [taskConfigNameToId, setTaskConfigNameToId] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [editImplement, setEditImplement] = useState({
    name: '',
    vin: '',
    make: '',
    notes: '',
    serialNumber: '',
    dimensions: {
      'width': 0,
      'height': 0,
      'length': 0,
    },
    linkedTaskId: '',
    archived: false,
    rowApplicationType: 0,
    applicationRows: 0,
    qrLabelId: '',
    btTagId: '',
  });

  const [tableData, setTableData] = useState([]);

  const [filterOptions, setFilterOptions] = useState({
    implementSN: [],
    implementLinkedTask: [],
    implementRapType: Object.keys(rowApplicationTypeMapping).map((key) => {
      if (rowApplicationTypeMapping[key] == 'Limited') {
        return null;
      }
      return rowApplicationTypeMapping[key];
    }),
    implementStatus: ['Archived', 'Active'],
    implementVin: [],
    implementMake: [],
    implementNotes: [],
  });
  const [filters, setFilters] = useState({
    implementSN: [],
    implementLinkedTask: [],
    implementRapType: [],
    implementStatus: [],
    implementVin: [],
    implementMake: [],
    implementNotes: [],
  });

  function submitBulkEdits() {
    console.log('Submitting bulk edits');
  }

  function handleEditModalOpen(openState) {
    setEditModalOpen(openState);
  }
  function handleBulkEditModeToggle(editState) {
    dispatch(setImplementsBulkEditMode(editState));
  }

  function handleBulkUploadModalOpen(openState) {
    setBulkUploadModalOpen(openState);
  }

  function handleUpdateEditImplement(Implement) {
    setEditImplement(Implement);
  }
  function handleSetSortMethod(method) {
    setSortMethod(method);
  }

  useEffect(() => {
    getTaskConfigsImplementsDataFromApi();
  }, []);

  useEffect(() => {
    processImplementsData();
  }, [implementSNDict, taskConfigDict, sortMethod, btTags, labels]);

  useEffect(() => {
    const tempTaskConfigNameToId = {};
    Object.keys(taskConfigDict).forEach((taskId) => {
      const taskConfigDoc = taskConfigDict[taskId];
      tempTaskConfigNameToId[taskConfigDoc['name'].toLowerCase()] = taskConfigDoc['taskId'];
    });
    setTaskConfigNameToId(tempTaskConfigNameToId);

    const tempImplementNamesList = Object.keys(implementSNDict).map((implementSN) => {
      return implementSNDict[implementSN].name;
    });
    setImplementNamesList(tempImplementNamesList);
  }, [taskConfigDict, implementSNDict]);

  function sortData(dataList) {
    // Make copy of list or else will get error when trying to sort
    const implementList = JSON.parse(JSON.stringify(dataList));
    // Sort zones and vehicle list within zone by method specified
    // Will sort a after b if compare function returns > 0 and sort a before b if compare function returns < 0
    if (sortMethod == 'name') {
      implementList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } else if (sortMethod == 'vin') {
      implementList.sort((a, b) => {
        if (!a.vin) {
          return 1;
        } else if (!b.vin) {
          return -1;
        }
        return a.vin.localeCompare(b.vin);
      });
    } else if (sortMethod == 'make') {
      implementList.sort((a, b) => {
        if (!a.make) {
          return 1;
        } else if (!b.make) {
          return -1;
        }
        return a.make.localeCompare(b.make);
      });
    } else if (sortMethod == 'notes') {
      implementList.sort((a, b) => {
        if (!a.notes) {
          return 1;
        } else if (!b.notes) {
          return -1;
        }
        return a.notes.localeCompare(b.notes);
      });
    } else if (sortMethod == 'linkedTaskId') {
      implementList.sort((a, b) => {
        const aValue = a[sortMethod];
        const bValue = b[sortMethod];
        const sortResult =
          aValue && bValue ? taskConfigDict[aValue].name.localeCompare(taskConfigDict[bValue].name) : !aValue - !bValue;
        return sortResult;
      });
    } else if (sortMethod == 'rowApplicationType') {
      implementList.sort((a, b) => {
        const aValue = a[sortMethod];
        const bValue = b[sortMethod];
        const sortResult =
          aValue && bValue
            ? rowApplicationTypeMapping[aValue].localeCompare(rowApplicationTypeMapping[bValue])
            : !aValue - !bValue;
        return sortResult;
      });
    } else {
      implementList.sort((a, b) => {
        return a[sortMethod] < b[sortMethod] ? 1 : -1;
      });
    }
    return implementList;
  }

  async function processImplementsData() {
    let implementsArray = [];
    const implementLinkedTaskArray = [];
    const implementSNArray = [];
    const vinFilterOptions = [];
    const makeFilterOptions = [];
    const notesFilterOptions = [];
    let rapLimitedPresence = false;

    const btTagsByImplementSN = {};
    const assetLabelsByImplementSN = {};
    btTags.forEach((btTagDoc) => {
      if (btTagDoc.data.implementSN) {
        btTagsByImplementSN[btTagDoc.data.implementSN] = btTagDoc.data;
      }
    });
    labels.forEach((labelDoc) => {
      if (labelDoc.data.assetType == 'implement' && labelDoc.data.assetId) {
        assetLabelsByImplementSN[labelDoc.data.assetId] = labelDoc.data;
      }
    });

    // Have to do this or get errors with trying to extend an object from state
    Object.keys(implementSNDict).forEach((implementSN) => {
      const implement = {...implementSNDict[implementSN]};

      // Check if implement has qr label and/or bt tag
      implement.qrLabelId = 'Not Assigned';
      implement.btTagId = 'Not Assigned';
      if (typeof assetLabelsByImplementSN[implementSN] !== 'undefined') {
        const label = assetLabelsByImplementSN[implementSN];
        implement.qrLabelId = `${label.id}-${label.databaseLabel}`;
      }
      if (typeof btTagsByImplementSN[implementSN] !== 'undefined') {
        const btTag = btTagsByImplementSN[implementSN];
        implement.btTagId = `${btTag.id}-${btTag.databaseLabel}`;
      }

      implementsArray.push(implement);
      implementSNArray.push({
        text: implement.name,
        value: implementSN,
      });

      if (implement.vin) {
        vinFilterOptions.push(implement.vin);
      }

      if (implement.make) {
        makeFilterOptions.push(implement.make);
      }

      if (implement.notes) {
        notesFilterOptions.push(implement.notes);
      }

      if (
        rowApplicationTypeMapping[implement?.rowApplicationType] &&
        rowApplicationTypeMapping[implement?.rowApplicationType] == 'Limited'
      ) {
        rapLimitedPresence = true;
      }
      if (implement?.linkedTaskId) {
        const taskInFilter = implementLinkedTaskArray.find((task) => {
          return task.value == implement?.linkedTaskId;
        });
        if (!taskInFilter) {
          implementLinkedTaskArray.push({
            text: taskConfigDict[implement?.linkedTaskId]?.name,
            value: implement?.linkedTaskId,
          });
        }
      }
    });

    implementSNArray.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });
    implementLinkedTaskArray.sort((a, b) => {
      const sortResult = a.text && b.text ? a.text.localeCompare(b.text) : !a.value - !b.value;
      return sortResult;
    });
    vinFilterOptions.sort((a, b) => {
      return a.localeCompare(b);
    });
    makeFilterOptions.sort((a, b) => {
      return a.localeCompare(b);
    });
    notesFilterOptions.sort((a, b) => {
      return a.localeCompare(b);
    });
    const newFilterOptions = {
      ...filterOptions,
      implementSN: implementSNArray,
      implementLinkedTask: [...implementLinkedTaskArray, {text: 'None', value: ''}],
      implementVin: vinFilterOptions,
      implementMake: makeFilterOptions,
      implementNotes: notesFilterOptions,
    };
    if (rapLimitedPresence) {
      newFilterOptions.implementRapType = Object.keys(rowApplicationTypeMapping).map((key) => {
        return rowApplicationTypeMapping[key];
      });
    }
    setFilterOptions(newFilterOptions);

    implementsArray = sortData(implementsArray);

    const implementsDict = {};

    implementsArray.forEach((imp) => {
      imp.rowsOrWidthValue =
        imp.rowApplicationType == 2
          ? imp.applicationRows
          : unitsSystem == 'imperial'
          ? parseFloat(unitsDisplayConversion(imp.dimensions.width, 'ft'))
          : imp.dimensions.width;
      implementsDict[imp.serialNumber] = {...imp};
      if (imp.rowsOrWidthValue == undefined) {
        imp.rowsOrWidthValue = '';
      }
      implementsDict[imp.serialNumber]['rowsOrWidthValue'] = imp.rowsOrWidthValue;
    });
    setTableData(implementsArray);

    dispatch(initializeBulkEditImplements(implementsDict));
  }

  async function getTaskConfigsImplementsDataFromApi() {
    dispatch(updateTableLoading({table: 'implements', status: true}));
    dispatch(updateTableLoading({table: 'task', status: true}));
    dispatch(getTaskConfigsData());
    dispatch(getImplementsData());
  }

  const implementCsvColumns = [
    {
      key: 'implementName',
      required: true,
      description: 'Name of the Implement',
    },
    {
      key: 'taskName',
      required: false,
      description: 'Name of Task assigning to the Implement (Task has to be created) ',
    },
    {
      key: 'vin',
      required: false,
      description: 'VIN of the Implement',
    },
    {
      key: 'make',
      required: false,
      description: 'Make of the Implement',
    },
    {
      key: 'notes',
      required: false,
      description: 'Additional Notes',
    },
    {
      key: 'acreCalUnit',
      required: false,
      description: `Acreage Calculation Type, Accepted Values: 'row' or 'width' (${
        unitsSystem == 'imperial' ? 'ft' : 'm'
      })`,
    },
    {
      key: 'acreCalValue',
      required: false,
      description:
        'Acreage Calculation values, Accepted Values: Positive Numbers, Rows has to be in increaments of 0.5',
    },
  ];

  function bulkUploadDataPreview(rowData, index, totalCount) {
    let taskDisp = 'None';
    if (Object.prototype.hasOwnProperty.call(taskConfigDict, rowData.linkedTaskId)) {
      taskDisp =
        taskConfigDict[rowData.linkedTaskId].name +
        (taskConfigDict[rowData.linkedTaskId]?.archived ? ' - Archived' : '');
    }
    return (
      <div className='card mb-2 text-left' key={index}>
        <div className='card-header row mx-0 px-0'>
          <div className='col-8'>
            Name: {rowData.name}{' '}
            {rowData.errors && rowData.errors.length > 0 ? (
              <FontAwesomeIcon className='text-danger' icon='fas fa-warning' />
            ) : rowData.warnings && rowData.warnings.length > 0 ? (
              <FontAwesomeIcon className='text-warning' icon='fas fa-warning' />
            ) : (
              <FontAwesomeIcon className='text-success' icon='fas fa-check-circle' />
            )}
          </div>
          <div className='col-4 text-right'>
            {index + 1}/{totalCount}
          </div>
        </div>
        <div className='row mx-0 px-0'>
          <div className='col-md-6'>Task: {taskDisp}</div>
          <div className='col-md-6'>VIN: {rowData.vin}</div>
          <div className='col-md-6'>Make: {rowData.make}</div>
          <div className='col-md-6'>Notes: {rowData.notes}</div>
          <div className='col-md-6'>Acre Calculation: {rowApplicationTypeMapping[rowData.rowApplicationType]}</div>
          <div className='col-md-6'>
            {rowData.rowApplicationType == 2
              ? `Rows: ${rowData.applicationRows.toFixed(2)} Rows`
              : `Width: ${
                  unitsSystem == 'imperial'
                    ? unitsDisplayConversion(rowData.dimensions.width, 'ft')
                    : rowData.dimensions.width
                } ${unitsSystem == 'imperial' ? 'ft' : 'm'}`}
          </div>
        </div>
        {((rowData.errors && rowData.errors.length > 0) || (rowData.warnings && rowData.warnings.length > 0)) && (
          <div className='card-footer'>
            {rowData.errors.map((errorMsg) => {
              return <div key={errorMsg}>{errorMsg}</div>;
            })}
            {rowData.warnings.map((errorMsg) => {
              return <div key={errorMsg}>{errorMsg}</div>;
            })}
          </div>
        )}
      </div>
    );
  }

  function validateAndMapBulkUploadedData(data, dupCheckObject) {
    const requiredColumns = implementCsvColumns
      .filter((column) => {
        return column.required;
      })
      .map((column) => {
        return column.key;
      });

    const noDupColumns = ['implementName'];

    const defaultDimensions = {
      'width': 0,
      'height': 0,
      'length': 0,
    };

    const defaultImplement = {
      'name': '',
      'serialNumber': 'TBD_new_implements',
      'dimensions': {...defaultDimensions},
      'linkedTaskId': '',
      'archived': false,
      'rowApplicationType': 0,
      'applicationRows': 0,
      'make': '',
      'notes': '',
      'vin': '',
    };

    const tempDoc = {
      ...defaultImplement,
      dimensions: {...defaultDimensions},
      ...(Object.hasOwnProperty.call(data, 'implementName') ? {name: data['implementName']} : {}),
      ...(Object.hasOwnProperty.call(data, 'vin') ? {vin: data['vin']} : {}),
      ...(Object.hasOwnProperty.call(data, 'make') ? {make: data['make']} : {}),
      ...(Object.hasOwnProperty.call(data, 'notes') ? {notes: data['notes']} : {}),
    };

    const errors = [];
    const warnings = [];

    noDupColumns.forEach((col) => {
      if (!Object.keys(dupCheckObject).includes(col)) {
        dupCheckObject[col] = [];
        dupCheckObject[col].push(data[col]);
      } else if (dupCheckObject[col].includes(data[col])) {
        errors.push(`Duplicate ${col}`);
      } else {
        dupCheckObject[col].push(data[col]);
      }
    });

    if (Object.hasOwnProperty.call(data, 'acreCalUnit')) {
      if (data['acreCalUnit'].toLowerCase() == 'row') {
        tempDoc['rowApplicationType'] = 2;
        if (Object.hasOwnProperty.call(data, 'acreCalValue')) {
          try {
            const rowCount = parseFloat(data['acreCalValue']);
            if (isNaN(rowCount)) {
              warnings.push(`Row Count is not a number, Entered value: ${data['acreCalValue']}`);
            } else if (rowCount % 1 != 0 && rowCount != 0.5) {
              warnings.push(
                `Row Count is not an integer or 0.5 please enter a valid number, Entered value: ${data['acreCalValue']}`
              );
            } else if (rowCount < 0) {
              warnings.push(`Row Count cannot be negative, Entered value: ${data['acreCalValue']}`);
            } else {
              tempDoc['applicationRows'] = rowCount;
            }
          } catch {
            warnings.push(`Row Count input is invalid, Entered value: ${data['acreCalValue']}`);
          }
        }
      } else if (data['acreCalUnit'].toLowerCase() == 'width') {
        tempDoc['rowApplicationType'] = 0;
        if (Object.hasOwnProperty.call(data, 'acreCalValue')) {
          try {
            const implementsWidth = parseFloat(data['acreCalValue']);
            if (isNaN(implementsWidth)) {
              warnings.push(`Implements Width is not a number, Entered value: ${data['acreCalValue']}`);
            } else if (implementsWidth < 0) {
              warnings.push(`Implements Width cannot be negative, Entered value: ${data['acreCalValue']}`);
            } else {
              tempDoc['dimensions']['width'] =
                unitsSystem == 'imperial' ? unitsSubmitConversion(implementsWidth, 'ft') : implementsWidth;
            }
          } catch {
            warnings.push(`Implements Width input is invalid, Entered value: ${data['acreCalValue']}`);
          }
        }
      } else {
        warnings.push(`Implements Acre Cal Unit is invalid, Entered unit: ${data['acreCalUnit']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'taskName')) {
      if (Object.hasOwnProperty.call(taskConfigNameToId, data['taskName'].toLowerCase())) {
        tempDoc.linkedTaskId = taskConfigNameToId[data['taskName'].toLowerCase()];
      } else {
        warnings.push(`Task Assigned Not Found, Entered value: ${data['taskName']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'implementName') && implementNamesList.includes(data['implementName'])) {
      errors.push(`Implement with the same name already Exist, Entered value: ${data['implementName']}`);
    }

    for (let i = 0; i < requiredColumns.length; i++) {
      if (!data[requiredColumns[i]] || data[requiredColumns[i]] == '') {
        errors.push(`Column ${requiredColumns[i]} from CSV cannot be empty`);
      }
    }

    tempDoc.errors = errors;
    tempDoc.warnings = warnings;
    return tempDoc;
  }

  async function postBulkImplements(newImplementDocs) {
    const postData = {docsList: newImplementDocs, uploadType: 'implements'};
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(postData),
    };
    const url = '/settings/bulkUpload';
    const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
    const result = await response.json();
    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    }

    return result;
  }

  return (
    <React.Fragment>
      <TabMenuTableWrapper
        menu={
          <Menu
            getTaskConfigsImplementsDataFromApi={getTaskConfigsImplementsDataFromApi}
            handleUpdateEditImplement={handleUpdateEditImplement}
            handleEditModalOpen={handleEditModalOpen}
            handleBulkUploadModalOpen={handleBulkUploadModalOpen}
            handleSetSortMethod={handleSetSortMethod}
            sortMethod={sortMethod}
            handleBulkEditModeToggle={handleBulkEditModeToggle}
            submitBulkEdits={submitBulkEdits}
            processImplementsData={processImplementsData}
          />
        }
        table={
          <ImplementsTable
            type='vehicles'
            tableData={tableData}
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            sortMethod={sortMethod}
            getTaskConfigsImplementsDataFromApi={getTaskConfigsImplementsDataFromApi}
            handleUpdateEditImplement={handleUpdateEditImplement}
            handleEditModalOpen={handleEditModalOpen}
            implementsBulkEditMode={implementsBulkEditMode}
          />
        }
      />
      <ImplementEditModal
        editModalOpen={editModalOpen}
        editImplement={editImplement}
        handleEditModalOpen={handleEditModalOpen}
        getTaskConfigsImplementsDataFromApi={getTaskConfigsImplementsDataFromApi}
      />
      <BulkUploadModal
        entityName={'Implements'}
        modalOpen={bulkUploadModalOpen}
        handleModalOpen={handleBulkUploadModalOpen}
        dataValidationAndMapping={validateAndMapBulkUploadedData}
        dataPreview={bulkUploadDataPreview}
        acceptedColumnHeaders={implementCsvColumns}
        bulkUploadSubmit={postBulkImplements}
        refreshData={getTaskConfigsImplementsDataFromApi}
      />
    </React.Fragment>
  );
}

export {ImplementsSettings};

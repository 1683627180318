import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {getCustomerAndUserInfo, checkAuthentication, setAuth, setInitialized} from '../appSlice';

import {CoverPageCard} from './CoverPageCard';
import {DownStatusIcon} from './DownStatusIcon';

function ProtectedRoute(props) {
  // Declare/import additional hooks
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const manualURLpageRequest = window.location.pathname.toLowerCase();
  const authenticationStatus = useSelector((state) => {
    return state.app.authenticationStatus;
  });

  const customerName = useSelector((state) => {
    return state.app.customerName;
  });

  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const auth = useSelector((state) => {
    return state.app.auth;
  });

  const initialized = useSelector((state) => {
    return state.app.initialized;
  });

  // Component effects
  // On component load, attempt to initialize by checking auth status
  useEffect(async () => {
    await dispatch(checkAuthentication());
    dispatch(setInitialized(true));
    // verify page access when user manually type in URL
    // verifyPageAccess();
  }, []);

  useEffect(async () => {
    if (initialized) {
      const pageNotEnabled = verifyPageAccess();

      if (authenticationStatus.loggedIn) {
        await dispatch(getCustomerAndUserInfo());
      }

      const auth = {
        loggedIn: authenticationStatus.loggedIn,
        pageNotEnabled: pageNotEnabled,
        pageReq: location.pathname,
      };
      dispatch(setAuth(auth));
    }
  }, [initialized]);

  useEffect(async () => {
    if (initialized && auth?.loggedIn) {
      // Refetch Customer and User Info when user navigate to new page/refresh
      await dispatch(getCustomerAndUserInfo());
      await dispatch(checkAuthentication());
    }
  }, [location]);

  function verifyPageAccess() {
    const pageRequested = location.pathname.toLowerCase();
    // Redirect User back to default home page if user 'refresh' on a page that they do not have access to.
    const pageNotEnabled =
      ((pageRequested == '/cabview' || manualURLpageRequest == '/cabview') && !authenticationStatus.cabviewEnabled) ||
      ((pageRequested == '/cropview' ||
        manualURLpageRequest == '/cropview' ||
        pageRequested == '/cropview-export' ||
        manualURLpageRequest == '/cropview-export' ||
        pageRequested == '/cropview-export-map' ||
        manualURLpageRequest == '/cropview-export-map') &&
        !authenticationStatus.cropviewEnabled) ||
      ((pageRequested == '/shopview' || manualURLpageRequest == '/shopview') &&
        !(authenticationStatus.shopviewEnabled || authenticationStatus.inspectionEnabled)) ||
      ((pageRequested == '/inspectionform' || manualURLpageRequest == '/inspectionform') &&
        !authenticationStatus.inspectionEnabled) ||
      ((pageRequested == '/mapview' || manualURLpageRequest == '/mapview') && !authenticationStatus.zoneInfoEnabled) ||
      ((pageRequested == '/statsview' || manualURLpageRequest == '/statsview') &&
        !authenticationStatus.dashboardEnabled) ||
      ((pageRequested == '/organization_settings' || manualURLpageRequest == '/organization_settings') &&
        !authenticationStatus.organization_settings);
    return pageNotEnabled;
  }

  if (auth === null || !initialized) {
    return (
      <CoverPageCard>
        <div style={{height: '150px'}}>
          <img src='/img/Logo_Text_NoSlogan.png' className='d-block mx-auto w-auto img-fluid' />
        </div>
        <div className='loader mx-auto'></div>
      </CoverPageCard>
    );
  } else if (
    !auth.loggedIn ||
    typeof customerName === 'undefined' ||
    typeof userSettings === 'undefined' ||
    typeof userSettings.username === 'undefined'
  ) {
    // If session is not logged in, save the original request link to allow Login component to refirect
    return <Navigate replace to='/login' state={{redirectTo: location.pathname + location.search}} />;
  } else if (auth.pageNotEnabled && location.pathname == auth.pageReq) {
    // If session is logged in, but the permissions is rejected, redirect to '/' route
    return <Navigate replace to='/' />;
  } else {
    // The route is allow access
    return <Outlet />;
  }
}

export default ProtectedRoute;

import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {searchFind, squareButton, generateCsv} from '../../../app/utils';
import {getReportConfigsData} from '../settingsSlice';

import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {Tailselect} from '../../../components/Tailselect';
import {TabMenuTableWrapper} from '../../../components/TabMenuTableWrapper';
import {CustomTablePagination} from '../../../components/CustomTablePagination';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Table, TableHead, TableBody, TableRow, TableCell, Button, CircularProgress} from '@mui/material';
import {ReportEditModal} from './ReportEditModal';

function ReportConfigTab(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => {
    return state.settings.loading;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const customerName = useSelector((state) => {
    return state.app.customerName;
  });
  const reportConfigsDict = useSelector((state) => {
    return state.settings.reportConfigsDict;
  });
  const databaseUsers = useSelector((state) => {
    return state.settings.databaseUsers;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });

  // For the Reports Configuration Visualizer
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [filters, setFilters] = useState({
    reportVisualsName: [],
    reportVisualsReceivers: [],
    reportVisualsReportType: [],
    reportVisualsInterval: [],
    reportVisualsUserFilter: [],
    reportVisualsTaskSelection: [],
  });

  const [filterOptions, setFilterOptions] = useState({
    reportVisualsName: [],
    reportVisualsReceivers: [],
    reportVisualsReportType: [
      {value: 'coverage', text: 'Coverage'},
      {value: 'equipment', text: 'Equipment'},
    ],
    reportVisualsInterval: [
      {value: 'daily', text: 'Daily'},
      {value: 'weekly', text: 'Weekly'},
      {value: 'half', text: '12 Hours'},
    ],
    reportVisualsUserFilter: ['Yes', 'No'],
    reportVisualsTaskSelection: ['all'],
  });

  const [tableData, setTableData] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editReportConfig, setEditReportConfig] = useState(undefined);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  function handleResetEditReportConfig() {
    setEditReportConfig(undefined);
  }

  function handleEditModalOpen() {
    setEditModalOpen(!editModalOpen);
  }

  useEffect(async () => {
    const usernameToEmailDict = {};
    if (databaseUsers) {
      databaseUsers.forEach((user) => {
        usernameToEmailDict[user.username] = user.email;
      });
    }
    const reportConfigurationMetadata = [];
    const emailsList = [];
    const userList = [];
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    Object.keys(reportConfigsDict).forEach((configId) => {
      if (reportConfigsDict[configId].disabled) {
        return;
      }
      // Email List when useDeliveryUserList is off, also includes user's email
      const emailSet = [
        ...new Set([
          ...(!reportConfigsDict[configId]?.useDeliveryUserList == false
            ? reportConfigsDict[configId].delivery.toUsers.map((username) => {
                return usernameToEmailDict[username];
              })
            : reportConfigsDict[configId].delivery.toEmails),
          ...(userSettings.role == 'ic_admin' ? reportConfigsDict[configId].delivery.bccEmails : []),
        ]),
      ];

      const reportUserList =
        !reportConfigsDict[configId]?.useDeliveryUserList == false ? reportConfigsDict[configId].delivery.toUsers : [];
      emailsList.push(...emailSet);
      // User List only when useDeliveryUserList is on
      userList.push(...reportUserList);
      const tempCronSchedule = reportConfigsDict[configId].schedule.split(' ');
      const timeString = `${`0${tempCronSchedule[1].split('/')[0] % 12 || 12}`.slice(
        -2
      )}:${`0${tempCronSchedule[0]}`.slice(-2)}`;
      const ampmString = tempCronSchedule[1] < 12 ? 'AM' : 'PM';
      let intervalString = '';

      if (reportConfigsDict[configId].range == 'half') {
        intervalString = `Every 12 Hours ${timeString} AM/PM`;
      } else if (reportConfigsDict[configId].range == 'weekly') {
        intervalString = `Weekly ${daysOfWeek[tempCronSchedule[4]]} ${timeString} ${ampmString}`;
      } else if (reportConfigsDict[configId].range == 'daily') {
        intervalString = `Daily ${timeString} ${ampmString}`;
      }

      // Have task selection display as all if empty
      // otherwise it should be the task name
      let taskSelectionString = ''; // if something happened to the task config, just display as empty
      const taskSelectionId = reportConfigsDict[configId].filters.taskSelection;
      if (taskSelectionId in taskConfigDict) {
        taskSelectionString = taskConfigDict[taskSelectionId].name;
      } else {
        taskSelectionString = 'All';
      }

      const rowObj = {
        email: emailSet, // email
        reportUserList: reportUserList,
        configId: configId,
        name: reportConfigsDict[configId].name,
        toEmailsCount: reportConfigsDict[configId].delivery.toEmails.length,
        bccEmailsCount: userSettings.role == 'ic_admin' ? reportConfigsDict[configId].delivery.bccEmails.length : 0,
        reportType: reportConfigsDict[configId].reportType, // 'coverage', 'equipment'
        interval: reportConfigsDict[configId].range,
        useDeliveryUserList: !reportConfigsDict[configId]?.useDeliveryUserList == false,
        toUsersCount: reportConfigsDict[configId].delivery.toUsers?.length || 0,
        intervalString: intervalString,
        useUserFilters: reportConfigsDict[configId].filters.useUserFilters ? 'Yes' : 'No', // true, false
        taskSelection: taskSelectionString, // 'all' OR 'task name' in string
      };
      reportConfigurationMetadata.push(rowObj);
    });

    const receiversOptions = [
      ...[...new Set(emailsList)].map((email) => {
        return {value: email, text: email, group: 'Emails'};
      }),
      ...[...new Set(userList)].map((username) => {
        return {value: username, text: username, group: 'Users'};
      }),
    ];

    setFilterOptions({
      ...filterOptions,
      reportVisualsName: reportConfigurationMetadata.map((config) => {
        return config.name;
      }),
      reportVisualsReceivers: receiversOptions,
      reportVisualsTaskSelection: [
        'all',
        ...Object.keys(taskConfigDict).map((taskId) => {
          return taskConfigDict[taskId].name;
        }),
      ],
    });

    const tempTableData = [...reportConfigurationMetadata].filter((rowObj) => {
      return filterRow(rowObj);
    });

    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }
    setTableData(tempTableData);
  }, [customerSettings, reportConfigsDict, searchText, filters, databaseUsers]);

  useEffect(async () => {
    // Get Report Configs on load
    dispatch(getReportConfigsData());
  }, []);

  /*
  Filter the table data based on the current state of the filters
  */
  function filterRow(rowObj) {
    const usernameToEmailDict = {};
    if (databaseUsers) {
      databaseUsers.forEach((user) => {
        usernameToEmailDict[user.username] = user.email;
      });
    }
    const search = searchFind(<div>{JSON.stringify(rowObj)}</div>, searchText.toLowerCase().trim());
    const nameInFilter = filters.reportVisualsName.includes(rowObj.name) || filters.reportVisualsName.length == 0;
    const reportTypeInFilter =
      filters.reportVisualsReportType.includes(rowObj.reportType) || filters.reportVisualsReportType.length == 0;
    const intervalInFilter =
      filters.reportVisualsInterval.includes(rowObj.interval) || filters.reportVisualsInterval.length == 0;
    const useUserFilterInFilter =
      filters.reportVisualsUserFilter.includes(rowObj.useUserFilters) || filters.reportVisualsUserFilter.length == 0;
    const taskSelectionInFilter =
      filters.reportVisualsTaskSelection.includes(rowObj.taskSelection) ||
      filters.reportVisualsTaskSelection.length == 0;

    const receiverInFilter =
      filters.reportVisualsReceivers.some((receiver) => {
        if (!rowObj.useDeliveryUserList == false) {
          // User's Email in Filter OR username in Filter
          return rowObj.reportUserList.includes(receiver) || rowObj.email.includes(receiver);
        } else {
          // Config deliver by Email
          // Filter Only if Config contains an Email in Filter
          return rowObj.email.includes(receiver);
        }
      }) || filters.reportVisualsReceivers.length == 0;

    const configInFilter =
      nameInFilter &&
      receiverInFilter &&
      reportTypeInFilter &&
      intervalInFilter &&
      useUserFilterInFilter &&
      taskSelectionInFilter;

    return configInFilter && search;
  }

  /*
  Reports Configuration Visualizer Components
  */
  function generateReportsConfigurationVisualizer() {
    const access = userSettings.roleViewAccess['reportsManagement'];

    return (
      <React.Fragment>
        {access && (
          <TabMenuTableWrapper
            menu={generateReportsVisualMenu()}
            table={generateReportsVisualTable()}
            pagination={
              <CustomTablePagination
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                page={page}
                setPage={setPage}
              />
            }
          />
        )}
      </React.Fragment>
    );
  }

  function generateReportsVisualMenu() {
    return (
      <React.Fragment>
        <div className='pt-1 pb-0'>
          <h6 className='mt-1 pl-1' style={{color: 'black', fontWeight: 600}}>
            Reports Configuration Table
          </h6>
          <div className='row mx-0 px-0'>
            <Button
              sx={squareButton}
              variant='ic-button'
              color='secondary'
              className='ml-1'
              title='Download CSV'
              onClick={downloadCsv}
            >
              <FontAwesomeIcon icon='fas fa-download' />
            </Button>
            <Button
              sx={squareButton}
              variant='ic-button'
              color='success'
              className='ml-1'
              title='Add Report Config'
              onClick={() => {
                handleEditModalOpen();
                setEditReportConfig(undefined);
              }}
            >
              <FontAwesomeIcon icon='fas fa-plus' />
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  function generateReportsVisualTable() {
    let colorFlip = false;
    return (
      <React.Fragment>
        <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Report Name'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-name-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsName'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-name-select'
                    name={'reportVisualsName'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsName']}
                    options={filterOptions['reportVisualsName']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Receivers'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-receiver-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsReceivers'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-receiver-select'
                    name={'reportVisualsReceivers'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsReceivers']}
                    options={filterOptions['reportVisualsReceivers']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Report Type'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-reportType-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsReportType'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-reportType-select'
                    name={'reportVisualsReportType'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsReportType']}
                    options={filterOptions['reportVisualsReportType']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Schedule'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-interval-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsInterval'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-interval-select'
                    name={'reportVisualsInterval'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsInterval']}
                    options={filterOptions['reportVisualsInterval']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Apply User Filter'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-user-filter-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsUserFilter'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-user-filter-select'
                    name={'reportVisualsUserFilter'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsUserFilter']}
                    options={filterOptions['reportVisualsUserFilter']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Task Selection'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#reports-config-visual-tasks-selection-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['reportVisualsTaskSelection'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='reports-config-visual-tasks-selection-select'
                    name={'reportVisualsTaskSelection'}
                    multiple={true}
                    search={true}
                    value={filters['reportVisualsTaskSelection']}
                    options={filterOptions['reportVisualsTaskSelection']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={100}>
                  <CircularProgress className='mt-4 mx-auto d-block' size={80} />
                </TableCell>
              </TableRow>
            ) : (
              <React.Fragment>
                {(rowsPerPage > 0
                  ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : tableData
                ).map((rowObj) => {
                  colorFlip = !colorFlip;
                  const rowKey = rowObj.configId;
                  return (
                    <TableRow key={rowKey} sx={{backgroundColor: colorFlip ? 'rgba(0, 0, 0, 0.05)' : ''}}>
                      <TableCell>{rowObj.name}</TableCell>
                      <TableCell>
                        {rowObj.useDeliveryUserList ? `${rowObj.toUsersCount} Users` : `${rowObj.toEmailsCount} Emails`}
                        {userSettings.role == 'ic_admin' && (
                          <React.Fragment>
                            <br></br>Bcc: {rowObj.bccEmailsCount}
                          </React.Fragment>
                        )}
                      </TableCell>
                      <TableCell className='text-capitalize'>{rowObj.reportType}</TableCell>
                      <TableCell>{rowObj.intervalString}</TableCell>
                      <TableCell>{rowObj.useUserFilters}</TableCell>
                      <TableCell>{rowObj.taskSelection}</TableCell>
                      <TableCell>
                        <button
                          className='btn btn-light'
                          style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                          onClick={() => {
                            setEditModalOpen(true);
                            setEditReportConfig(reportConfigsDict[rowObj.configId]);
                          }}
                        >
                          <FontAwesomeIcon icon='fa fa-edit' />
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  function downloadCsv() {
    // Compile table data
    const csvList = [];
    tableData.forEach((configObj) => {
      const row = [
        `\"${configObj.name}\"`,
        configObj.useDeliveryUserList ? `\"${configObj.toUsersCount}\"` : `\"${configObj.toEmailsCount}\"`,
        `\"${configObj.bccEmailsCount}\"`,
        configObj.useDeliveryUserList ? `users` : `emails`,
        `\"${configObj.reportType}\"`,
        `\"${configObj.intervalString}\"`,
        `\"${configObj.useUserFilters}\"`,
        `\"${configObj.taskSelection}\"`,
        configObj.useDeliveryUserList ? `\"${configObj.reportUserList}\"` : `\"${configObj.email}\"`,
      ];
      csvList.push(row);
    });

    // Configure headers
    const filename = `reportsConfigurations_${customerName}`;
    const csvHeaders = [
      'report name',
      'report receivers count',
      'report bcc emails count',
      'report receivers',
      'report type',
      'schedule',
      'apply user filter',
      'task selection',
      'report receivers list',
    ];

    generateCsv(filename, csvHeaders, csvList);
  }

  /**
   * Returns generated page
   */

  return (
    <div className='tab-wrapper'>
      <ReportEditModal
        editModalOpen={editModalOpen}
        editReportConfig={editReportConfig}
        handleEditModalOpen={handleEditModalOpen}
        handleResetEditReportConfig={handleResetEditReportConfig}
        getReportConfigsData={getReportConfigsData}
      />
      {loading ? (
        <CircularProgress className='mt-2 mx-auto d-block' size={80} />
      ) : (
        <React.Fragment>
          <div className='tab-wrapper overflow-auto'>
            {/* Reports Config Visualizer*/}
            {generateReportsConfigurationVisualizer()}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export {ReportConfigTab};

import React from 'react';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalWideColumnSection,
  ModalBody,
} from '../../../components/Modal';

function ScheduleDataModal(props) {
  const initialValues = useSelector((state) => {
    return state.scheduleModal;
  });

  return createPortal(
    <ModalFramework id='shopview-schedule-data-modal'>
      <ModalHeader title='Task Details'>
        <span className='h5'>{initialValues.vehicle}</span>
        <div className='small'>{initialValues.task}</div>
      </ModalHeader>
      <ModalBody>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Interval</div>
            <div className='small pl-2' dangerouslySetInnerHTML={{__html: initialValues.intervalString}} />
          </ModalWideColumnSection>
        </ModalRowSection>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Notes</div>
            <div className='small pl-2' dangerouslySetInnerHTML={{__html: initialValues.notes}} />
          </ModalWideColumnSection>
        </ModalRowSection>
      </ModalBody>
      <ModalFooter onSubmit={editModal} submitText='Edit Task' />
    </ModalFramework>,
    document.getElementById('app')
  );

  function editModal() {
    // Close data modal
    $('#shopview-schedule-data-modal').modal('hide');
    // Open edit task modal with a timeout to allow animation to complete
    setTimeout(function () {
      $('#shopview-edit-task-modal').modal('show');
    }, 350);
  }
}

export {ScheduleDataModal};

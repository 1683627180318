import React, {useState, useEffect} from 'react';
import {Tailselect} from '../../../components/Tailselect';
import BarChart from '../../../components/BarChart';
import DoughnutChart from '../../../components/DoughnutChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties} from '../../../app/utils';
import {genPieDataServices, genServiceHealth} from '../dashboardDataProcessing';
import {KpiTooltip, ScorecardSelectHolder, KpiTable} from '../dashboardUtils';
import {CircularProgress, ToggleButtonGroup, ToggleButton, FormControlLabel, Checkbox} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import {sortVehicleNamesHelper} from '../../../app/utils';
ChartJS.register(annotationPlugin);

function KpiServiceCompletion(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const servicesData = useSelector((state) => {
    return state.dashboard.servicesData;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });

  const [vehicleSNFilterOptions, setVehicleSNFilterOptions] = useState([]);

  const [serviceIntervalSelectedUnit, setServiceIntervalSelectedUnit] = useState({
    engHr: 'Engine Hours',
    odo: 'Km',
    time: 'Days',
  });

  const [servicesBarData, setServicesBarData] = useState(null);
  const [serviceIntervalSelected, setServiceIntervalSelected] = useState('completion');
  const [serviceHealthTypeAvailableOptions, setServiceHealthTypeAvailableOptions] = useState([]);
  const [serviceHealthFilters, setServiceHealthFilters] = useState({
    serviceHealthVehicleSNs: [],
    serviceHealthVehicleType: [],
    serviceHealthGroupByMachineType: true,
    serviceHealthVehicleCount: '25',
    serviceHealthVehicleSort: 'Desc',
  });

  const [servicesPieData, setServicesPieData] = useState(null);
  const [totalServices, setTotalServices] = useState(0);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const opts = Object.keys(vehicleSNDict)
      .sort((vehicleSNa, vehicleSNb) => {
        const aName = vehicleSNDict[vehicleSNa].name;
        const bName = vehicleSNDict[vehicleSNb].name;
        return sortVehicleNamesHelper(aName, bName);
      })
      .map((vehSN) => {
        return {value: vehSN, text: vehicleSNDict[vehSN].name};
      });
    setVehicleSNFilterOptions(opts);
  }, [vehicleSNDict]);

  function handleFilters(value) {
    setServiceHealthFilters((values) => {
      return {...values, 'serviceHealthVehicleSNs': value};
    });
  }

  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setServiceIntervalSelectedUnit((values) => {
        return {...values, odo: 'Miles'};
      });
    }
  }, [unitsSystem]);

  useEffect(() => {
    if (props.pageStates?.serviceIntervalSelected) {
      setServiceIntervalSelected(props.pageStates?.serviceIntervalSelected);
    } else {
      setServiceIntervalSelected('completion');
    }
    if (props?.pageFilters) {
      setServiceHealthFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
  }, [props.pageStates, props.pageFilters]);

  useEffect(() => {
    if (servicesData != null) {
      generateData();

      // Check which Service Health has Data.
      const vehicleServices = servicesData.vehicleServices;
      const serviceHealthIntervalOptions = ['odo', 'engHr', 'time'];
      const serviceHealthAvailableOptions = [];
      serviceHealthIntervalOptions.forEach((serviceIntervalType) => {
        const vehicleKeys = Object.keys(vehicleServices);
        for (const vehicleSN of vehicleKeys) {
          const vehicleHasSelectedService =
            vehicleServices[vehicleSN].serviceStats[serviceIntervalType].overBy.length > 0;
          if (vehicleHasSelectedService) {
            serviceHealthAvailableOptions.push(serviceIntervalType);
            break;
          }
        }
      });
      setServiceHealthTypeAvailableOptions(serviceHealthAvailableOptions);
    }
  }, [operationsData, servicesData, serviceHealthFilters]);

  useEffect(() => {
    if (servicesData != null && vehicleSNDict && serviceIntervalSelected != 'completion') {
      generateServiceHealthData();
    }
  }, [servicesData, serviceHealthFilters, vehicleSNDict, serviceIntervalSelected]);

  useEffect(() => {}, [servicesBarData]);

  function handleVehiclesFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }

    const value = select.multiple ? selected : selected[0];
    const name = select.name;
    setServiceHealthFilters((values) => {
      return {...values, [name]: value};
    });
  }

  function handleChecked(event) {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setServiceHealthFilters((values) => {
      return {...values, [name]: value};
    });
  }

  function generateData() {
    if (servicesData) {
      let filteredVehicleServices = Object.keys(servicesData.vehicleServices);
      let onTimeServices = servicesData.onTimeServices;
      let lateServices = servicesData.lateServices;
      // Generate data
      if (
        serviceHealthFilters &&
        serviceHealthFilters.hasOwnProperty('serviceHealthVehicleSNs') &&
        serviceHealthFilters['serviceHealthVehicleSNs'].length > 0
      ) {
        onTimeServices = 0;
        lateServices = 0;

        filteredVehicleServices = filteredVehicleServices.filter((vehSN) => {
          return serviceHealthFilters['serviceHealthVehicleSNs'].includes(vehSN);
        });

        filteredVehicleServices.forEach((vehSN) => {
          const curVeh = servicesData.vehicleServices[vehSN];
          onTimeServices += curVeh['onTimeServices'];
          lateServices += curVeh['lateServices'];
        });
      }

      const servicesPieDataTemp = genPieDataServices(onTimeServices, lateServices);
      if (lateServices + onTimeServices > 0) {
        setServicesPieData(servicesPieDataTemp);
      } else {
        setServicesPieData(null);
      }

      const totalServicesTemp = lateServices + onTimeServices;
      setTotalServices(totalServicesTemp);

      // Generate table data
      const vehicleServicesTableData = filteredVehicleServices.map((vehicle) => {
        return servicesData.vehicleServices[vehicle];
      });
      setTableData(vehicleServicesTableData);
    }
  }

  function generateServiceHealthData() {
    if (servicesData) {
      // Generate data
      const servicesHealthBarData = genServiceHealth(
        servicesData.vehicleServices,
        serviceHealthFilters,
        vehicleSNDict,
        serviceIntervalSelected,
        serviceIntervalSelectedUnit
      );
      setServicesBarData(servicesHealthBarData);
    }
  }

  function serviceCompletionGraph() {
    return (
      <div style={{height: '50vh', maxHeight: '400px'}}>
        {/* Chart */}
        <DoughnutChart
          data={servicesPieData}
          options={{
            plugins: {
              datalabels: {
                color: '#fff', // Label text color
                font: {
                  size: 16,
                  weight: 'bold',
                },
                formatter: (value, context) => {
                  if (value == 0 || totalServices == 0) {
                    return '';
                  }
                  return Math.round((value / totalServices) * 100) + '%';
                },
              },
              title: {
                display: true,
                text: servicesPieData.datasets[0].label,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  function serviceHealthBarGraph() {
    return (
      <div style={{height: '60vh', minHeight: '500px'}}>
        {/* Chart */}
        <BarChart
          data={servicesBarData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              annotation: {
                annotations: {
                  // Draw a Line to represent Target
                  line1: {
                    type: 'line',
                    scaleID: 'y',
                    value: 0,
                    endValue: 0,
                    borderColor: customChartProperties.colorRed,
                    borderWidth: 2,
                    display: true,
                  },
                },
              },
              title: {
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
                text: `Average Service Late/Early (${serviceIntervalSelectedUnit[serviceIntervalSelected]})`,
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  filter: function (legendItem, data) {
                    return legendItem.text != 'Target' || 100;
                  },
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              y: {
                title: {
                  text: `${serviceIntervalSelectedUnit[serviceIntervalSelected]}`,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
              TargetAxis: {
                title: {
                  text: '',
                  display: false,
                },
                display: false,
              },
              x: {
                stacked: true,
                ticks: {
                  autoSkip: servicesBarData.labels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 90,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: serviceHealthFilters.serviceHealthGroupByMachineType ? 'Machine Type' : 'Vehicle Name',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
          plugins={[
            {
              id: 'customPlugin',
              beforeDraw: function (chart) {
                chart.config.data.datasets.forEach((dataset, i) => {
                  if (dataset.legendBackgroundColor) {
                    chart.legend.legendItems[i].fillStyle = dataset.legendBackgroundColor;
                    chart.legend.legendItems[i].strokeStyle = dataset.legendBorderColor
                      ? dataset.legendBorderColor
                      : dataset.legendBackgroundColor;
                  }
                });
              },
            },
          ]}
        />
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }
  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiServiceCompletion' />
        </div>
        {serviceIntervalSelected == 'completion' ? (
          !servicesPieData ? (
            <React.Fragment>
              <div className='col-lg-6 flex-col text-center px-0 align-items-center mt-5 mb-auto pb-3'>
                {' '}
                No data available
              </div>
              <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
                <KpiTable
                  pageStates={props.pageStates}
                  tableData={tableData}
                  type={'services'}
                  filters={serviceHealthFilters}
                  filterOptions={{'serviceHealthVehicleSNs': vehicleSNFilterOptions}}
                  handleFilters={handleFilters}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
                {serviceCompletionGraph()}
              </div>
              <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
                <KpiTable
                  pageStates={props.pageStates}
                  tableData={tableData}
                  type={'services'}
                  filters={serviceHealthFilters}
                  filterOptions={{'serviceHealthVehicleSNs': vehicleSNFilterOptions}}
                  handleFilters={handleFilters}
                />
              </div>
            </React.Fragment>
          )
        ) : !servicesBarData ? (
          <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
        ) : (
          <React.Fragment>
            <div className='col-lg-8 flex-col text-center px-0 align-items-center mb-auto pb-5'>
              {serviceHealthBarGraph()}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return (
    <div className='row d-flex mx-0 px-0 justify-content-center align-items-center'>
      {/* Tooltip */}
      <div className='col-6 flex-col ml-n1'>
        <KpiTooltip selectedSubset='kpiServiceCompletion' />
      </div>
      <div className='col-6 px-0'>
        <ScorecardSelectHolder
          autofillPageName={'Machine Service Completion'}
          pageFilters={serviceHealthFilters}
          pageStates={{
            serviceIntervalSelected: serviceIntervalSelected,
          }}
        />
      </div>
      <div className='col-12 flex-col ml-n1'>
        <label>Service Interval Type: </label>
        <ToggleButtonGroup
          color='primary'
          value={serviceIntervalSelected}
          exclusive
          onChange={(e) => {
            const selectedType = e.target.value;
            setServiceIntervalSelected(selectedType);
          }}
          name='serviceHealthServiceType'
          aria-label='serviceHealthServiceType'
        >
          <ToggleButton value='completion' style={{textTransform: 'none'}}>
            Completion
          </ToggleButton>
          {serviceHealthTypeAvailableOptions.includes('engHr') && (
            <ToggleButton value='engHr' style={{textTransform: 'none'}}>
              Engine Hour
            </ToggleButton>
          )}
          {serviceHealthTypeAvailableOptions.includes('odo') && (
            <ToggleButton value='odo' style={{textTransform: 'none'}}>
              Odometer
            </ToggleButton>
          )}
          {serviceHealthTypeAvailableOptions.includes('time') && (
            <ToggleButton value='time' style={{textTransform: 'none'}}>
              Time
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </div>
      {serviceIntervalSelected == 'completion' ? (
        !servicesPieData ? (
          <React.Fragment>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mt-5 mb-auto pb-3'>
              {' '}
              No data available
            </div>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              <KpiTable
                pageStates={props.pageStates}
                tableData={tableData}
                type={'services'}
                filters={serviceHealthFilters}
                filterOptions={{'serviceHealthVehicleSNs': vehicleSNFilterOptions}}
                handleFilters={handleFilters}
              />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              {serviceCompletionGraph()}
            </div>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              <KpiTable
                pageStates={props.pageStates}
                tableData={tableData}
                type={'services'}
                filters={serviceHealthFilters}
                filterOptions={{'serviceHealthVehicleSNs': vehicleSNFilterOptions}}
                handleFilters={handleFilters}
              />
            </div>
          </React.Fragment>
        )
      ) : !servicesBarData ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          <div className='col-lg-8 flex-col text-center px-0 align-items-center mb-auto pb-5'>
            {serviceHealthBarGraph()}
          </div>
          <div className='col-lg-4 flex-col text-center px-0 align-items-center my-auto pb-3'>
            <div className='text-centeralign-items-center my-auto pb-3'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={serviceHealthFilters.serviceHealthGroupByMachineType}
                    name='serviceHealthGroupByMachineType'
                    onChange={handleChecked}
                  />
                }
                label='Group by Machine Type'
              />
              <Tailselect
                id='dashboard-vehicles-serviceHealthVehicleSNs-filter'
                name='serviceHealthVehicleSNs'
                multiple={true}
                search={true}
                value={serviceHealthFilters.serviceHealthVehicleSNs}
                options={vehicleSNFilterOptions}
                onChange={handleVehiclesFilters}
                placeholder='Filter for Vehicles'
              />
              <Tailselect
                id='dashboard-vehicles-serviceHealthVehicleType-filter'
                name='serviceHealthVehicleType'
                multiple={true}
                search={true}
                value={serviceHealthFilters.serviceHealthVehicleType}
                options={filterOptions.vehicles.vehicleType}
                onChange={handleVehiclesFilters}
                placeholder='Filter for Vehicle Type'
              />
              <Tailselect
                id='dashboard-vehicles-serviceHealthVehicleCount-filter'
                name='serviceHealthVehicleCount'
                multiple={false}
                value={serviceHealthFilters.serviceHealthVehicleCount}
                options={filterOptions.vehicles.vehicleCount}
                onChange={handleVehiclesFilters}
                placeholder='Number Of Vehicles To Show'
              />
              <Tailselect
                id='dashboard-vehicles-serviceHealthVehicleSort-filter'
                name='serviceHealthVehicleSort'
                multiple={false}
                value={serviceHealthFilters.serviceHealthVehicleSort}
                options={filterOptions.vehicles.serviceHealthVehicleSort}
                onChange={handleVehiclesFilters}
                placeholder='Sorting method'
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export {KpiServiceCompletion};

import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate, Navigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {sendGAPageview} from '../app/utils';

import {CoverPageCard} from '../components/CoverPageCard';
import {DownStatusIcon} from '../components/DownStatusIcon';
import {checkAuthentication} from '../appSlice';

function Login() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const authenticationStatus = useSelector((state) => {
    return state.app.authenticationStatus;
  });

  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  });
  const [status, setStatus] = useState('');

  useEffect(async () => {
    document.title = 'IntelliCulture | Login';
    sendGAPageview(document.title, true);

    await dispatch(checkAuthentication());
  }, []);

  useEffect(async () => {
    // Redirect if user already logged in
    if (!authenticationStatus.loggedIn) {
      dispatch({type: 'RESET'}); // Need to reset the states to clear current cache
      return null;
    } else {
      return <Navigate replace to='/' />;
    }
  }, [authenticationStatus]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => {
      return {...values, [name]: value};
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setStatus('submitting');
    const postData = {
      username: inputs.username,
      password: inputs.password,
    };
    const response = await fetch('/auth', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(postData),
      cache: 'no-store',
    });
    const result = await response.json();
    if (typeof result.success != 'undefined' && result.success === true) {
      if (result.redirectTo) {
        // If there is a redirectTo available from the back end we will consume it
        window.location.href = result.redirectTo;
      } else if (location.state) {
        // If there is a redirectTo specified from the front end attempt to reach the site
        navigate(location.state.redirectTo);
      } else {
        // If we are not specifieing a url accessed redirect,
        // then go to the base route which should handle the reduced loading home page
        navigate('/');
      }
    } else {
      setStatus('failure');
    }
  }

  return (
    <CoverPageCard>
      <div className='text-center'>
        <h1 className='h4 text-gray-900 mb-4'>
          Welcome Back to IntelliCulture!
          <span>
            <DownStatusIcon login={true} small={true} />
          </span>
        </h1>
      </div>
      <hr />
      {/* Login needs a auth action and a post method */}
      <form className='user' onSubmit={handleSubmit}>
        {status == 'failure' && (
          <p className='text-center' style={{color: '#FF0004'}}>
            Wrong username or password
          </p>
        )}
        <div className='form-group'>
          <input
            type='text'
            name='username'
            id='loginUsername'
            className='form-control form-control-user'
            placeholder='Enter Username...'
            value={inputs.username}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            name='password'
            id='loginPassword'
            className='form-control form-control-user'
            placeholder='Password'
            value={inputs.password}
            onChange={handleChange}
            required
          />
        </div>
        <input type='submit' className='btn btn-primary btn-user btn-block' disabled={status == 'submitting'} />
      </form>
      <hr />
      <div className='text-center'>
        <button
          type='button'
          className='btn border bg-white small'
          onClick={() => {
            return navigate('/forgot_password');
          }}
        >
          Forgot Password?
        </button>
      </div>
    </CoverPageCard>
  );
}

export {Login};

import {createSlice} from '@reduxjs/toolkit';

const scheduleModalSlice = createSlice({
  name: 'scheduleModal',
  initialState: {
    vehicle: '',
    vehicleSN: '',
    task: '',
    recurring: true,
    timeInterval: '',
    timeIntervalUnits: '',
    engineHoursInterval: '',
    odometerInterval: '',
    intervalString: '',
    notes: '',
    averageLaborHours: '',
    averageLaborCost: '',
    averagePartsCost: '',
    serviceTask: {},
  },
  reducers: {
    setInitialValues(state, action) {
      const taskData = action.payload;
      state.vehicle = taskData.vehicle;
      state.vehicleSN = taskData.vehicleSN;
      state.task = taskData.task;
      state.recurring = taskData.serviceTask.recurring;
      state.timeInterval = taskData.serviceTask.time_interval;
      state.timeIntervalUnits = taskData.serviceTask.time_units;
      state.engineHoursInterval = taskData.serviceTask.engine_hours_interval;
      state.odometerInterval = taskData.serviceTask.odometer_interval;
      state.intervalString = taskData.intervalString;
      state.notes = taskData.notes;
      state.serviceTask = taskData.serviceTask;
      state.currOdometer = taskData.currOdometer;
      state.currEngineHours = taskData.currEngineHours;
      state.averageLaborHours = taskData.averageLaborHours || '';
      state.averageLaborCost = taskData.averageLaborCost || '';
      state.averagePartsCost = taskData.averagePartsCost || '';
      state.machineType = taskData.machineType;
    },
  },
});

export const {setInitialValues} = scheduleModalSlice.actions;
export default scheduleModalSlice.reducer;

import React, {useState} from 'react';
import {Autocomplete, ClickAwayListener, Checkbox} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

function IntelliSelect({
  name,
  open,
  id,
  value,
  handleChangeParent,
  options,
  multiple,
  handleOpenChange,
  placeholder = 'Type in to search...',
}) {
  const [hoverAll, setHoverAll] = useState(false);
  const [hoverNone, setHoverNone] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };

  const handleClickAway = (event) => {
    if (event.target.id.includes(id)) {
      return;
    }
    handleOpenChange(false);
  };

  function handleAllButtonClicked() {
    handleChange(null, null, 'All', null);
  }
  function handleNoneButtonClicked() {
    handleChange(null, null, 'None', null);
  }

  function handleChange(e, value, reason, details) {
    handleChangeParent(reason, details, name, inputValue);
  }

  if (open) {
    return (
      <React.Fragment>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Autocomplete
            fullWidth
            multiple={multiple}
            disableCloseOnSelect
            id={id}
            options={options}
            getOptionLabel={(option) => {
              if (typeof option == 'object') {
                return option.text;
              } else {
                const optionObj = options.find((opt) => {
                  return opt.value == option;
                });
                if (optionObj) {
                  return optionObj.text;
                } else {
                  return '';
                }
              }
            }}
            value={value}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            open={open}
            onChange={handleChange}
            filterSelectedOptions
            renderInput={(params) => {
              return (
                <div
                  placeholder={placeholder}
                  style={{fontSize: '5', position: 'absolute', backgroundColor: 'white', marginTop: '8px'}}
                  ref={params.InputProps.ref}
                >
                  <input style={{margin: '10px', width: '250px', zIndex: 9999}} type='text' {...params.inputProps} />
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <button
                      onClick={() => {
                        handleAllButtonClicked();
                      }}
                      onMouseOut={() => {
                        setHoverAll(false);
                      }}
                      onMouseOver={() => {
                        setHoverAll(true);
                      }}
                      style={{
                        textShadow: 'none',
                        margin: '0 2px',
                        padding: '2px 6px',
                        lineHeight: '14px',
                        fontSize: '10px',
                        borderWidth: '1px',
                        borderRadius: '3px',
                        color: !hoverAll ? '#aaa' : '#62c462',
                        backgroundColor: 'transparent',
                        borderColor: !hoverAll ? '#ccc' : '#62c462',
                      }}
                    >
                      All
                    </button>

                    <button
                      onClick={() => {
                        handleNoneButtonClicked();
                      }}
                      onMouseOut={() => {
                        setHoverNone(false);
                      }}
                      onMouseOver={() => {
                        setHoverNone(true);
                      }}
                      style={{
                        textShadow: 'none',
                        margin: '0 2px',
                        padding: '2px 6px',
                        lineHeight: '14px',
                        fontSize: '10px',
                        borderWidth: '1px',
                        borderRadius: '3px',
                        color: !hoverNone ? '#aaa' : '#ee5f5b',
                        backgroundColor: 'transparent',
                        borderColor: !hoverNone ? '#ccc' : '#ee5f5b',
                      }}
                    >
                      None
                    </button>
                  </div>
                </div>
              );
            }}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Checkbox
                    id={id}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{marginRight: 8}}
                    checked={value.includes(option.value)}
                  />
                  {option.text}
                </li>
              );
            }}
          />
        </ClickAwayListener>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
}

export {IntelliSelect};

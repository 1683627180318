import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import chardet from 'chardet';

function FileDropZone(props) {
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const focusedStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  function onDrop(acceptedFiles) {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => {
        console.log('file reading was aborted');
      };
      reader.onerror = () => {
        console.log('file reading has failed');
      };
      reader.onload = () => {
        const fileEncoding = chardet.detect(new Uint8Array(reader.result));        
        const enc = new TextDecoder(fileEncoding);
        const fileString = enc.decode(reader.result);
        
        if (acceptedFiles && acceptedFiles.length == 1) {
          const data = csvToArray(fileString);
          const headers = data[0].map((value) => {
            return value.trim();
          });
          data.shift();
          const transformedData = [];
          data.forEach((row) => {
            const rowData = {};
            let notEmpty = false;
            for (let i = 0; i < headers.length; i++) {
              const headerColumnExist = headers[i] && headers[i] != '' && row[i] && row[i] != '';
              const headerAccepted = props.acceptedColumns.includes(headers[i]);
              if (headerColumnExist && headerAccepted) {
                // Only add data to object if header and value exist
                rowData[headers[i]] = row[i].trim();
                notEmpty = true;
              }
            }
            if (notEmpty) {
              transformedData.push(rowData);
            }
          });
          props.setUploadedData(transformedData);
          props.setUploadedFile(acceptedFiles[0]);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }

  const {acceptedFiles, fileRejections, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} =
    useDropzone({
      accept: {
        'text/csv': ['.csv'],
      },
      maxFiles: 1,
      onDrop,
    });

  const style = useMemo(() => {
    return {
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    };
  }, [isFocused, isDragAccept, isDragReject]);

  function csvToArray(strData, strDelimiter) {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = strDelimiter || ',';

    // Create a regular expression to parse the CSV values.
    const objPattern = new RegExp(
      // Delimiters.
      '(\\' +
        strDelimiter +
        '|\\r?\\n|\\r|^)' +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' +
        strDelimiter +
        '\\r\\n]*))',
      'gi'
    );

    // Create an array to hold our data. Give the array
    // a default empty first row.
    const arrData = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    let arrMatches = null;

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while ((arrMatches = objPattern.exec(strData))) {
      // Get the delimiter that was found.
      const strMatchedDelimiter = arrMatches[1];

      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        // Since we have reached a new row of data,
        // add an empty row to our data array.
        arrData.push([]);
      }

      let strMatchedValue;

      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      if (arrMatches[2]) {
        // We found a quoted value. When we capture
        // this value, unescape any double quotes.
        strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3];
      }

      // Now that we have our value string, let's add
      // it to the data array.
      arrData[arrData.length - 1].push(strMatchedValue);
    }

    // Return the parsed data.
    return arrData;
  }

  const acceptedFileItems = acceptedFiles.map((file) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    );
  });

  const fileRejectionItems = fileRejections.map(({file, errors}) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => {
            return <li key={e.code}>{e.message}</li>;
          })}
        </ul>
      </li>
    );
  });

  return (
    <div {...getRootProps({style})}>
      <input {...getInputProps()} />
      <div>
        <FontAwesomeIcon icon='fas fa-upload' className='fa-2x' />
        <p style={{userSelect: 'none'}}>{`Drag n' drop file here or click to select a CSV file`}</p>
      </div>
      <aside>
        {fileRejectionItems.length > 0 && (
          <div>
            <h4>Rejected file</h4>
            <ul>{fileRejectionItems}</ul>
          </div>
        )}
      </aside>
    </div>
  );
}

export {FileDropZone};

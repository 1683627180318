import {createSlice} from '@reduxjs/toolkit';

const upcomingModalSlice = createSlice({
  name: 'upcomingModal',
  initialState: {
    vehicle: '',
    machineType: '',
    task: '',
    overdue: false,
    upcoming: false,
    inspectionFailure: false,
    intervalString: '',
    dueString: '',
    noHistory: false,
    lastCompletedDate: '',
    lastCompletedTimeDiff: '',
    reviewedBy: '',
    lastCompletedOdometer: '',
    lastCompletedEngineHours: '',
    serviceTask: {},
  },
  reducers: {
    setInitialValues(state, action) {
      const taskData = action.payload;
      state.vehicle = taskData.vehicle;
      state.machineType = taskData.machineType;
      state.task = taskData.task;
      state.overdue = taskData.overdue;
      state.upcoming = taskData.upcoming;
      state.inspectionFailure = taskData.inspectionFailure;
      state.intervalString = taskData.intervalString;
      state.dueString = taskData.dueString;
      state.noHistory = taskData.noHistory;
      state.lastCompletedDate = taskData.lastCompletedDate;
      state.lastCompletedTimeDiff = taskData.lastCompletedTimeDiff;
      state.reviewedBy = taskData.reviewedBy;
      state.lastCompletedOdometer = taskData.lastCompletedOdometer;
      state.lastCompletedEngineHours = taskData.lastCompletedEngineHours;
      state.currentOdometer = taskData.currentOdometer;
      state.currentEngineHours = taskData.currentEngineHours;
      state.serviceTask = taskData.serviceTask;
    },
  },
});

export const {setInitialValues} = upcomingModalSlice.actions;
export default upcomingModalSlice.reducer;

import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CoverPageCard} from '../components/CoverPageCard';
import {sendGAPageview, fetchPostAuthSafe} from '../app/utils';
import {useSelector} from 'react-redux';

function DeleteAccount() {
  const navigate = useNavigate();
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const [inputs, setInputs] = useState({
    email: '',
  });
  const [warnings, setWarnings] = useState({});
  const [status, setStatus] = useState('');

  useEffect(() => {
    document.title = 'IntelliCulture | Delete Account';
    sendGAPageview(document.title);
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    if (!warnings.emailInvalid) {
      setStatus('submitting');

      const postData = {
        archiveUser: true,
      };
      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(postData),
      };
      const response = await fetchPostAuthSafe(
        '/deleteAccountSubmit',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const result = await response.json();

      if (result.errorMsg) {
        navigate('/error', {state: {errorMsg: result.errorMsg}});
      } else {
        setStatus('success');
        setTimeout(() => {
          window.location.href = '/logout';
        }, 2000);
      }
    }
  }

  return (
    <CoverPageCard>
      <div className='text-center'>
        <a href='https://www.intelliculture.ca/' target='_blank' className='card-logo' rel='noreferrer'>
          <img src='/img/Logo_Text_NoSlogan.png' alt='Logo' border='0' width='256' className='card-logo-img' />
        </a>
        <h1 id='heading' className='h4 text-gray-900 mb-4'>
          {status == 'success' ? 'Request Sent!' : 'Delete Account'}
        </h1>
        <h4>{status == 'success' ? '' : 'Please confirm that you would like to delete your account.'}</h4>
      </div>
      {status != 'success' && (
        <React.Fragment>
          <hr />
          {/* Login needs a auth action and a post method */}
          <form className='user' onSubmit={handleSubmit}>
            <input type='submit' className='btn btn-primary btn-block' disabled={status == 'submitting'} />
          </form>
          <hr />
          <div className='text-center'>
            <button
              type='button'
              className='btn border bg-white small'
              onClick={() => {
                return navigate('/personal_settings');
              }}
            >
              Cancel
            </button>
          </div>
        </React.Fragment>
      )}
    </CoverPageCard>
  );
}

export {DeleteAccount};

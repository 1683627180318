import React from 'react';
import {useSelector} from 'react-redux';
import Pagination from '@mui/material/Pagination';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function CustomTablePagination(props) {
  const displaySettings = useSelector((state) => {
    return state.framework;
  });
  const {count, page, rowsPerPage} = props;

  const handleChangePage = (newPage) => {
    props.setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  return (
    <div className='d-flex flex-row p-1'>
      <div className='p-0' style={{width: displaySettings.smallScreen ? '75px' : '85px'}}>
        <FormControl sx={{width: '100%'}} size='small'>
          <InputLabel sx={{fontSize: displaySettings.smallScreen ? '10px' : '14px'}}>Items/Page</InputLabel>
          <Select value={rowsPerPage} label='Items/Page' onChange={handleChangeRowsPerPage} size='small'>
            <MenuItem value={0}>All</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className='d-flex align-items-center' style={{flexGrow: 1, overflow: 'auto'}}>
        <Pagination
          color='primary'
          shape='rounded'
          count={rowsPerPage ? Math.ceil(count / rowsPerPage) : 1}
          sx={{
            '.MuiPagination-ul': {
              justifyContent: 'space-evenly',
            },
            '.MuiPaginationItem-firstLast': {
              paddingLeft: '0px',
              paddingRight: '0px',
            },
            width: '100%',
          }}
          onChange={(event, value) => {
            handleChangePage(value);
          }}
          page={page + 1}
          siblingCount={displaySettings.smallScreen ? 1 : 3}
          size={displaySettings.smallScreen ? 'small' : 'medium'}
          showFirstButton
          showLastButton
          hidePrevButton={displaySettings.smallScreen}
          hideNextButton={displaySettings.smallScreen}
        ></Pagination>
      </div>
    </div>
  );
}

export {CustomTablePagination};

import {createSlice} from '@reduxjs/toolkit';

const inspectionReportsModalSlice = createSlice({
  name: 'inspectionReportsModal',
  initialState: {
    vehicle: '',
    items: [],
    inspectedDate: '',
    closedDate: '',
    inspectedByUser: '',
    closedByUser: '',
    comment: '',
    status: null,
    reportDocId: null,
    inspectionNameInput: '',
  },
  reducers: {
    setInspectionReportsModalValues(state, action) {
      const reportData = action.payload;
      state.vehicle = reportData.vehicle;
      state.items = reportData.items;
      state.inspectedDate = reportData.inspectedDate;
      state.closedDate = reportData.closedDate;
      state.inspectedByUser = reportData.inspectedByUser;
      state.closedByUser = reportData.closedByUser;
      state.comment = reportData.comment;
      state.status = reportData.status;
      state.reportDocId = reportData.reportDocId;
      state.inspectionNameInput = reportData.inspectionNameInput;
    },
  },
});

export const {setInspectionReportsModalValues} = inspectionReportsModalSlice.actions;
export default inspectionReportsModalSlice.reducer;

import {Doughnut} from 'react-chartjs-2';
import React, {useState, useEffect, useRef} from 'react';
import {ArcElement} from 'chart.js';
import ChartJS from 'chart.js/auto';
import {customChartProperties} from '../app/utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement);
ChartJS.register(ChartDataLabels);

function drawTargetLine(chart, args) {
  const ctx = chart.ctx;
  if (!args) {
    return;
  }
  ctx.save();

  // Target in percent of the circumference of the circle
  const target = args.meta._dataset.target;
  const xCenter = chart.getDatasetMeta(0).data[0].x;
  const yCenter = chart.getDatasetMeta(0).data[0].y;
  const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
  const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
  const doughnutThickness = outerRadius - innerRadius;

  // // Calculate the angle in radians based on the target percentage
  const angle = (2 * Math.PI * target) / 100;

  // Draw a line at the center of the chart
  ctx.translate(xCenter, yCenter);
  ctx.rotate(angle);
  ctx.beginPath();
  ctx.fillStyle = customChartProperties.colorBlue;
  ctx.rect(0 - 2.5, -outerRadius - 10, 5, doughnutThickness + 20);
  ctx.fill();
  ctx.restore();
}

function DougnutChart(props) {
  const options = props.options;

  if (!options.plugins.targetLine) {
    options.plugins.targetLine = false;
  }
  if (!options.plugins.datalabels) {
    options.plugins.datalabels = false;
  }

  const chartRef = useRef(null);

  // Callback to get the chart instance after it is created
  const getChartInstance = (chart) => {
    chartRef.current = chart;

    // Register the targetLine plugin
    ChartJS.register({
      id: 'targetLine',
      afterDatasetDraw: (chartInstance, args) => {
        drawTargetLine(chartInstance, args);
      },
    });
  };

  const data = props.data;
  return <Doughnut className='d-inline-block' options={options} data={data} ref={getChartInstance} />;
}

export default DougnutChart;

import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {numberWithCommas, unitsDisplayConversion, sortVehicleNamesHelper} from '../../../app/utils';
import {ScheduleTable} from './ScheduleTable';

function ScheduleTab(props) {
  const loading = useSelector((state) => {
    return state.shopview.loading;
  });
  const serviceTasks = useSelector((state) => {
    return state.shopview.serviceTasks;
  });
  const vehiclesLookUp = useSelector((state) => {
    return state.shopview.vehiclesLookUp;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });

  const [tableData, setTableData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    vehicles: [],
    tasks: [],
  });
  const [units, setUnits] = useState('km');
  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setUnits('mi');
    } else {
      setUnits('km');
    }
  }, [unitsSystem]);

  useEffect(() => {
    getSchedule();
  }, [serviceTasks, vehiclesLookUp]);

  function getSchedule() {
    const rowList = [];
    const filterOptionsVehicle = [];
    const filterOptionsTask = [];

    for (let i = 0; i < serviceTasks.length; i++) {
      const serviceTask = serviceTasks[i].data;

      const vehicle = vehiclesLookUp[serviceTask.vehicleSN];
      // Skip if vehicle not active or task deleted
      if (!vehicle || serviceTask.deleted || !vehicle.shopActive) {
        continue;
      }

      // Task Interval
      let intervalString = '';
      if (serviceTask.time_interval) {
        intervalString = intervalString + `Every ${serviceTask.time_interval} ${serviceTask.time_units}`;
      }
      if (serviceTask.odometer_interval) {
        intervalString +=
          (intervalString != '' ? '<br>' : '') +
          `Every ${numberWithCommas(unitsDisplayConversion(serviceTask.odometer_interval, units))} ${units}`;
      }
      if (serviceTask.engine_hours_interval) {
        intervalString +=
          (intervalString != '' ? '<br>' : '') +
          `Every ${numberWithCommas(Math.round(serviceTask.engine_hours_interval))} engine hours`;
      }

      rowList.push({
        serviceTask: serviceTask,
        vehicle: vehicle.name,
        vehicleSN: vehicle.serialNumber,
        task: serviceTask.name,
        intervalString: intervalString,
        notes: serviceTask.notes ?? '',
        averageLaborHours: serviceTask.average_labor_hours || '',
        averageLaborCost: serviceTask.average_labor_cost || '',
        averagePartsCost: serviceTask.average_parts_cost || '',
      });

      // Update filter options
      filterOptionsVehicle.push(vehicle.name);
      filterOptionsTask.push(serviceTask.name);
    }

    // Add filter options to dropdowns
    filterOptionsVehicle.sort((a, b) => {
      return sortVehicleNamesHelper(a, b);
    });
    filterOptionsTask.sort();
    setFilterOptions((values) => {
      return {...values, vehicles: [...new Set(filterOptionsVehicle)], tasks: [...new Set(filterOptionsTask)]};
    });

    // Sort rows based on vehicle name
    rowList.sort((a, b) => {
      return sortVehicleNamesHelper(a.vehicle, b.vehicle);
    });

    setTableData(rowList);
  }

  return (
    <div className='tab-wrapper'>
      <div className='row my-2 mx-0'>
        <div className='ml-auto col-md-4 col-xl-2 col-6 px-0'>
          <button
            type='button'
            className='btn border-dark btn-light col-12 ml-auto'
            data-toggle='modal'
            data-target='#shopview-new-task-modal'
          >
            New Service
          </button>
        </div>
      </div>
      <ScheduleTable tableData={tableData} filterOptions={filterOptions} loading={loading} />
    </div>
  );
}

export {ScheduleTab};

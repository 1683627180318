import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {sendGAEvent, distanceBetweenPoints, fetchPostAuthSafe, formatString} from '../../app/utils';
import {DateTime, Interval, Duration} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {CircularProgress} from '@mui/material';
import {Tailselect} from '../../components/Tailselect';
import {useNavigate} from 'react-router-dom';
import {sortVehicleNamesHelper, machineTypeMapping, unitsDisplayConversion} from '../../app/utils';
import {Button, TextField, Grid} from '@mui/material';
import {
  setServicesData,
  setOperationsData,
  setTaskConfigs,
  setInspectionsData,
  setLoading,
  setInstallTypes,
  setDatesCache,
  setDaysDiff,
  setInOutTime,
  setVehicles,
  setInspectionsDict,
  setkpiLandingPageOn,
  updateActiveDevices,
  setSelectedKPI,
  setScorecards,
  setSelectedScorecardId,
  setSelectedScorecardPageIndex,
} from './dashboardSlice';

// Declare abort controllers
let abortController;

function Menu(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const taskConfigs = useSelector((state) => {
    return state.dashboard.taskConfigs;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const datesCache = useSelector((state) => {
    return state.dashboard.dates;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const inspectionsData = useSelector((state) => {
    return state.dashboard.inspectionsData;
  });
  const servicesData = useSelector((state) => {
    return state.dashboard.servicesData;
  });
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const inspectionsDict = useSelector((state) => {
    return state.dashboard.inspectionsDict;
  });
  const kpiLandingPageOn = useSelector((state) => {
    return state.dashboard.kpiLandingPageOn;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });
  const selectedKPI = useSelector((state) => {
    return state.dashboard.selectedKPI;
  });
  const selectedScorecardId = useSelector((state) => {
    return state.dashboard.selectedScorecardId;
  });

  const keyRef = useRef(DateTime.now());
  const [searchParams, setSearchParams] = useSearchParams();
  const [exportToggles, setExportToggles] = useState({
    vehicles: false,
    regions: false,
    fields: false,
    blocks: false,
    tasks: false,
    inspections: false,
    services: false,
  });
  const [distanceUnits, setDistanceUnits] = useState('km');
  const [speedUnits, setSpeedUnits] = useState('kph');

  useEffect(() => {
    let distUnits = 'km';
    if (unitsSystem == 'imperial') {
      distUnits = 'mi';
    }
    setDistanceUnits(distUnits);

    let spdUnits = 'kph';
    if (unitsSystem == 'imperial') {
      spdUnits = 'mph';
    }
    setSpeedUnits(spdUnits);
  }, [unitsSystem]);

  // We have to set dates using the start time of first active task to NOW
  const [dates, setDates] = useState({
    start: null,
    end: null,
    max: null,
  });

  useEffect(() => {
    if (dates.start == null || dates.end == null) {
      // Null values means initilization still needs to occur
      initDates();
    }
  }, []);

  useEffect(async () => {
    await loadReport();
  }, [dates]);

  useEffect(async () => {
    if (dates.start != null || dates.end != null) {
      const datesStartISO = dates.start.toISO();
      const datesEndISO = dates.end.toISO();
      if (datesStartISO != datesCache.start || datesEndISO != datesCache.end) {
        // set new dates to reload data if date cached is updated (from scorecard page), and is different from date range of data fetched.
        setDates((values) => {
          const newDateObject = {
            ...values,
            start: DateTime.fromISO(datesCache.start).setZone(customerSettings.general.timeZone, {}),
            end: DateTime.fromISO(datesCache.end).setZone(customerSettings.general.timeZone, {}),
          };
          return newDateObject;
        });
      }
    }
  }, [datesCache]);

  async function loadReport() {
    dispatch(setLoading(true));

    if (dates.start != null && dates.end != null) {
      window.history.replaceState(
        null,
        '',
        window.location.origin +
          `/statsview?start=${encodeURIComponent(dates.start)}&end=${encodeURIComponent(dates.end)}`
      );
      const getDevicesRequest = fetch('/getDevices', {cache: 'no-store'});
      if (typeof abortController !== 'undefined') {
        abortController.abort();
      }
      abortController = new AbortController();
      const operationsDataRequest = getOperationsData();
      const servicesRequest = getServicesData();
      const installTypeRequest = getInstallTypes();

      const [operationsDataResponse, servicesResponse, installTypeResponse, getDevicesResponse] = await Promise.all([
        operationsDataRequest,
        servicesRequest,
        installTypeRequest,
        getDevicesRequest,
      ]);

      if (typeof operationsDataResponse.success === 'undefined' || operationsDataResponse.success == false) {
        navigate('/error', {state: {errorMsg: 'Error retrieving operations data please try again.'}});
        return;
      }

      dispatch(
        setInOutTime({
          inFieldTime: operationsDataResponse.data.inFieldTotal,
          outFieldTime: operationsDataResponse.data.outFieldTotal,
        })
      );

      dispatch(setOperationsData(operationsDataResponse.data.operationsReport));
      dispatch(setInspectionsData(operationsDataResponse.data.inspectionsReport));
      dispatch(setServicesData(servicesResponse));
      dispatch(setInstallTypes(installTypeResponse));
      dispatch(setTaskConfigs(operationsDataResponse.data.taskConfigIdDict));
      dispatch(setInspectionsDict(operationsDataResponse.data.inspectionItemsDict));
      dispatch(setVehicles(operationsDataResponse.data.vehicleSNDict));

      const devices = await getDevicesResponse.json();
      dispatch(updateActiveDevices(devices));

      const dayDiff = Math.round((new DateTime(dates.end) - new DateTime(dates.start)) / (1000 * 60 * 60 * 24));
      dispatch(setDaysDiff(dayDiff));
    }

    dispatch(setLoading(false));
  }

  async function getOperationsData() {
    const queryData = {
      start: dates.start.toISO(),
      end: dates.end.toISO(),
    };

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-store',
      signal: abortController.signal,
    };
    const response = await fetch(
      `/statsview/getOperationsData?start=${encodeURIComponent(queryData.start)}&end=${encodeURIComponent(
        queryData.end
      )}`,
      options
    );
    const result = await response.json();
    return result;
  }

  async function getScorecards() {
    // TODO REPLACE END DATE FROM THE DATA SELECTOR

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-store',
    };
    const response = await fetch('/statsview/getScorecards', options);
    const result = await response.json();
    return result.data;
  }

  async function getServicesData() {
    // TODO REPLACE END DATE FROM THE DATA SELECTOR
    const queryData = {
      start: dates.start.toISO(),
      end: dates.end.toISO(),
    };

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-store',
      signal: abortController.signal,
    };
    const response = await fetch(
      `/statsview/getServiceData?start=${encodeURIComponent(queryData.start)}&end=${encodeURIComponent(queryData.end)}`,
      options
    );
    const result = await response.json();
    return result.data;
  }

  async function getInstallTypes() {
    if (
      typeof customerSettings.dashboard.kpiIdleInstallStatusWarningDisplay !== 'undefined' &&
      customerSettings.dashboard.kpiIdleInstallStatusWarningDisplay === false
    ) {
      return {};
    }

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-store',
      signal: abortController.signal,
    };
    const response = await fetch(`/getDeviceInstallTypes`, options);
    const result = await response.json();
    return result;
  }

  function sortHeadersWithSubstringFirst(headers) {
    headers.sort((a, b) => {
      if (a.includes('Name') && !b.includes('Name')) {
        return -1;
      } else if (!a.includes('Name') && b.includes('Name')) {
        return 1;
      }
      return 0;
    });
    return headers;
  }

  function downloadCsv(data, title, tasks = false) {
    const csvList = [];
    let headerKeys;
    let headerText;
    let headerDict = {};
    if (tasks) {
      headerDict = {
        'taskName': 'Task Name',
        'zoneName': 'Zone Name',
        'zoneLevel': 'Zone Level',
        'acPerHr': 'ac/hr',
        'acreage': 'Acreage',
        'avgSpeed': `Ave Speed (${speedUnits})`,
        'distance': `Distance (${distanceUnits})`,
        'duration': 'Operation Duration (hr)',
      };

      const zoneLevels = ['Block', 'Field', 'Region'];
      Object.keys(data).forEach((key) => {
        const taskObj = data[key];
        zoneLevels.forEach((zoneLevel) => {
          const zoneObj = taskObj[zoneLevel];
          Object.keys(zoneObj).forEach((zoneName) => {
            const row = [];
            const innerMostObj = zoneObj[zoneName];
            Object.keys(headerDict).forEach((headerKey) => {
              if (headerKey == 'taskName') {
                if (taskConfigs.hasOwnProperty(key) && taskConfigs[key].hasOwnProperty('name')) {
                  row.push(`\"${taskConfigs[key]['name']}\"`);
                } else {
                  row.push(`\"No Task Found\"`);
                }
              } else if (headerKey == 'zoneName') {
                row.push(`\"${zoneName}\"`);
              } else if (headerKey == 'zoneLevel') {
                row.push(`\"${zoneLevel}\"`);
              } else if (['distance', 'avgSpeed'].includes(headerKey)) {
                const distanceUnitConverted =
                  unitsDisplayConversion(innerMostObj[headerKey] * 100, distanceUnits) / 100;
                row.push(`\"${distanceUnitConverted}\"`);
              } else {
                row.push(`\"${innerMostObj[headerKey]}\"`);
              }
            });
            csvList.push(row);
          });
        });
      });
    } else if (title == 'Vehicles') {
      headerDict = {
        'vehicleName': 'Vehicle Name',
        'type': 'Type',
        'inFieldTime': 'In Field Time (hr)',
        'outOfFieldTime': 'Out Of Field Time (hr)',
        'drivingDuration': 'Driving Duration (hr)',
        'idleTimeInHours': 'Idle Time In Hours (hr)',
        'totalDuration': 'Total Duration (hr)',
        'acPerHr': 'ac/hr',
        'acreage': 'Acreage',
        'avgSpeed': `Ave Speed (${speedUnits})`,
        'distance': `Distance (${distanceUnits})`,
        'enteredTaskAcreage': 'Acreage - Task Entered',
        'missingTaskAcreage': 'Acreage - Task Missing',
        'enteredTaskHours': 'In Field Hours - Task Entered',
        'missingTaskHours': 'In Field Hours - Task Missing',
        'idlePercent': 'Idle Percent',
      };

      const items = Object.keys(data).map(function (key) {
        return data[key];
      });

      items.sort((vehicleA, vehicleB) => {
        const aName = vehicleA['vehicleName'];
        const bName = vehicleB['vehicleName'];

        return sortVehicleNamesHelper(aName, bName);
      });

      items.forEach((item) => {
        const row = [];
        const obj = item;
        Object.keys(headerDict).forEach((headerKey) => {
          let cellContent = obj[headerKey];
          if (headerKey == 'type') {
            cellContent = machineTypeMapping[obj[headerKey]];
          }
          if (['distance', 'avgSpeed'].includes(headerKey)) {
            const distanceUnitConverted = unitsDisplayConversion(obj[headerKey] * 100, distanceUnits) / 100;
            cellContent = distanceUnitConverted;
          }
          row.push(`\"${cellContent}\"`);
        });
        csvList.push(row);
      });
    } else if (title == 'Inspections') {
      headerDict = {
        'vehicle': 'Vehicle Name',
        'pass': 'Pass',
        'fail': 'Fail',
        'failedItems': 'Failed Items',
      };

      Object.keys(data).forEach((key) => {
        const row = [];
        const obj = data[key];
        Object.keys(headerDict).forEach((headerKey) => {
          row.push(`\"${obj[headerKey]}\"`);
        });
        csvList.push(row);
      });
    } else if (title == 'Services') {
      headerDict = {
        'vehicleName': 'Vehicle Name',
        'onTimeServices': 'On-time Services',
        'lateServices': 'Late Services',
      };

      Object.keys(data).forEach((key) => {
        const row = [];
        const obj = data[key];
        Object.keys(headerDict).forEach((headerKey) => {
          row.push(`\"${obj[headerKey]}\"`);
        });
        csvList.push(row);
      });
    } else {
      headerKeys =
        Object.keys(data).length > 0 ? sortHeadersWithSubstringFirst(Object.keys(data[Object.keys(data)[0]])) : [];
      headerText = headerKeys;

      Object.keys(data).forEach((key) => {
        const row = [];
        const obj = data[key];
        headerKeys.forEach((headerKey) => {
          row.push(`\"${obj[headerKey]}\"`);
        });
        csvList.push(row);
      });
    }

    // Export the header text
    let csv = '';
    const exportHeaderKeys = Object.keys(headerDict);
    for (let i = 0; i < exportHeaderKeys.length; i++) {
      if (i + 1 == exportHeaderKeys.length) {
        csv += headerDict[exportHeaderKeys[i]];
        csv += '\n';
      } else {
        csv += headerDict[exportHeaderKeys[i]] + ',';
      }
    }

    csvList.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
    hiddenElement.target = '_blank';

    const start = dates.start;
    const end = dates.end;

    const fileName = `${title}_${DateTime.fromISO(start)
      .setZone(customerSettings.timeZone)
      .toFormat('yyyy-MM-dd_HH-mm')}_${DateTime.fromISO(end)
      .setZone(customerSettings.timeZone)
      .toFormat('yyyy-MM-dd_HH-mm')}`;
    hiddenElement.download = `${fileName}.csv`;
    hiddenElement.click();

    sendGAEvent('csv_download', 'kpi', 'dashboard');
  }

  function exportData() {
    Object.keys(exportToggles).forEach((key) => {
      if (exportToggles[key]) {
        if (key == 'vehicles') {
          downloadCsv(operationsData.vehiclesObject, 'Vehicles', false);
        }
        if (key == 'tasks') {
          downloadCsv(operationsData.tasksObject, 'Tasks', true);
        }
        if (key == 'inspections') {
          // Need to parse inspections data to be readable
          const inspectionsCsvData = [];

          Object.keys(inspectionsData.vehicleResults).forEach((veh) => {
            let failedItemsString = '';
            inspectionsData.vehicleResults[veh].failedItems.forEach((item) => {
              failedItemsString += inspectionsDict[item.id].name + ',';
            });
            const inspectionsCsvObject = {
              vehicle: veh,
              pass: inspectionsData.vehicleResults[veh].pass,
              fail: inspectionsData.vehicleResults[veh].fail,
              failedItems: failedItemsString.slice(0, -1),
            };
            inspectionsCsvData.push(inspectionsCsvObject);
          });
          downloadCsv(inspectionsCsvData, 'Inspections', false);
        }
        if (key == 'services') {
          downloadCsv(servicesData.vehicleServices, 'Services', false);
        }

        // Not active / available
        if (key == 'regions') {
          downloadCsv(operationsData.regionsObject, 'Regions', false);
        }
        if (key == 'fields') {
          downloadCsv(operationsData.fieldsObject, 'Fields', false);
        }
        if (key == 'blocks') {
          downloadCsv(operationsData.blocksObject, 'Blocks', false);
        }
      }
    });
  }

  function datePickerRender() {
    let buttonDisplayString = '';

    if (
      typeof dates.start !== 'undefined' &&
      typeof dates.end !== 'undefined' &&
      dates.start != null &&
      dates.end != null
    ) {
      buttonDisplayString = ` ${dates.start.toFormat('L/d/yy')} ` + `- ${dates.end.toFormat('L/d/yy')} `;
      return (
        <DateRangePicker
          key={keyRef.current}
          onApply={dateSelection}
          initialSettings={{
            startDate: dates.start.toFormat('MM/dd/yyyy'),
            endDate: dates.end.toFormat('MM/dd/yyyy'),
            maxDate: dates.max.toFormat('MM/dd/yyyy'),
            // timePicker: false,
            // timePicker24Hour: false,
            locale: {
              format: 'MM/DD/YYYY',
            },
          }}
          disabled={loading}
        >
          <button className='btn border-dark btn-light col-12 cropview-menu-text h-100'>
            <i className='fas fa-calendar-alt' />
            {buttonDisplayString}
          </button>
        </DateRangePicker>
      );
    }
  }

  function dateSelection(event, picker) {
    const [todayStart, todayEnd] = getTodayDates();
    // Clear the filters
    if (Object.prototype.hasOwnProperty.call(props, 'setFilterDefault')) {
      props.setFilterDefault();
    }

    const startDate = DateTime.fromISO(picker.startDate.toISOString()).setZone(customerSettings.general.timeZone, {
      keepLocalTime: true,
    });
    const endDate = DateTime.fromISO(picker.endDate.toISOString())
      .set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      })
      .setZone(customerSettings.general.timeZone, {keepLocalTime: true});

    const newDataDates = {
      start: startDate,
      end: endDate,
      max: todayEnd,
    };
    setDates(newDataDates);
    dispatch(setDatesCache({start: newDataDates.start.toISO(), end: newDataDates.end.toISO()}));
  }

  async function initDates() {
    const scorecardsRequest = getScorecards();
    const [scorecardsResponse] = await Promise.all([scorecardsRequest]);
    dispatch(setScorecards(scorecardsResponse));

    // Determine if we have cached dates
    const cachedStart =
      datesCache.start != null
        ? DateTime.fromISO(datesCache.start).setZone(customerSettings.general.timeZone, {
            keepLocalTime: true,
          })
        : null;
    const cachedEnd =
      datesCache.end != null
        ? DateTime.fromISO(datesCache.end).setZone(customerSettings.general.timeZone, {
            keepLocalTime: true,
          })
        : null;

    // Init using current shift
    const [todayStart, todayEnd] = getTodayDates();

    // Check if values were inputted through url
    const startParam = cachedStart == null ? decodeURIComponent(searchParams.get('start')) : cachedStart;
    const endParam = cachedEnd == null ? decodeURIComponent(searchParams.get('end')) : cachedEnd;

    let startTime = DateTime.fromISO(startParam).setZone(customerSettings.general.timeZone);
    let endTime = DateTime.fromISO(endParam).setZone(customerSettings.general.timeZone);

    const urlSearch = window.location.search;
    if (urlSearch.includes('scorecardId')) {
      const scorecardId = window.location.search.split('=')[1];

      let foundCard = null;
      for (let i = 0; i < scorecardsResponse.length; i++) {
        const resp = scorecardsResponse[i];
        const scorecard = resp.data;

        if (scorecard.id == scorecardId) {
          foundCard = scorecard;
          if (scorecard.pages.length > 0) {
            dispatch(setSelectedScorecardPageIndex(0));
          }
          break;
        }
      }

      startTime = DateTime.fromISO(foundCard.startDate['@ts']).setZone(customerSettings.general.timeZone);
      endTime = DateTime.fromISO(foundCard.endDate['@ts']).setZone(customerSettings.general.timeZone);

      dispatch(setSelectedScorecardId(scorecardId));
      dispatch(setSelectedKPI('Scorecards'));
      dispatch(setkpiLandingPageOn(false));
    }

    // Ensure the url values are valid or else they need to be re-initilizationed
    if (!startTime.isValid || !endTime.isValid || (startTime >= todayEnd && endTime >= todayEnd)) {
      // If dates are after the lastUploadTime, or the values are invalid, set to the 24hr before
      startTime = todayStart;
      endTime = todayEnd;
    } else if (startTime.isValid && endTime.isValid && startTime < todayEnd && endTime >= todayEnd) {
      // Otherwise the dates are valid, check if the end time is after the lastUploadTime
      endTime = todayEnd;
    }

    keyRef.current = DateTime.now();
    const newDates = {
      start: startTime,
      end: endTime,
      max: todayEnd,
    };
    dispatch(setDatesCache({start: startTime.toISO(), end: endTime.toISO()}));
    setDates(newDates);
  }

  function getTodayDates() {
    // Get startDate for today
    const now = DateTime.local({zone: customerSettings.general.timeZone});
    const todayStart = now.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    // Get endDate for today
    const todayEnd = todayStart.plus({days: 1, milliseconds: -1});

    return [todayStart, todayEnd];
  }

  return (
    <React.Fragment>
      <div className='container-fluid row my-1 mx-0 justify-content-between'>
        <div className='col-2 col-md-1 px-0'>
          <button
            className={`btn border-dark btn-light col-12 cropview-menu-text ${kpiLandingPageOn && 'd-none'}`}
            disabled={kpiLandingPageOn}
            onClick={(e) => {
              dispatch(setkpiLandingPageOn(true));
              dispatch(setSelectedKPI('Machinery'));
            }}
          >
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px'}}>
              <FontAwesomeIcon
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '18px'}}
                icon='fas fa-left-long'
              />
            </div>
          </button>
        </div>
        {selectedKPI != 'Scorecards' && (
          <React.Fragment>
            <div className='col-8 col-md-5 px-0'>{datePickerRender()}</div>
            <div className='col-2 col-md-1 px-0'>
              <button
                className='btn border-dark btn-light col-12 cropview-menu-text'
                data-toggle='dropdown'
                disabled={loading || kpiLandingPageOn}
              >
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px'}}>
                  <FontAwesomeIcon
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '18px'}}
                    icon='fas fa-download'
                  />
                </div>
              </button>
              <div className='dropdown-menu border-dark' style={{minWidth: '0'}}>
                <div className='dropdown-header cropview-menu-text px-3'>Export</div>
                <button
                  className='dropdown-item cropview-menu-text px-3'
                  onClick={(e) => {
                    e.stopPropagation();
                    setExportToggles({...exportToggles, vehicles: !exportToggles.vehicles});
                  }}
                >
                  Vehicles {exportToggles.vehicles && <FontAwesomeIcon icon='fas fa-check' />}
                </button>
                <button
                  className='dropdown-item cropview-menu-text px-3'
                  onClick={(e) => {
                    e.stopPropagation();
                    setExportToggles({...exportToggles, tasks: !exportToggles.tasks});
                  }}
                >
                  Tasks {exportToggles.tasks && <FontAwesomeIcon icon='fas fa-check' />}
                </button>
                <button
                  className='dropdown-item cropview-menu-text px-3'
                  onClick={(e) => {
                    e.stopPropagation();
                    setExportToggles({...exportToggles, inspections: !exportToggles.inspections});
                  }}
                >
                  Inspections {exportToggles.inspections && <FontAwesomeIcon icon='fas fa-check' />}
                </button>
                <button
                  className='dropdown-item cropview-menu-text px-3'
                  onClick={(e) => {
                    e.stopPropagation();
                    setExportToggles({...exportToggles, services: !exportToggles.services});
                  }}
                >
                  Services {exportToggles.services && <FontAwesomeIcon icon='fas fa-check' />}
                </button>
                <div style={{display: 'flex', alignItems: 'center'}}></div>
                <div className='dropdown-item cropview-menu-text px-3'>
                  <Button
                    variant='ic-button'
                    onClick={() => {
                      exportData();
                    }}
                    color='success'
                  >
                    Export
                  </Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

export {Menu};

import React, {useEffect, useState} from 'react';
import {deepCopy} from '../../app/utils';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import {useSelector, useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  TableHeadFilterButtonTitleWrapper,
  TableHeadFilterButtonTitleWrapperWithSort,
  TableHeadHiddenDropdownWrapper,
} from '../../components/Table';
import {IntelliSelect} from '../../components/IntelliSelect';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {DateTime} from 'luxon';
import TextField from '@mui/material/TextField';
import {InputLabel, FormControl, Select, MenuItem} from '@mui/material';
import {
  Collapse,
  Alert,
  AlertTitle,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import {ModalSubmitting} from '../../components/GeneralComponents';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {fetchPostAuthSafe} from '../../app/utils';
import {setScorecards, setSelectedScorecardId} from './dashboardSlice';

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

function generateTableHead(sortTableData, type, filters, handleFilters, filterOptions, pageStates, pageFilters) {
  const [selectOpen, setSelectOpen] = useState(false);

  function handleInternal(reason, details, name, inputValue) {
    let oldFilters = deepCopy(filters[name]);

    if (reason == 'selectOption' && !oldFilters.includes(details.option.value)) {
      oldFilters.push(details.option.value);
    } else if (reason == 'selectOption') {
      const indexToRemove = oldFilters.indexOf(details.option.value);
      oldFilters.splice(indexToRemove, 1);
    } else if (reason == 'None') {
      oldFilters = [];
    } else if (reason == 'All') {
      const allSelected = filterOptions[name]
        .filter((item) => {
          return item.text.toLowerCase().includes(inputValue.toLowerCase());
        })
        .map((opt) => {
          return opt.value;
        });
      oldFilters = allSelected;
    }

    handleFilters(oldFilters);
  }

  if (type == 'services') {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <TableHeadFilterButtonTitleWrapperWithSort
              onClickHandler={() => {
                sortTableData('vehicleName');
              }}
              title={'Vehicle'}
            >
              {!pageStates && (
                <button
                  className='btn btn-transparent btn-sm'
                  onClick={() => {
                    setSelectOpen(!selectOpen);
                  }}
                >
                  <FontAwesomeIcon
                    icon='fas fa-filter'
                    style={{
                      color:
                        filters.hasOwnProperty('serviceHealthVehicleSNs') &&
                        filters['serviceHealthVehicleSNs'].length > 0 &&
                        '#4e73df',
                    }}
                  />
                </button>
              )}
            </TableHeadFilterButtonTitleWrapperWithSort>
            {!pageStates && (
              <TableHeadHiddenDropdownWrapper>
                <IntelliSelect
                  id='service-health-vehicle-name-select'
                  name={'serviceHealthVehicleSNs'}
                  multiple={true}
                  open={selectOpen}
                  value={filters['serviceHealthVehicleSNs']}
                  options={filterOptions['serviceHealthVehicleSNs']}
                  handleChangeParent={handleInternal}
                  handleOpenChange={() => {
                    setSelectOpen(!selectOpen);
                  }}
                />
              </TableHeadHiddenDropdownWrapper>
            )}
          </TableCell>
          <TableCell>
            <TableHeadFilterButtonTitleWrapperWithSort
              onClickHandler={() => {
                sortTableData('lateServices');
              }}
              title={'# Late Services'}
            />
          </TableCell>
          <TableCell>
            <TableHeadFilterButtonTitleWrapperWithSort
              onClickHandler={() => {
                sortTableData('onTimeServices');
              }}
              title={'# On Time Services'}
            />
          </TableCell>
          <TableCell>
            <TableHeadFilterButtonTitleWrapperWithSort
              onClickHandler={() => {
                sortTableData('nonRecurringServices');
              }}
              title={'# Non-Recurring Services'}
            />
          </TableCell>
        </TableRow>
      </TableHead>
    );
  } else if (type == 'inspections') {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <TableHeadFilterButtonTitleWrapperWithSort
              onClickHandler={() => {
                sortTableData('vehicleName');
              }}
              title={'Vehicle'}
            >
              {!pageStates && !pageFilters && (
                <button
                  className='btn btn-transparent btn-sm'
                  onClick={() => {
                    setSelectOpen(!selectOpen);
                  }}
                >
                  <FontAwesomeIcon
                    icon='fas fa-filter'
                    style={{
                      color:
                        filters.hasOwnProperty('inspectionVehicleSNs') &&
                        filters['inspectionVehicleSNs'].length > 0 &&
                        '#4e73df',
                    }}
                  />
                </button>
              )}
            </TableHeadFilterButtonTitleWrapperWithSort>
            {!pageStates && !pageFilters && (
              <TableHeadHiddenDropdownWrapper>
                <IntelliSelect
                  id='insp-veh-name-select'
                  name={'inspectionVehicleSNs'}
                  multiple={true}
                  open={selectOpen}
                  value={filters['inspectionVehicleSNs']}
                  options={filterOptions['inspectionVehicleSNs']}
                  handleChangeParent={handleInternal}
                  handleOpenChange={() => {
                    setSelectOpen(!selectOpen);
                  }}
                />
              </TableHeadHiddenDropdownWrapper>
            )}
          </TableCell>
          <TableCell>
            <TableHeadFilterButtonTitleWrapperWithSort
              onClickHandler={() => {
                sortTableData('failures');
              }}
              title={'Failures'}
            />
          </TableCell>
          <TableCell>
            <TableHeadFilterButtonTitleWrapperWithSort
              onClickHandler={() => {
                sortTableData('failedItems');
              }}
              title={'Items Failed'}
            />
          </TableCell>
          <TableCell>
            <TableHeadFilterButtonTitleWrapper title={'Failed Dates'} />
          </TableCell>
        </TableRow>
      </TableHead>
    );
  } else {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <TableHeadFilterButtonTitleWrapperWithSort
              onClickHandler={() => {
                sortTableData('vehicleName');
              }}
              title={'Vehicle'}
            >
              {!pageStates && (
                <button
                  className='btn btn-transparent btn-sm'
                  onClick={() => {
                    setSelectOpen(!selectOpen);
                  }}
                >
                  <FontAwesomeIcon
                    icon='fas fa-filter'
                    style={{
                      color: filters.hasOwnProperty('oppEffVehSNs') && filters['oppEffVehSNs'].length > 0 && '#4e73df',
                    }}
                  />
                </button>
              )}
            </TableHeadFilterButtonTitleWrapperWithSort>
            {!pageStates && (
              <TableHeadHiddenDropdownWrapper>
                <IntelliSelect
                  id='op-eff-vehicle-name-select'
                  name={'oppEffVehSNs'}
                  multiple={true}
                  open={selectOpen}
                  value={filters['oppEffVehSNs']}
                  options={filterOptions['oppEffVehSNs']}
                  handleChangeParent={handleInternal}
                  handleOpenChange={() => {
                    setSelectOpen(!selectOpen);
                  }}
                />
              </TableHeadHiddenDropdownWrapper>
            )}
          </TableCell>
          <TableCell>
            <TableHeadFilterButtonTitleWrapperWithSort
              onClickHandler={() => {
                sortTableData('type');
              }}
              title={'Machine Type'}
            ></TableHeadFilterButtonTitleWrapperWithSort>
          </TableCell>
          <TableCell>
            <TableHeadFilterButtonTitleWrapperWithSort
              onClickHandler={() => {
                sortTableData('inFieldPercent');
              }}
              title={'In Field Actual'}
            ></TableHeadFilterButtonTitleWrapperWithSort>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

function generateTableBody(tableData, type) {
  if (type == 'services') {
    let colorFlip = false;
    return (
      <React.Fragment>
        <React.Fragment>
          {tableData.map((rowData, idx) => {
            colorFlip = !colorFlip;

            return (
              <TableRow
                style={{backgroundColor: colorFlip ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0)'}}
                key={idx}
              >
                <TableCell>{rowData.vehicleName}</TableCell>
                <TableCell>{rowData.lateServices}</TableCell>
                <TableCell>{rowData.onTimeServices}</TableCell>
                <TableCell>{rowData.nonRecurringServices}</TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      </React.Fragment>
    );
  } else if (type == 'inspections') {
    let colorFlip = false;
    return (
      <React.Fragment>
        <React.Fragment>
          {tableData.map((rowData, idx) => {
            colorFlip = !colorFlip;

            return (
              <TableRow
                style={{backgroundColor: colorFlip ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0)'}}
                key={idx}
              >
                <TableCell>{rowData.vehicleName}</TableCell>
                <TableCell>{rowData.failures}</TableCell>
                <TableCell>{rowData.failedItems}</TableCell>
                <TableCell>{rowData.failedItemsDates}</TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      </React.Fragment>
    );
  } else {
    let colorFlip = false;
    return (
      <React.Fragment>
        <React.Fragment>
          {tableData.map((rowData, idx) => {
            colorFlip = !colorFlip;

            return (
              <TableRow
                style={{backgroundColor: colorFlip ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0)'}}
                key={idx}
              >
                <TableCell>{rowData.vehicleName}</TableCell>
                <TableCell>{rowData.type}</TableCell>
                <TableCell>{rowData.inFieldPercent}%</TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export function KpiTable(props) {
  const [tableData, setTableData] = useState([]);
  const [sortSettings, setSortSettings] = useState({
    method: 'vehicleName',
    direction: true,
  });

  useEffect(() => {
    const tableDataTemp = props.tableData;
    const sortMethod = sortSettings.method;
    const sortDirection = sortSettings.direction;
    tableDataTemp.sort((a, b) => {
      const valueA = a[sortMethod];
      const valueB = b[sortMethod];
      if (sortMethod == 'vehicleName' || sortMethod == 'type') {
        return sortDirection ? a[sortMethod].localeCompare(b[sortMethod]) : b[sortMethod].localeCompare(a[sortMethod]);
      } else {
        return sortDirection ? valueB - valueA : valueA - valueB;
      }
    });
    setTableData([].concat(tableDataTemp)); // For some reason need to use concat
  }, [props.tableData, sortSettings]);

  function sortTableData(newSortMethod) {
    // setTableData([]);
    setSortSettings({
      method: newSortMethod,
      direction: !sortSettings.direction,
    });
  }

  return (
    <TableContainer sx={{maxHeight: 440, minHeight: 400}}>
      <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
        {generateTableHead(
          sortTableData,
          props.type,
          props.filters,
          props.handleFilters,
          props.filterOptions,
          props.pageStates,
          props.pageFilters
        )}
        <TableBody>{generateTableBody(tableData, props.type)}</TableBody>
      </Table>
    </TableContainer>
  );
}

export function ScorecardSelectHolder(props) {
  const [submitStatus, setSubmitStatus] = useState('pending');
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const datesCache = useSelector((state) => {
    return state.dashboard.dates;
  });
  const selectedKPI = useSelector((state) => {
    return state.dashboard.selectedKPI;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });
  const selectedInnerSubset = useSelector((state) => {
    return state.dashboard.selectedInnerSubset;
  });
  const selectedZoneLevel = useSelector((state) => {
    return state.dashboard.selectedZoneLevel;
  });
  const reportFilters = useSelector((state) => {
    return state.dashboard.reportFilters;
  });
  const efficiencyAxisDisplay = useSelector((state) => {
    return state.dashboard.efficiencyAxisDisplay;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const scorecards = useSelector((state) => {
    return state.dashboard.scorecards;
  });
  const scorecardsIdDict = useSelector((state) => {
    return state.dashboard.scorecardsIdDict;
  });
  const selectedScorecardId = useSelector((state) => {
    return state.dashboard.selectedScorecardId;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const scorecardsArray = scorecards.map((scorecard) => {
    return {
      id: scorecard.data.id,
      name: scorecard.data.name,
    };
  });
  const initialSelectedScorecard =
    selectedScorecardId != null
      ? selectedScorecardId
      : scorecardsArray.length > 0
      ? scorecardsArray[0].id
      : 'newScorecard';
  const [selectedScorecard, setSelectedScorecard] = useState('newScorecard');
  const [newScorecardInput, setNewScorecardInput] = useState('');
  const [newPageName, setNewPageName] = useState(props.autofillPageName);
  const [dateMismatch, setDateMismatch] = useState(false);

  useEffect(() => {
    handleSelectedScorecardChange({target: {value: initialSelectedScorecard}});
  }, []);

  const scorecardEditAccess = userSettings.roleAccess['dashboardScorecardManagement'];

  function handleSelectedScorecardChange(event) {
    setSelectedScorecard(event.target.value);
    if (event.target.value != 'newScorecard') {
      dispatch(setSelectedScorecardId(event.target.value));
    }

    let sameDatesAsCache = false;
    if (scorecardsIdDict.hasOwnProperty(event.target.value)) {
      const scorecardStartDate = scorecardsIdDict[event.target.value].startDate['@ts'];
      const scorecardEndDate = scorecardsIdDict[event.target.value].endDate['@ts'];
      sameDatesAsCache =
        checkDateStringsSame(scorecardStartDate, datesCache.start) &&
        checkDateStringsSame(scorecardEndDate, datesCache.end);
    }
    if (event.target.value == 'newScorecard') {
      sameDatesAsCache = true;
    }
    setDateMismatch(!sameDatesAsCache);
  }

  function scorecardSelect() {
    return (
      <React.Fragment>
        <InputLabel id='demo-simple-select-standard-label'>Select Scorecard to Add View</InputLabel>
        <FormControl variant='standard' className='w-100'>
          <Select
            id='demo-simple-select-standard'
            size='small'
            value={selectedScorecard}
            onChange={handleSelectedScorecardChange}
          >
            {scorecardsArray.map((scorecard) => {
              return (
                <MenuItem key={scorecard.id} value={scorecard.id}>
                  <div style={{display: 'flex'}}>
                    <p style={{marginBottom: '0px'}}>{scorecard.name} </p>
                    <p style={{fontStyle: 'italic', fontSize: '12px', marginBottom: '0px', paddingLeft: '5px'}}>
                      {' '}
                      (
                      {DateTime.fromISO(scorecardsIdDict[scorecard.id].startDate['@ts'])
                        .setZone(customerSettings.general.timeZone, {})
                        .toISO()
                        .slice(0, 10)}{' '}
                      -{' '}
                      {DateTime.fromISO(scorecardsIdDict[scorecard.id].endDate['@ts'])
                        .setZone(customerSettings.general.timeZone, {})
                        .toISO()
                        .slice(0, 10)}
                      )
                    </p>
                  </div>
                </MenuItem>
              );
            })}
            <MenuItem key={'newScorecard'} value={'newScorecard'}>
              <FontAwesomeIcon style={{marginRight: '5px'}} icon='fa-solid fa-plus' />{' '}
              <div style={{display: 'flex'}}>
                <p style={{marginBottom: '0px'}}>Add to New Scorecard </p>
                <p style={{fontStyle: 'italic', fontSize: '12px', marginBottom: '0px', paddingLeft: '5px'}}>
                  {' '}
                  (
                  {DateTime.fromISO(datesCache.start)
                    .setZone(customerSettings.general.timeZone, {})
                    .toISO()
                    .slice(0, 10)}{' '}
                  -{' '}
                  {DateTime.fromISO(datesCache.end).setZone(customerSettings.general.timeZone, {}).toISO().slice(0, 10)}
                  )
                </p>
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </React.Fragment>
    );
  }

  async function submitPage() {
    const scorecardId = selectedScorecard;
    if (scorecardId == 'newScorecard') {
      const submissionObject = {
        name: newScorecardInput,
        startDate: datesCache.start,
        endDate: datesCache.end,
        pageName: newPageName,
        selectedKpi: selectedKPI,
        selectedSubset: selectedSubset,
        selectedInnerSubset: selectedInnerSubset,
        selectedZoneLevel: selectedZoneLevel,
        filters: reportFilters,
        efficiencyAxisDisplay: efficiencyAxisDisplay,
        pageFilters: props?.pageFilters,
        pageStates: props?.pageStates,
      };
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionObject),
      };
      setSubmitStatus('submitting');
      const response = await fetchPostAuthSafe(
        '/statsview/createScorecard',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const result = await response.json();
      if (result.success) {
        setSubmitStatus('success');
        setNewPageName('');
        setNewScorecardInput('');
        setModalOpen(false);
        setSubmitStatus('pending');
        dispatch(setScorecards(result.data));
      } else {
        setSubmitStatus('duplicateName');
      }
    } else {
      const submissionObject = {
        id: scorecardId,
        pageName: newPageName,
        selectedKpi: selectedKPI,
        selectedSubset: selectedSubset,
        selectedInnerSubset: selectedInnerSubset,
        selectedZoneLevel: selectedZoneLevel,
        filters: reportFilters,
        efficiencyAxisDisplay: efficiencyAxisDisplay,
        pageFilters: props?.pageFilters,
        pageStates: props?.pageStates,
      };
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionObject),
      };

      setSubmitStatus('submitting');
      const response = await fetchPostAuthSafe(
        '/statsview/addPageToScorecard',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const result = await response.json();
      if (result.success) {
        setSubmitStatus('success');
        setNewPageName('');
        setNewScorecardInput('');
        setModalOpen(false);
        setSubmitStatus('pending');
        dispatch(setScorecards(result.data));
      } else {
        setSubmitStatus('duplicatePageName');
      }
    }
  }

  if (!scorecardEditAccess) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        onClick={() => {
          setModalOpen(true);
        }}
        className='float-right hoverPls'
      >
        Add Current View to Scorecard <FontAwesomeIcon icon='fa-solid fa-plus' />
      </div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={boxStyle}>
          {submitStatus != 'submitting' && submitStatus != 'success' && (
            <Box>
              {selectedScorecard == 'newScorecard' ? (
                <React.Fragment>
                  <InputLabel id='demo-simple-select-standard-label-2'>New Scorecard Name</InputLabel>
                  <TextField
                    size='small'
                    fullWidth
                    value={newScorecardInput}
                    onChange={(e) => {
                      setNewScorecardInput(e.target.value);
                    }}
                  />
                </React.Fragment>
              ) : (
                scorecardSelect()
              )}
              <InputLabel style={{'marginTop': '15px'}} id='demo-simple-select-standard-label-2'>
                New Page Name
              </InputLabel>
              <TextField
                size='small'
                fullWidth
                value={newPageName}
                onChange={(e) => {
                  setNewPageName(e.target.value);
                }}
              />
            </Box>
          )}
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            {submitStatus == 'submitting' && <ModalSubmitting />}
            {submitStatus == 'success' && (
              <div>
                <div style={{color: 'green', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-check' />
                </div>
                <span>Update Completed.</span>
              </div>
            )}
            {submitStatus == 'duplicateName' ||
              (submitStatus == 'duplicatePageName' && (
                <div>
                  <div style={{color: 'red', fontSize: '5vw'}}>
                    <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-xmark' />
                  </div>
                  <span>Please enter a unique scorecard and page name</span>
                </div>
              ))}
          </div>
          {submitStatus != 'submitting' && (
            <div style={{marginTop: '30px'}} className='flex-row mb-3 col-12 px-1'>
              {!dateMismatch ? (
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                  {selectedScorecard == 'newScorecard' && scorecardsArray.length > 0 ? (
                    <Button
                      variant='ic-button'
                      color='error'
                      onClick={() => {
                        setSelectedScorecard(initialSelectedScorecard);
                      }}
                    >
                      <FontAwesomeIcon style={{marginleft: '5px'}} icon='fa-solid fa-arrow-left' /> Use Existing
                      Scorecard
                    </Button>
                  ) : (
                    <Button
                      variant='ic-button'
                      color='secondary'
                      onClick={() => {
                        setModalOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    disabled={(selectedScorecard == 'newScorecard' && newScorecardInput == '') || newPageName == ''}
                    variant='ic-button'
                    color='primary'
                    onClick={submitPage}
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                <Alert
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setDateMismatch(false);
                      }}
                    >
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                  variant='outlined'
                  severity='warning'
                >
                  {' '}
                  Current View does not match selected Scorecard Dates. By submitting, this view will be saved using
                  dates from {scorecardsIdDict[selectedScorecard].name}.
                </Alert>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export function CreateNewScorecardModal(props) {
  const [submitStatus, setSubmitStatus] = useState('pending');
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const scorecards = useSelector((state) => {
    return state.dashboard.scorecards;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const datesCache = useSelector((state) => {
    return state.dashboard.dates;
  });
  const scorecardsArray = scorecards.map((scorecard) => {
    return {
      id: scorecard.data.id,
      name: scorecard.data.name,
    };
  });
  const [selectedScorecard, setSelectedScorecard] = useState('none');
  const [newScorecardInput, setNewScorecardInput] = useState('');
  const initDateRange = {
    start: DateTime.fromISO(datesCache.start).setZone(customerSettings.general.timeZone, {}),
    end: DateTime.fromISO(datesCache.end).setZone(customerSettings.general.timeZone, {}),
  };
  const [newScorecardDateRange, setNewScorecardDateRange] = useState(initDateRange);
  const scorecardEditAccess = userSettings.roleAccess['dashboardScorecardManagement'];

  function datePickerRender() {
    let buttonDisplayString = '';
    if (
      typeof newScorecardDateRange.start !== 'undefined' &&
      typeof newScorecardDateRange.end !== 'undefined' &&
      newScorecardDateRange.start != null &&
      newScorecardDateRange.end != null
    ) {
      buttonDisplayString =
        ` ${newScorecardDateRange.start.toFormat('L/d/yy')} ` + `- ${newScorecardDateRange.end.toFormat('L/d/yy')} `;
      return (
        <DateRangePicker
          // key={keyRef.current}
          onApply={editDateSelection}
          initialSettings={{
            startDate: newScorecardDateRange.start.toFormat('MM/dd/yyyy'),
            endDate: newScorecardDateRange.end.toFormat('MM/dd/yyyy'),
            // timePicker: false,
            // timePicker24Hour: false,
            locale: {
              format: 'MM/DD/YYYY',
            },
          }}
          // disabled={loading}
        >
          <button className='btn border-dark btn-light col-12 cropview-menu-text h-100'>
            <i className='fas fa-calendar-alt' />
            {buttonDisplayString}
          </button>
        </DateRangePicker>
      );
    }
  }

  function editDateSelection(event, picker) {
    const startDate = DateTime.fromISO(picker.startDate.toISOString()).setZone(customerSettings.general.timeZone, {
      keepLocalTime: true,
    });
    const endDate = DateTime.fromISO(picker.endDate.toISOString())
      .set({
        second: 59,
        millisecond: 999,
      })
      .setZone(customerSettings.general.timeZone, {keepLocalTime: true});
    setNewScorecardDateRange({start: startDate, end: endDate});
  }

  function handleSelectedScorecardChange(event) {
    setSelectedScorecard(event.target.value);
  }

  function scorecardSelect() {
    return (
      <React.Fragment>
        <InputLabel id='demo-simple-select-standard-label'>Select Scorecard to use as Template</InputLabel>
        <FormControl variant='standard' className='w-100'>
          <Select
            id='scorecardTemplateSelect'
            size='small'
            value={selectedScorecard}
            onChange={handleSelectedScorecardChange}
          >
            <MenuItem value={'none'}>Create without Template</MenuItem>
            {scorecardsArray.map((scorecard) => {
              return (
                <MenuItem key={scorecard.id} value={scorecard.id}>
                  {scorecard.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  }

  async function submitPage() {
    const submissionObject = {
      name: newScorecardInput,
      startDate: newScorecardDateRange.start,
      endDate: newScorecardDateRange.end,
    };
    if (selectedScorecard != 'none') {
      submissionObject.templateId = selectedScorecard;
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submissionObject),
    };
    setSubmitStatus('submitting');
    const response = await fetchPostAuthSafe(
      '/statsview/createScorecard',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    if (result.success) {
      setSubmitStatus('success');
      setNewScorecardInput('');
      setModalOpen(false);
      setSubmitStatus('pending');
      dispatch(setScorecards(result.data));
    } else {
      setSubmitStatus('duplicateName');
    }
  }

  if (!scorecardEditAccess) {
    return null;
  }
  return (
    <React.Fragment>
      <button
        className='btn border-dark btn-light col-3 cropview-menu-text'
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px'}}>
          <FontAwesomeIcon
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '18px'}}
            icon='fas fa-plus'
          />
        </div>
      </button>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={boxStyle}>
          {submitStatus != 'submitting' && submitStatus != 'success' && (
            <Box>
              <React.Fragment>
                <InputLabel id='demo-simple-select-standard-label-2'>New Scorecard Name</InputLabel>
                <TextField
                  size='small'
                  fullWidth
                  value={newScorecardInput}
                  onChange={(e) => {
                    setNewScorecardInput(e.target.value);
                  }}
                />
                <InputLabel style={{'marginTop': '15px'}} id='new-scorecard-daterange'>
                  Date Range
                </InputLabel>
                {datePickerRender()}
              </React.Fragment>
              {scorecardSelect()}
            </Box>
          )}
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            {submitStatus == 'submitting' && <ModalSubmitting />}
            {submitStatus == 'success' && (
              <div>
                <div style={{color: 'green', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-check' />
                </div>
                <span>Submission Completed.</span>
              </div>
            )}
            {submitStatus == 'duplicateName' && (
              <div>
                <div style={{color: 'red', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-xmark' />
                </div>
                <span>Please enter a unique scorecard name</span>
              </div>
            )}
          </div>

          {submitStatus != 'submitting' && (
            <div style={{marginTop: '30px'}} className='flex-row mb-3 col-12 px-1'>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <Button
                  variant='ic-button'
                  color='secondary'
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button disabled={newScorecardInput == ''} variant='ic-button' color='primary' onClick={submitPage}>
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export function KpiTooltip(props) {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  if (!tooltipVisible) {
    return (
      <IconButton
        size='small'
        onClick={() => {
          setTooltipVisible(true);
          if (props.clickHandler) {
            props.clickHandler(true);
          }
        }}
      >
        <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-question' />
      </IconButton>
    );
  } else {
    return (
      <Collapse in={tooltipVisible} className='py-2'>
        <Alert
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setTooltipVisible(false);
                if (props.clickHandler) {
                  props.clickHandler(false);
                }
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          variant='outlined'
          severity='success'
          sx={{
            '& .MuiAlert-message': {
              width: '100%',
            },
          }}
        >
          {kpiTooltipContent(props.selectedSubset)}
        </Alert>
      </Collapse>
    );
  }
}

function kpiTooltipContent(selectedSubset) {
  switch (selectedSubset) {
    case 'kpiServiceCosts':
      return (
        <React.Fragment>
          <AlertTitle sx={{textAlign: 'center'}}>
            <strong>Overview of Machinery Service Costs</strong>
          </AlertTitle>
          <p>Look for machines with high service costs to consider if machine usage is appropriate.</p>
        </React.Fragment>
      );
    case 'kpiServiceCompletion':
      return (
        <React.Fragment>
          <AlertTitle sx={{textAlign: 'center'}}>
            <strong>A rollup of how frequent the shop services machinery on time.</strong>
          </AlertTitle>
          <p>
            Encourage shop managers to review weekly equipment reports to drive the “On-Time” percentage up. Identify
            which vehicles have the greatest # of late services and encourage shop to service more frequently.
          </p>
        </React.Fragment>
      );
    case 'kpiInspectionRates':
      return (
        <React.Fragment>
          <AlertTitle sx={{textAlign: 'center'}}>
            <strong>A snapshot of how frequent safety circle checks have failed inspection items.</strong>
          </AlertTitle>
          <p>Look for machines with high reported failures and consider a major service, or retiring the machine.</p>
        </React.Fragment>
      );
    case 'kpiMachineUsage':
      return (
        <React.Fragment>
          <AlertTitle sx={{textAlign: 'center'}}>
            <strong>A quick overview of all hours of your machinery.</strong>
          </AlertTitle>
          <p>
            Look for machines that have very high or very low hours, and encourage rotation through the fleet to keep
            costs down!
          </p>
        </React.Fragment>
      );
    case 'kpiMachineUsageInput':
      return (
        <React.Fragment>
          <p>
            For evaluating fuel consumption, cost or GHG emissions, a good ‘average’ fuel rate is{' '}
            <strong>3.5 Gal / Hr (13.5 L / Hr)</strong>
          </p>
        </React.Fragment>
      );
    case 'kpiIdling':
      return (
        <React.Fragment>
          <AlertTitle sx={{textAlign: 'center'}}>
            <strong>A summary of which vehicles are idling the most, by percent of total hours.</strong>
          </AlertTitle>
          <p>Encourage ranch managers to speak with foreman about machines that have the highest idling percentage</p>
        </React.Fragment>
      );
    case 'kpiOperationalEfficiency':
      return (
        <React.Fragment>
          <AlertTitle sx={{textAlign: 'center'}}>
            <strong>A snapshot of how time your machines spend truly in a field working.</strong>
          </AlertTitle>
          <p>
            Out of field time includes travel to/from blocks, and industry average is ~60% of time is spent in field
          </p>
        </React.Fragment>
      );
    case 'kpiEfficiency':
      return (
        <React.Fragment>
          <AlertTitle sx={{textAlign: 'center'}}>
            <strong>Three ways to drill down on farming efficiency.</strong>
          </AlertTitle>
          <div>
            <p>
              <strong>Acres per Hour to understand ranch efficiency. </strong>
              Compare different blocks with different designs (long vs. short rows) to understand planning and budgeting
              implications. Higher acres/hour means you are covering more ground each hour and typically spending less
              money to farm!
            </p>
            <p>
              <strong>Hours per Acre to understand scheduling implications. </strong>
              Take this number and multiply by the ranch total acres to understand how long it will take to farm the
              field. Higher hours/acre means you take a longer time to work through that ranch and will need more time
              scheduled to complete a task!
            </p>
            <p>
              <strong>Average Speed to ensure quality work is being performed. </strong>
              Filter for critical tasks and ensure the activity is being performed to target (i.e. Spraying at 3mph).
              This speed is the average ground speed while in the field performing the selected task (i.e. does not
              include out of field time)
            </p>
            <p>
              <strong>Total Dollars Calculator to estimate cost </strong>
              Multiply the chart value to convert ac/hr (or hr/ac) to total dollars (i.e. Total Acres * $32/ac = Total
              $)
            </p>
          </div>
        </React.Fragment>
      );
    case 'kpiTasksLogged':
      return (
        <React.Fragment>
          <AlertTitle sx={{textAlign: 'center'}}>
            <strong>User adoption of task input to the IntelliCulture platform.</strong>
          </AlertTitle>
          <p>Look for regions / ranches with the lowest percentage and provide more coaching on task input</p>
        </React.Fragment>
      );
    case 'kpiMissingTasks':
      return (
        <React.Fragment>
          <AlertTitle sx={{textAlign: 'center'}}>
            <strong>An overview of which machines are missing task data in IntelliCulture.</strong>
          </AlertTitle>
          <p>Look for machines with the highest hours and provide more coaching on task input</p>
        </React.Fragment>
      );
  }
}

export function SimpleSnackbar({open, setOpen}) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
        <CloseIcon fontSize='small' />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message='Link Copied'
        action={action}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      />
    </div>
  );
}

export function checkDateStringsSame(string1, string2) {
  const string1Formatted = DateTime.fromISO(string1).toUTC().toISO();
  const string2Formatted = DateTime.fromISO(string2).toUTC().toISO();
  return string1Formatted == string2Formatted;
}

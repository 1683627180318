import {useEffect} from 'react';

function BackendRoute(props) {
  // Component effects
  useEffect(() => {
    window.location.href = `/v1${window.location.pathname + window.location.search}`;
  }, []);

  return null;
}

export default BackendRoute;
